import { Link } from "react-router-dom";

import Post from "../../components/blog/Post";
import PostImage from "../../components/blog/PostImage";
import AIGFCDownloads from "./AIGFCDownloads";
import AISystemLifecycle from "./AISystemLifecycle";

import aiGovernance from "../../img/blog/aigfc/ai-governance.webp";
import aigfc from "../../img/blog/aigfc/aigfc.webp";

import { useModal } from "../../helpers/utils";

import AIGFCModal from "./AIGFCModal";

const POSTNAME: postNickname = "aigfc-intro";

const AIGFCIntro: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default AIGFCIntro;

const Rest = () => {
  const contents = [
    { anchor: "executive-summary", text: "Executive Summary" },
    { anchor: "background", text: "Background" },
    // { anchor: "target-audience", text: "Target Audience" },
    { anchor: "ai-lifecycle", text: "AI Lifecycle Phases" },
    { anchor: "types-of-ai-governance", text: "Types of AI Governance" },
    { anchor: "conclusion", text: "Conclusion" },
  ];

  const { showModal, handleShowClick } = useModal();

  return (
    <>
      {showModal && <AIGFCModal handleShowClick={handleShowClick} />}

      <div className="blog">
        <p>
          Artificial Intelligence is reshaping the business landscape. Alongside rapid adoption and technical
          advancements comes the pressing need for effective AI Governance. In this series, we explore best practices
          drawn from <strong>seven leading AI Governance frameworks</strong>, offering strategic insight on how to{" "}
          <strong>deploy and operate</strong> AI models.{" "}
          <span>
            Incorporating advice from experienced data practitioners, we evaluate potential impact of leading AI
            Governance frameworks to improve{" "}
            <Link to="/resources/data-driven-decision-making" target="_blank">
              data-driven decision-making
            </Link>
            . You can review our evaluation of the frameworks by{" "}
            <Link to="/landing/aigfc/slides" target="_blank">
              downloading our presentation slides
            </Link>
            .
          </span>
        </p>
        {/* <AIGFCDownloads /> */}
        {/* <p>
        To provide context on the need for AI Governance frameworks, we outline{" "}
        <Link to="/resources/aigfc/adoption" target="_blank">
          adoption trends
        </Link>
        ,{" "}
        <Link to="/resources/aigfc/risks" target="_blank">
          risks associated with AI
        </Link>
        , and{" "}
        <Link to="/resources/aigfc/governance" target="_blank">
          AI Governance practices
        </Link>
        . Incorporating advice from experienced data practitioners, we evaluate potential impact of leading AI
        Governance frameworks to improve{" "}
        <Link to="/resources/data-driven-decision-making" target="_blank">
          data-driven decision-making
        </Link>
        . The series provides a <strong>thorough introduction to AI Governance frameworks</strong> to empower
        organizations of all sizes to deploy and operate AI with confidence.
      </p> */}
        <PostImage postName={POSTNAME} />
        <h4>Contents</h4>
        <ul>
          <li>
            <a href={"#" + contents[0].anchor}>{contents[0].text}</a>
          </li>
          <li>
            <a href={"#" + contents[1].anchor}>{contents[1].text}</a>
            {contents.slice(2, 4).map((content) => (
              <li>
                <a href={"#" + content.anchor}>{content.text}</a>
              </li>
            ))}
          </li>
          <li>
            <a href={"#" + contents[4].anchor}>{contents[4].text}</a>
          </li>
        </ul>
        <div className="teal-box" style={{ maxWidth: 1000, marginTop: "3em" }}>
          <h2 id={contents[0].anchor} style={{ marginTop: "0.2em" }}>
            {contents[0].text}
          </h2>
          <p>
            The{" "}
            <Link to="/landing/aigfc/full-report" target="_blank">
              AI Governance Frameworks Comparison (AIGFC) report
            </Link>{" "}
            aims to help data practitioners and C-level executives at businesses understand the need for AI Governance
            and the value potential of prominent AI Governance frameworks for deploying and operating AI.
          </p>
          <blockquote>
            <p style={{ marginBottom: ".5em" }}>
              "As business leaders survey the ecosystem of AI Governance,<strong> </strong>they
              <strong> find a confusing hodgepodge of guidelines and frameworks</strong> – a lot of mechanisms but{" "}
              <strong>not much clarity on how they fit together or which ones are likely to be useful</strong>."
            </p>

            <p style={{ textAlign: "right", fontSize: 16 }}>
              – "
              <a
                href="https://www.bcg.com/publications/2023/a-guide-to-mitigating-ai-risks"
                target="_blank"
                rel="noreferrer"
              >
                A Guide to AI Governance for Business Leaders
              </a>
              ," Boston Consulting Group
            </p>
          </blockquote>

          <p>In order to understand the value of AI Governance, it is worthwhile to consider the following topics:</p>
          <p>
            <strong>AI adoption</strong>:&nbsp; more businesses are using AI than ever before, yet many find successful
            adoption to be a challenge. We aggregate survey findings to understand the key barriers to adopting AI,
            especially over 2023-2024.
          </p>
          <p>
            <strong>AI risks</strong>:&nbsp; evidence indicates a lack of awareness of the growing frequency and breadth
            of AI incidents and the need to practically mitigate various risks associated with AI.
          </p>
          <p>
            <strong>AI Governance frameworks</strong>:&nbsp; we explore the use of AI Governance frameworks to address
            the most significant barriers to AI deployment and operation. Our overview covers the benefits, roles,
            responsibilities, and adoption trends associated with AI Governance.
          </p>
          <p>
            <strong>Framework profiles</strong>:&nbsp; finally, we assessed seven leading AI Governance frameworks for
            their depth, breadth, and practicality across Risk Management and Data Management dimensions. We also
            identified common characteristics to help businesses implementing their own framework.
          </p>

          <p>The report provides comprehensive analysis of seven leading AI Governance frameworks:</p>
          <ol>
            <li>
              <a
                href="https://www.pdpc.gov.sg/-/media/Files/PDPC/PDF-Files/Resource-for-Organisation/AI/SGModelAIGovFramework2.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Singapore's Model AI Governance Framework
              </a>
            </li>

            <li>
              <a
                href="https://cdn-dynmedia-1.microsoft.com/is/content/microsoftcorp/microsoft/final/en-us/microsoft-brand/documents/Microsoft-Responsible-AI-Standard-General-Requirements.pdf?culture=en-us&country=us"
                target="_blank"
                rel="noreferrer"
              >
                Microsoft Responsible AI Standard
              </a>
            </li>
            <li>
              <a href="https://arxiv.org/pdf/2202.02776" target="_blank" rel="noreferrer">
                Council of Europe's HUDERAF for AI Systems
              </a>
            </li>
            <li>
              <a
                href="https://www.oecd.org/en/publications/oecd-framework-for-the-classification-of-ai-systems_cb6d9eca-en.html"
                target="_blank"
                rel="noreferrer"
              >
                OECD's Framework for the Classification of AI Systems
              </a>
            </li>
            <li>
              <a
                href="https://docs.google.com/document/d/1irljyf0l_dSKTN6aHhztbiuW7UVugN-yAYbu53uvSNA/edit?tab=t.hbvzkrcj3oab#heading=h.8tbdmf19mhd"
                target="_blank"
                rel="noreferrer"
              >
                NIST AI Risk Management Framework
              </a>
            </li>
            <li>
              <a
                href="https://services.google.com/fh/files/blogs/google_secure_ai_framework_approach.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Google Secure AI Framework Approach
              </a>
            </li>
            <li>
              <a
                href="https://docs.aws.amazon.com/pdfs/whitepapers/latest/aws-caf-for-ai/aws-caf-for-ai.pdf#governance-perspective-managing-an-aiml-driven-organization"
                target="_blank"
                rel="noreferrer"
              >
                AWS Cloud Adoption Framework for AI, ML, and Gen AI
              </a>
            </li>
          </ol>
          <p>These frameworks offer suggested best practices on how to deploy and operate AI models effectively.</p>

          <p>Our approach to exploring AI Governance frameworks:</p>
          <ol>
            <li>Highlight specific best practices and definitions</li>
            <li>
              Provide ratings that assess across breadth, depth, and practicality across Risk Management and Data
              Management dimensions
            </li>
          </ol>
          <p>
            This report aims to provide useful guidance from leading frameworks and clarify the value potential for
            businesses to investigate each framework in more detail.
          </p>
        </div>

        <h2 id={contents[1].anchor}>{contents[1].text}</h2>
        {/* <h4 id={contents[2].anchor}>{contents[2].text}</h4>
      <p>
        This report is intended for data practitioners and C-level executives at organizations interested in
        understanding the need for AI Governance to effectively deploy and operate AI systems. The report is also useful
        for organizations that are already using AI and want to improve their AI Governance practices.
      </p> */}

        <h4 id={contents[2].anchor}>{contents[2].text}</h4>
        <p>
          The AI lifecycle is a series of phases that AI models go through from development to deployment and operation.
          The AI lifecycle phases are as follows: Design, Verify, Deploy, and Operate. AI Governance refers to a set of
          practices that help businesses manage AI models throughout the AI lifecycle. AI Governance practices are
          especially important for the deployment and operation phases of the AI lifecycle.
        </p>
        <AISystemLifecycle figureNumber="0.1" />

        <p>
          As more businesses, especially larger companies, develop and deploy their AI systems, the guidance provided by
          an AI Governance framework will become increasingly important.
        </p>
        <h4 id={contents[3].anchor}>{contents[3].text}</h4>
        <p>
          According to{" "}
          <a
            href="https://www.bcg.com/publications/2023/a-guide-to-mitigating-ai-risks"
            target="_blank"
            rel="noreferrer"
          >
            Boston Consulting Group
          </a>
          , types of AI Governance include AI principles, AI frameworks, laws and policies, voluntary guidelines, and
          standards and certifications. In particular, frameworks can help businesses manage AI models throughout the AI
          lifecycle, especially during the deployment and operation phases.
        </p>
        <figure>
          <img
            src={aiGovernance}
            alt="diagram showing different areas of AI Governance:  AI principles, AI frameworks, laws and policies, voluntary guidelines, and standards and certifications"
            onClick={() =>
              window.open("https://www.bcg.com/publications/2023/a-guide-to-mitigating-ai-risks", "_blank")
            }
            style={{ cursor: "pointer" }}
          />
          <figcaption>
            Figure 0.2 – "The many types of AI Governance" from "
            <a
              href="https://www.bcg.com/publications/2023/a-guide-to-mitigating-ai-risks"
              target="_blank"
              rel="noreferrer"
            >
              A Guide to AI Governance for Business Leaders
            </a>
            ,"{" "}
            <a href="https://www.bcg.com/" target="_blank" rel="noreferrer">
              Boston Consulting Group
            </a>
          </figcaption>
        </figure>

        <h2 id={contents[4].anchor}>{contents[4].text}</h2>
        <p>
          In this blog series, we assessed the landscape of AI Governance frameworks from public and private sector
          institutions to provide guidance on their use. We evaluated the frameworks across two dimensions: Risk
          Management and Data Management. Our evaluation criteria included the following:
        </p>
        <ol>
          <li>Best practices and definitions</li>
          <li>Depth, breadth, and practicality</li>
          <li>Common characteristics</li>
        </ol>
        <p>
          Our evaluation of the frameworks provides a comprehensive analysis of the value potential for businesses to
          investigate each framework in more detail. We aim to help data practitioners and C-level executives at
          organizations understand the need for AI Governance and the value potential of prominent AI Governance
          frameworks for deploying and operating AI.
        </p>
        {/* – you can find more information about the frameworks and our evaluations by{" "}
          <Link to="/landing/aigfc/slides" target="_blank">
            downloading our presentation slides
          </Link>
          . */}
        <figure>
          <figcaption style={{ fontStyle: "italic", marginBottom: 4, fontSize: ".8em" }}>(Click to Zoom)</figcaption>
          <img
            src={aigfc}
            alt="comparison of AI Governance frameworks"
            style={{ maxHeight: 1200, cursor: "pointer" }}
            onClick={handleShowClick}
            className="box-shadow"
          />
        </figure>
        <p>
          Across the series, we analyze evidence for current AI adoption trends, AI risks, and AI Governance practices.
          We incorporate advice from experienced data practitioners and assess the value potential of leading AI
          Governance frameworks to mitigate risks and improve Data Management capabilities. Our aim is to provide a
          thorough introduction to AI Governance frameworks in order to empower organizations of all sizes to deploy and
          operate AI systems with more confidence.
        </p>
      </div>
    </>
  );
};

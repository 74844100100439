import { Link } from "react-router-dom";

import Post from "../../components/blog/Post";
import PostImage from "../../components/blog/PostImage";
import AIGFCDownloads from "./AIGFCDownloads";

import aiGovernance from "../../img/blog/aigfc/ai-governance.webp";
import aiFrameworkTypes from "../../img/blog/aigfc/ai-framework-types.webp";

const POSTNAME: postNickname = "aigfc-frameworks";

const AIGFCFrameworks: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default AIGFCFrameworks;

const Rest = () => {
  const contents = [
    { anchor: "overview", text: "Overview" },
    { anchor: "investigation-aims", text: "Investigation Aims" },
    { anchor: "selecting-frameworks", text: "Selecting Frameworks" },
    { anchor: "integrating-insights", text: "Integrating Insights" },
    { anchor: "principle-based-frameworks", text: "Principle-based Frameworks" },
    { anchor: "oecd-framework", text: "OECD Framework for the Classification of AI Systems" },
    { anchor: "nist-framework", text: "NIST AI Risk Management Framework" },
    { anchor: "singapore-framework", text: "Singapore's Model AI Governance Framework" },
    { anchor: "huderaf-framework", text: "The Council of Europe's HUDERAF for AI Systems" },
    { anchor: "vendor-based-frameworks", text: "Vendor-based Frameworks" },
    { anchor: "microsoft-framework", text: "Microsoft's Responsible AI Standards" },
    { anchor: "aws-framework", text: "AWS Cloud Adoption Framework for AI, ML, and Gen AI" },
    { anchor: "google-framework", text: "Google's Secure AI Framework Approach" },
    { anchor: "key-takeaways", text: "Key Takeaways" },
    { anchor: "assessments-comparison", text: "Assessments Comparison Table" },
    { anchor: "highlights", text: "Highlights by Best Practice Subcategory" },
    { anchor: "discussion", text: "Discussion" },
    { anchor: "framework-recommendations", text: "Framework Recommendations" },
  ];

  return (
    <div className="blog">
      <PostImage postName={POSTNAME} />
      <h5>Contents</h5>
      <ul>
        <li>
          <a href={"#" + contents[0].anchor}>{contents[0].text}</a>
          {contents.slice(1, 4).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
        <li>
          <a href={"#" + contents[4].anchor}>{contents[4].text}</a>
          {contents.slice(5, 9).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
        <li>
          <a href={"#" + contents[9].anchor}>{contents[9].text}</a>
          {contents.slice(10, 13).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>

        <li>
          <a href={"#" + contents[13].anchor}>{contents[13].text}</a>
          {contents.slice(14, 17).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
      </ul>
      <h2 id={contents[0].anchor}>{contents[0].text}</h2>
      <h4 id={contents[1].anchor}>{contents[1].text}</h4>
      <p>
        An essential step towards businesses establishing the AI Governance expertise necessary to implement an
        effective AI Governance framework is understanding the frameworks currently leading the field. But navigating
        existing frameworks isn't easy. Many different and often densely written frameworks are being promoted which,
        because the field remains without agreed-upon standards, vary widely in the areas of AI Governance they focus on
        and the type of guidance they offer.
      </p>
      <p>
        We aim to investigate and aggregate basic insights on these frameworks, which help businesses make faster and
        clearer decisions about which frameworks might be most promising for them to investigate more deeply. These
        investigations culminate in written profiles for each framework, which offer two basic types of insights.
      </p>
      <p>
        First, we efficiently familiarize businesses with basic descriptions of what each framework focuses on,
        including their aims and specific examples of the most relevant guidance they offer. Second, we want to make the
        potential value of businesses investigating each framework clearer by offering high-level assessments of not
        only the type of content they offer in addition to what we've highlighted here but also what their supplementary
        resources may offer.
      </p>
      <p>
        Together, we hope that these insights offer decision-relevant information to key business roles about what they
        need to do to implement an AI Governance framework and which frameworks they can gain the most from learning
        more about.
      </p>

      <h4 id={contents[2].anchor}>{contents[2].text}</h4>

      <p>
        Our investigations span seven leading frameworks: four{" "}
        <a href="#principle-based-frameworks-4">principle-based frameworks</a> and three{" "}
        <a href="#vendor-based-frameworks-26">vendor-based frameworks</a>. The four principle-based frameworks were
        selected from IAPP-FTI Consulting's{" "}
        <a href="https://iapp.org/media/pdf/resource_center/ai_governance_in_practice_report_2024.pdf">2024 report</a>.
        As such, this selection involved limited independent prioritization between frameworks to highlight. Initially
        focusing our investigations on IAPP's selection for AI Governance frameworks offers a reliable and efficient
        starting place for familiarizing businesses with AI Governance frameworks to learn from and integrate.
      </p>
      <p>
        Two exceptions to this approach were made to better focus our investigations on a single core framework from
        each organization during our assessments. The first exception was to treat{" "}
        <a href="https://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.1270.pdf">NIST's Special Publication</a>{" "}
        related to AI bias as a supplementary framework to NIST's{" "}
        <a href="https://nvlpubs.nist.gov/nistpubs/ai/NIST.AI.100-1.pdf">AI RMF</a> rather than its own independent
        framework. The second involved highlighting the Singaporean government's{" "}
        <a href="https://www.pdpc.gov.sg/-/media/Files/PDPC/PDF-Files/Resource-for-Organisation/AI/SGModelAIGovFramework2.pdf">
          Model AI Governance Framework
        </a>{" "}
        over Singapore AI Verify's specifically{" "}
        <a href="https://aiverifyfoundation.sg/wp-content/uploads/2024/06/Model-AI-Governance-Framework-for-Generative-AI-19-June-2024.pdf">
          gen AI framework
        </a>
        , which we've instead treated as a supplementary resource. See our Methodology section for more information.
      </p>
      <p>
        In addition to these principle-based frameworks, we investigated AI Governance frameworks from the three leading
        cloud service vendors. The frameworks of Microsoft, AWS, and Google were selected because they offer among the
        few publicly accessible AI Governance frameworks being used in practice and because each of these companies has
        an outsized impact on other businesses.
      </p>
      <p>
        AI Governance frameworks that vendors have developed for their own AI deployment and operation complement the
        largely top-down advice of principle-based frameworks. They don't necessarily follow all the best practices that
        principle-based frameworks recommend but do offer insight into what's being done in practice to navigate the
        novel challenges of AI Governance. Furthermore, businesses using the services of these large tech businesses,
        such as cloud services, may benefit from understanding and aligning their framework with their service
        provider's framework to maximize compatibility.
      </p>

      <h4 id={contents[3].anchor}>{contents[3].text}</h4>

      <p>
        How businesses use insights from and about their frameworks will likely vary widely. IAPP and EY offer March to
        June 2023{" "}
        <a href="https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report.pdf?referrer=content_seehereview">
          survey results
        </a>{" "}
        on the prevalence of different frameworks to benchmark and develop AI Governance programs across continents.
        NIST's AI RMF is especially prominent among existing frameworks, used by 50% of North American businesses.
        However, many businesses, especially in Asia and Europe, use internally developed frameworks (57% in Asia) or
        haven't used any particular framework (57% in Europe).
      </p>
      <p>
        The wide distribution of approaches businesses are taking to developing AI governance frameworks indicates that
        there's currently no general consensus on a best approach for doing so. One business that directly implements or
        designs a framework based on the framework of its cloud provider, whose framework we investigated, could
        maximize system compatibility.
      </p>
      <p>
        Meanwhile, another business might take inspiration from the policies and procedures of multiple frameworks for
        their framework design without directly implementing any policies or procedures from any specific framework. For
        example, such a business could start using the high-level structure of tools for trustworthy AI offered in the
        OECD's "
        <a href="https://www.oecd-ilibrary.org/docserver/008232ec-en.pdf?expires=1730238317&id=id&accname=guest&checksum=BEB1A6DA71952C260493D2FE17BB1F81">
          Tools for Trustworthy AI
        </a>
        " to identify the types of tools they could adopt from other frameworks. Businesses should consider how their
        objectives and resource capacity align with different approaches to select the best-fit approach.
      </p>
      <h2 id={contents[4].anchor}>{contents[4].text}</h2>

      <h4 id={contents[5].anchor}>{contents[5].text}</h4>

      <h5>Introduction</h5>

      <p>
        The Organisation for Economic Co-operation and Development's (OECD) AI in Work, Innovation, Productivity, and
        Skills (AI-WIPS) program created the{" "}
        <a href="https://www.oecd.org/en/publications/oecd-framework-for-the-classification-of-ai-systems_cb6d9eca-en.html">
          OECD Framework for the Classification of AI systems
        </a>{" "}
        with the support of over 60 experts in their Experts Working Group. It was released in February 2022.
      </p>
      <p>
        This framework offers high-level guidance about which dimensions AI will most impact, which risks threaten each
        dimension, and how risks can start to be approached responsibly, as outlined in the content summary below. Its
        definitions, concepts, and criteria are designed to be adaptable to future classifications of AI. Although it's
        primarily targeted at policymakers, regulators, and legislators, businesses can use the framework to understand
        what needs to be governed, what risks AI presents across dimensions, and how businesses might start mitigating
        these risks.
      </p>
      <p>
        The Experts Working Group's next steps produce an actionable AI system risk methodology, including refined
        classification criteria based on actual AI systems, metrics to assess subjective AI harm-related criteria, and a
        common AI incident reporting framework.
      </p>
      <p>
        <strong>Stated aims</strong>:
      </p>
      <ul>
        <li>Promote a common understanding of AI</li>

        <li>Inform registries or inventories</li>

        <li>Support sector-specific frameworks</li>

        <li>Support risk assessment</li>

        <li>Support risk management</li>
      </ul>

      <h5>Content Summary</h5>

      <p>
        The "OECD Framework for the Classification of AI systems" is a comprehensive framework that helps assess AI
        systems. Specifically, the framework links AI system characteristics with the{" "}
        <a href="https://oecd.ai/en/ai-principles">OECD's AI Principles</a> and outlines case studies for how they might
        be implemented for each dimension.
      </p>
      <p>
        It's primarily composed of two sections. The first explains the dimensions and characteristics used for
        assessment, while the second provides several case studies on how assessments work in practice.
      </p>
      <p>
        Five dimensions are used in classifying AI systems: People & Planet, Economic Context, Data & Input, AI Model,
        and Task & Output. Core characteristics are identified and explained for each dimension, with notes about why
        each characteristic matters and how they relate to the OECD's AI Principles. Particular attention is paid to the
        policy implications of each characteristic, but many of these are nonetheless relevant to how businesses deploy
        and operate AI.
      </p>
      <p>
        These characteristics are then used to classify the application of AI systems in specific, real-world contexts.
        Various organizations were invited to test the framework's usability and robustness through an online survey and
        public consultations. Key conclusions from the survey include that the framework is best suited to specific AI
        system applications over generic AI systems and that respondents were more consistent at classifying People &
        Planet and Economic Context criteria than other dimensions.
      </p>

      <h5>Best Practice Highlights</h5>

      <h5>Assessment Process</h5>

      <h4 id={contents[6].anchor}>{contents[6].text}</h4>

      <h5>Introduction</h5>

      <p>
        The US' National Institute of Standards and Technology (NIST) produced the AI Risk Management Framework (
        <a href="https://nvlpubs.nist.gov/nistpubs/ai/NIST.AI.100-1.pdf">AI RMF</a>) in collaboration with the public
        and private sector, releasing it in January 2023.
      </p>
      <p>
        The framework defines the types of risks that AI presents and proposes four functions that enable dialogue,
        understanding, and activities to manage AI risks and responsibly develop trustworthy AI systems. It is
        deliberately non-sector-specific and use-case agnostic so that all organizations and individuals – referred to
        as "AI actors" – who play an active role in the AI system lifecycle can benefit from its guidance.
      </p>
      <p>
        NIST will continue to update, expand, and improve the framework based on evolving technology, the global
        standards landscape, and AI community experience and feedback. For example, evaluations for the AI RMF's
        effectiveness in conjunction with the AI community, such as on ways to better measure bottom-line improvements
        in the trustworthiness of AI systems, will be a priority. A formal review is expected no later than 2028.
      </p>
      <p>
        <strong>Stated aims</strong>:
      </p>
      <ul>
        <li>Equip organizations and individuals with approaches that increase the trustworthiness of AI systems</li>

        <li>Help foster the responsible design, development, deployment, and use of AI systems over time</li>

        <li>Be voluntary, rights-preserving, non-sector-specific, and use-case agnostic</li>

        <li>Be operationalized by organizations in varying degrees and capacities</li>

        <li>Address new risks as they emerge</li>
      </ul>

      <h5>Content Summary:</h5>

      <p>
        The "NIST AI Risk Management Framework (AI RMF)" comprises two parts. Together, they offer a comprehensive and
        action-oriented introduction to mitigating AI risks.
      </p>
      <p>
        Part 1 provides foundational information about AI risks, trustworthiness characteristics, and framework context.
        The trustworthiness properties outlined include AI that is valid and reliable, safe, secure and resilient,
        accountable and transparent, explainable and interpretable, privacy-enhanced, and fair with harmful bias
        managed.
      </p>
      <p>
        Part 2 details the core functions and profiles for implementing risk management practices. Four core functions
        form the basis of AI risk management activities:
      </p>
      <ul>
        <li>
          <strong>GOVERN</strong>: cultivates and implements risk management culture and policies
        </li>

        <li>
          <strong>MAP</strong>: establishes context and frames AI system risks
        </li>

        <li>
          <strong>MEASURE</strong>: analyzes, assesses, and tracks AI risks using quantitative and qualitative methods{" "}
        </li>

        <li>
          <strong>MANAGE</strong>: allocates resources to prioritize and address identified risks
        </li>
      </ul>
      <p>
        The accompanying AI RMF Playbook is an especially valuable supplementary resource. It provides substantially
        more detail on each action and outcome outlined in the AI RMF, making it critical for effective practical
        implementation.
      </p>

      <h5>Best Practice Highlights</h5>

      <h5>Assessment Process</h5>

      <h4 id={contents[7].anchor}>{contents[7].text}</h4>

      <h5>Introduction</h5>

      <p>
        Singapore's Info-communications Media Development Authority (IMDA) and Personal Data Protection Commission
        (PDPC) developed the{" "}
        <a href="https://www.pdpc.gov.sg/-/media/Files/PDPC/PDF-Files/Resource-for-Organisation/AI/SGModelAIGovFramework2.pdf">
          Model AI Governance Framework
        </a>{" "}
        in collaboration with industry stakeholders. It was first released at the 2019 World Economic Forum in Davos,
        with a second edition in January 2020.
      </p>
      <p>
        The framework provides guidance for organizations deploying AI solutions at scale, translating ethical
        principles into concrete governance practices. Rather than prescribing rigid requirements, it offers flexible
        guidance that organizations can adapt to their needs, focusing on practical measures to address key ethical and
        governance issues in AI deployment. It is deliberately designed to be sector-agnostic, technology-neutral, and
        applicable to organizations of any size or business model.
      </p>
      <p>
        Unlike frameworks focused primarily on high-level principles or policy guidance, Singapore's approach emphasizes
        operational implementation – helping organizations build stakeholder confidence through responsible AI
        deployment while aligning their internal structures with accountability-based practices in Data Management and
        protection.
      </p>
      <p>
        The PDPC maintains this as a "living" framework, committing to periodic updates that reflect evolving AI
        technologies, ethical considerations, and governance challenges.
      </p>
      <p>
        <strong>Stated aims</strong>:
      </p>
      <ul>
        <li>
          Designed to be a practical, ready-to-use tool that organizations can adapt based on their specific needs while
          maintaining core principles of responsible AI deployment
        </li>

        <li>Algorithm-agnostic, Technology-agnostic, Sector-agnostic, Scale- and Business-model-agnostic</li>

        <li>Guidance on the key issues to be considered and measures that can be implemented</li>
      </ul>

      <h5>Content Summary</h5>

      <p>
        The Singapore Model AI Governance Framework is especially focused on practical implementation of AI Governance.
      </p>
      <p>
        It's organized around four key areas. Internal Governance Structures and Measures addresses how organizations
        can establish oversight and accountability mechanisms for ethical AI deployment. The framework then covers
        Determining Human Involvement in AI-augmented Decision-making, introducing a risk-based methodology with three
        oversight models and a probability-severity matrix to guide implementation.
      </p>
      <p>
        Operations Management outlines essential practices for responsible AI development, focusing on data quality,
        algorithm governance, and model management through measures like explainability, repeatability, and robustness.
        Finally, Stakeholder Interaction and Communication provides guidance on AI system transparency and stakeholder
        engagement practices.
      </p>
      <p>
        The framework emphasizes practical implementation throughout, supporting each area with real-world examples
        while maintaining flexibility for different organizational contexts.
      </p>

      <h5>Best Practice Highlights</h5>

      <h5>Assessment Process</h5>

      <h4 id={contents[8].anchor}>{contents[8].text}</h4>

      <h5>Introduction</h5>

      <p>
        The Council of Europe's (CoE) Human Rights, Democracy and the Rule of Law Assurance Framework (
        <a href="https://arxiv.org/pdf/2202.02776">HUDERAF</a>) for AI Systems was developed by The Alan Turing
        Institute and submitted to the Council of Europe in September 2021, following the CoE's Ad Hoc Committee on
        Artificial Intelligence's December 2020 Feasibility Study.
      </p>
      <p>
        The framework is deliberately "algorithm neutral" and practice-based to remain inclusive of different AI
        applications and adaptable to future developments. It is designed for designers, developers, and users of AI
        systems who seek to minimize risks to human rights, democracy, or the rule of law.
      </p>
      <p>
        The framework is intended to be delivered through a single digital platform with interactive templates and
        automated reporting features. The HUDERAF model is designed to be dynamic, with regular reassessment and updates
        needed to address novel AI innovations, unforeseen applications, and emerging risks to fundamental rights and
        freedoms.
      </p>
      <p>
        This process will be achieved through inclusive multi-stakeholder processes for intermittent evaluation and
        revision of the framework. The framework's unique contribution is its integration of human rights due diligence
        practices with technical AI Governance mechanisms, providing practical tools for organizations to meet their
        human rights obligations while developing trustworthy AI systems.
      </p>
      <p>
        <strong>Stated aims</strong>:
      </p>
      <ul>
        <li>
          Provide an accessible and user-friendly set of mechanisms for facilitating compliance with a binding legal
          framework on AI
        </li>

        <li>
          Provide an innovation-enabling way to facilitate better, more responsible innovation practices that conform to
          human rights, democracy, and the rule of law so that these technologies can optimally promote individual,
          community, and planetary wellbeing
        </li>

        <li>
          To address the challenges put forward in CAHAI-PDG(2021)05rev, which involve various tools and methodologies
          to identify and evaluate AI risks to human rights, democracy, and the rule of law
        </li>
      </ul>

      <h5>Content Summary</h5>

      <p>
        The Council of Europe's HUDERAF consists of an integrated set of processes and templates that provide a
        comprehensive approach to assessing and managing AI risks to fundamental rights and freedoms. It's built around
        the SAFE-D goals that define key trustworthiness characteristics. These include safety, accountability,
        fairness, explainability, and data quality and protection.
      </p>
      <p>
        The primary content of the framework is organized into four sections. Each section represents a stage in the
        process to identify and evaluate risks:
      </p>
      <ul>
        <li>
          <strong>PCRA (Preliminary Context-Based Risk Analysis)</strong>: provides initial assessment of
          context-specific risks to human rights, democracy, and rule of law through structured questions and automated
          reporting
        </li>

        <li>
          <strong>SEP (Stakeholder Engagement Process)</strong>: guides identification and meaningful inclusion of
          affected stakeholders throughout the project lifecycle using proportionate engagement methods
        </li>

        <li>
          <strong>HUDERIA (Human Rights Impact Assessment)</strong>: enables collaborative evaluation of potential and
          actual impacts through stakeholder dialogue and detailed impact severity assessment
        </li>

        <li>
          <strong>HUDERAC (Assurance Case)</strong>: builds structured arguments demonstrating how claims about
          achieving framework goals are supported by evidence
        </li>
      </ul>
      <p>
        The framework is supported by detailed templates for each process that consolidate necessary questions, prompts,
        and actions. For example, the PCRA template features interactive elements that generate customized risk reports
        through conditional logic. Implementation is intended through a unified digital platform that makes all
        components accessible and user-friendly.
      </p>

      <h5>Best Practice Highlights</h5>

      <h5>Assessment Process</h5>

      <h5></h5>

      <h2 id={contents[9].anchor}>{contents[9].text}</h2>

      <h4 id={contents[10].anchor}>{contents[10].text}</h4>

      <h5>Introduction</h5>

      <p>
        Microsoft released version 1 of its{" "}
        <a href="https://cdn-dynmedia-1.microsoft.com/is/content/microsoftcorp/microsoft/final/en-us/microsoft-brand/documents/Microsoft-Responsible-AI-Standard-General-Requirements.pdf?culture=en-us&country=us">
          Responsible AI Standard
        </a>{" "}
        in September 2019, followed by v2 in June 2022. It was developed as part of a multi-year effort for Microsoft to
        operationalize its six AI principles into concrete product development requirements. The standard was created in
        response to a recognized policy gap, where existing laws and norms had not kept pace with AI's unique risks and
        societal needs. Microsoft leveraged expertise from their research, policy, and engineering teams to develop this
        guidance.
      </p>
      <p>
        The framework is designed primarily for Microsoft's product development teams but is being made publicly
        available for sharing learnings and contributing to broader discussions about AI Governance. It can be utilized
        by various stakeholders including system owners, developers, customer support teams, and organizations using
        Microsoft's AI systems.
      </p>
      <p>
        Microsoft frames this as a "living document" that will continue to evolve based on new research, technologies,
        laws, and learnings from both inside and outside the company. They actively seek feedback through their
        ResponsibleAIQuestions portal and emphasize the importance of collaboration between industry, academia, civil
        society, and government to advance responsible AI practices.
      </p>
      <p>
        <strong>Stated aims</strong>:
      </p>
      <ul>
        <li>To operationalize Microsoft's six AI principles</li>

        <li>To provide "concrete and actionable guidance" for product development teams</li>

        <li>
          To fill the "policy gap" where "laws and norms had not caught up with AI's unique risks or society's needs"
        </li>
      </ul>

      <h5>Content Summary</h5>

      <p>
        The "Microsoft Responsible AI Standard v2" translates Microsoft's six AI principles into concrete requirements,
        tools, and practices for product development teams.
      </p>
      <p>
        The document is composed of six core goal categories, each with detailed implementation requirements. The first
        section, Accountability Goals, focuses on impact assessment, oversight, and data governance, while subsequent
        sections address Transparency, Fairness, Reliability & Safety, Privacy & Security, and Inclusiveness.
      </p>
      <p>
        Each goal category contains specific requirements that must be met. For example, Fairness has three major goals:
        Quality of Service refers to ensuring similar performance across demographic groups, Allocation of Resources and
        Opportunities focuses on minimizing disparate outcomes, and Minimization of Stereotyping aims to prevent harmful
        bias. For each goal, detailed requirements are provided, along with recommended tools and practices for
        implementation.
      </p>
      <p>
        Particular attention is paid to accountability and documentation requirements, specifically identifying and
        mitigating risks before deployment and maintaining ongoing monitoring afterward. While some areas, like security
        and privacy, aren't explicitly covered and instead reference separate Microsoft policies, other key risks are
        covered in moderately high detail.
      </p>

      <h5>Best Practice Highlights</h5>

      <h5>Assessment Process</h5>

      <h4 id={contents[11].anchor}>{contents[11].text}</h4>

      <h5>Introduction</h5>

      <p>
        "AWS Cloud Adoption Framework for Artificial Intelligence, Machine Learning, and Generative AI (
        <a href="https://docs.aws.amazon.com/pdfs/whitepapers/latest/aws-caf-for-ai/aws-caf-for-ai.pdf#governance-perspective-managing-an-aiml-driven-organization">
          CAF-AI
        </a>
        )" was published in February 2024 through a collaborative effort between multiple AWS entities, including the
        Generative AI Innovation Center, Professional Services, Machine Learning Solutions Lab, and other specialized
        teams.
      </p>
      <p>
        This framework provides organizations with a comprehensive mental model and prescriptive guidance for generating
        business value from AI technologies. It maps out the AI transformation journey, detailing how organizational
        capabilities mature over time and outlining the foundational capabilities needed to grow AI maturity. The
        framework is structured across six key perspectives – Business, People, Governance, Platform, Security, and
        Operations –each targeting specific stakeholder groups from C-suite executives to technical practitioners.
      </p>
      <p>
        While primarily designed for organizations seeking to implement enterprise-level AI adoption beyond proof of
        concept, CAF-AI serves multiple purposes. It acts as both a starting point and ongoing guide for AI, ML, and
        generative AI initiatives, facilitates strategic discussions within teams and with AWS Partners, and helps
        organizations align their cloud and AI transformation journeys.
      </p>
      <p>
        CAF-AI is designed to be dynamic and evolving, serving as a constantly growing index of considerations for
        enterprise AI adoption. As part of AWS' larger Cloud Adoption Framework, it enables organizations to think
        holistically about their cloud and AI transformations. Regular updates are planned to reflect the rapidly
        evolving AI landscape.
      </p>
      <p>
        <strong>Stated aims</strong>:
      </p>
      <ul>
        <li>Describe the journey businesses experience as their organizational capabilities on AI and ML mature</li>

        <li>
          Prescriptive guidance on the target state of foundational capabilities and how to evolve them step by step to
          generate business value along the way
        </li>

        <li>Inspire and inform customers' mid-term planning and strategy for AI Governance</li>
      </ul>

      <h5>Content Summary</h5>

      <p>
        The AWS CAF-AI is organized into two main components. First, it briefly presents the AI cloud transformation
        value chain, which explains how AI capabilities lead to business outcomes. before outlining a four-stage AI
        transformation journey – Envision, Align, Launch, Scale – that organizations progress through iteratively.
      </p>
      <p>
        Second, it details foundational capabilities across six key perspectives. The six perspectives forming the
        framework's core are:
      </p>
      <ul>
        <li>
          <strong>Business</strong>: ensures AI investments accelerate digital transformation and business outcomes
        </li>

        <li>
          <strong>People</strong>: bridges technology and business, evolving culture toward continual growth
        </li>

        <li>
          <strong>Governance</strong>: orchestrates AI initiatives while maximizing benefits and minimizing risks
        </li>

        <li>
          <strong>Platform</strong>: builds enterprise-grade, scalable cloud platforms for AI operations and development
        </li>

        <li>
          <strong>Security</strong>: achieves confidentiality, integrity, and availability of data and cloud workloads
        </li>

        <li>
          <strong>Operations</strong>: ensures AI services meet business needs and maintain reliability
        </li>
      </ul>
      <p>
        Each perspective contains several foundational capabilities that are either enriched from the original AWS Cloud
        Adoption Framework or newly introduced for AI-specific needs. For example, new capabilities include ML Fluency
        (People perspective) and Responsible Use of AI (Governance perspective), while existing capabilities like Data
        Protection (Security perspective) are enhanced with AI-specific considerations. Detailed guidance on
        implementing each capability, complete with best practices, considerations, and implementation steps, is given.
      </p>

      <h5>Best Practice Highlights</h5>

      <h5>Assessment Process</h5>

      <h2 id={contents[12].anchor}>{contents[12].text}</h2>

      <h4 id={contents[13].anchor}>{contents[13].text}</h4>

      <h4 id={contents[14].anchor}>{contents[14].text}</h4>

      <p>
        The most evident pattern from our assessments is that leading principle-based and vendor-based AI Governance
        frameworks disproportionately guide risk mitigation compared with Data Management. The mean overall risk
        mitigation rating for frameworks is 6.8, while the mean overall Data Management rating is 3.5.
      </p>
      <p>
        This pattern holds across all three criteria, although there's more variance for risk mitigation guidance. The
        range in mean ratings between Data Management criteria was 0.6 between breadth (3.3) and depth (3.9), far more
        narrow than a range of 2.4 for risk mitigation, also between breadth (8.4) and depth (6) but with breadth being
        higher than depth, unlike for Data Management.
      </p>
      <p>
        With a difference of 5.1, the difference in mean breadth between risk mitigation and Data Management guidance
        has an outsized impact on the variance in overall ratings. However, the difference in depth (2.1) and
        practicality (2.7) ratings remain moderately large, indicating that the difference in coverage of risk
        mitigation and Data Management guidance is likely informative about what businesses should expect from these
        frameworks and isn't simply the result of variance in the breadth of subcategories covered.
      </p>
      <p>
        Looking at the aggregated highlights by subcategory, we can see that the average number of relevant highlights
        per subcategory is far higher for risk mitigation (3.6) than for Data Management (1.6). Indeed, half of DAMA's{" "}
        <em>
          <a href="http://amzn.to/32oK8hH">DMBOK</a>
        </em>{" "}
        Data Management practice areas aren't featured at all, and of those that do, half of the highlights recorded
        relate to data quality. Highlights were selected without regard for the subcategories they related to.
      </p>
      <p>
        Although the sample size is very small and the number of practices highlighted per framework varies, the skew in
        our highlights towards data quality guidance is likely representative of the concentrated relevance of a small
        number of data practices to AI Governance. This difference, on top of the large difference in breadth ratings
        between best practice categories, might indicate how limited a benchmark the Data Management subcategories from
        DAMA's <em>DMBOK</em> are for fairly rating the breadth of relevant data practices AI Governance frameworks
        provide guidance on.
      </p>
      <p>
        The possibility that there is only a limited set of Data Management practices, alongside the centrality of risk
        mitigation to AI Governance as a whole, might explain the large differences in breadth, depth, and practicality
        assessments between best practice categories. However, it's important to note that the range of best practice
        categories relevant to effective AI Governance is wider than just risk mitigation and Data Management.
      </p>
      <p>
        For example, guidance on operational tasks and how frameworks may be practically implemented are each essential
        to a successful AI Governance framework. However, as discussed across our Limitations and Methodology sections,
        measuring each of these ended up outside this project's scope. Let alone limitations in our assessments of risk
        mitigation and Data Management guidance, our assessments thus also do not reflect the full extent of value
        businesses could gain from investigating them further.
      </p>
      <p>
        Overall, our framework profiles reveal the value propositions of each framework for two key AI Governance best
        practice categories, in particular, the generally greater breadth, depth, and practicality of risk mitigation
        guidance over Data Management guidance. However, limitations, including how the breadth of Data Management
        guidance was measured and the breadth of AI Governance best practice categories assessed, mean that each
        framework's overall general value proposition may be far higher than our assessments indicate. Each business
        will gain more or less value from guidance from certain organizations or on certain best practice categories
        depending on its objectives.
      </p>
      <h4 id={contents[15].anchor}>{contents[15].text}</h4>

      <h5>Focusing on the Most Relevant Guidance</h5>

      <p>
        To maximize the value gained from investigating AI Governance frameworks, each business needs to understand what
        guidance best aligns with their business objectives. For example, businesses with end users but without any AI
        risk mitigation practices in place would likely benefit most from prioritizing risk mitigation for bias,
        accountability, explainability, or other relevant risks.
      </p>
      <p>
        Meanwhile, a business without a Data Strategy might need to develop one with AI Governance in mind before it can
        confidently consider how to mitigate AI risks, especially those deeply connected to data. As a business's AI
        Governance capabilities mature, the framework can be updated.
      </p>
      <p>
        We do, however, emphasize that evidence indicates that many businesses either don't recognize the relevance of
        various AI risks to them or have stalled on mitigating the risks they state as being relevant to them. Many
        businesses that would benefit most from implementing risk mitigation guidance first might be tempted to focus on
        Data Management guidance as practices they're more familiar with or that seem more relevant at face value. This
        potential bias risks significantly undermining the effectiveness and sustainability of a business's AI
        Governance framework, opening them and wider society up to various possible harms from AI.
      </p>
      <p>
        To help understand the relevance of AI risks to a business, we encourage businesses to at least start hiring for
        relevant expertise and assessing AI risks that they could be vulnerable to. Guidance such as that offered in the
        Council of Europe's framework is available to support getting started.
      </p>
      <h5>Risk Mitigation</h5>

      <p>
        We recommend investigating NIST's AI RMF for businesses focused on risk mitigation. Its guidance is highly
        specialized, providing extensive context for AI risks and step-by-step instructions for how businesses can start
        mitigating them as a whole. The NIST AI RMF Playbook, as a supplementary resource, adds considerable additional
        guidance on the actions and outcomes of each core function. Microsoft's framework also offers considerable
        value, especially regarding the practicality of its guidance.
      </p>
      <h5>Data Management</h5>

      <p>
        AWS' framework stands out for its Data Management guidance, particularly its relative breadth and depth compared
        with other frameworks. Although it is dominantly focused on framework implementation, additional detailed
        guidance is offered in their Well-Architected Framework Machine Learning Lens on AI Governance over the
        deployment and operation lifecycle phases. The OECD's framework could complement AWS' framework, especially for
        Data Quality practices.
      </p>
    </div>
  );
};

import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Grid, useMediaQuery } from "@mui/material";

import CustomHelmet from "../components/CustomHelmet";
import NewPostCard from "../components/blog/NewPostCard";

import SelectorButtons from "../components/nav/resources/SelectorButtons";
import ResourcesBurger from "../components/nav/resources/ResourcesBurger";

import logo from "../img/helmet/products/logo_cdmp.png";

import posts from "../data/blog/posts";

const Resources: React.FC = () => {
  const topics = ["All", "Data Strategy", "Data Privacy", "Data Analytics", "Data Science", "Career Services"];
  const desktop = useMediaQuery("(min-width: 1250px)");
  const location = useLocation();

  const postsSorted = posts
    .filter((post) => post.showOnHomepage)
    .sort((a, b) => {
      return b.updateDate.getTime() - a.updateDate.getTime();
    });

  const [topic, setTopic] = useState<string>(location.search ? location.search : "All");
  const history = useHistory();

  function createQueryParams(title) {
    return title.toLowerCase().split(" ").join("-");
  }

  function createTopic(dashedString: string): string {
    return dashedString
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const topicFromURL = searchParams.get("topic");
    if (topicFromURL) {
      const formattedTopic = createTopic(topicFromURL);
      if (topics.includes(formattedTopic)) {
        setTopic(formattedTopic);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    const topicForURL = topic ?? "All";
    const searchParams = new URLSearchParams(location.search);
    const currentTopicParam = searchParams.get("topic");

    if (createQueryParams(topicForURL) !== currentTopicParam) {
      searchParams.set("topic", createQueryParams(topicForURL));
      history.push({ pathname: location.pathname, search: searchParams.toString() });
    }
  }, [topic, history, location.search, location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        description="Articles to help you better understand Data Strategy."
        name="Resources"
        link="/resources"
        img={logo}
        alt="hand holding lightbulb"
      />
      <div className="home-layout">
        <div style={{ display: "grid", gridTemplateColumns: "auto 1fr" }}>
          <h1 className="title">Resources</h1>
          {desktop ? (
            <SelectorButtons marginTop={0} topics={topics} topic={topic} setTopic={setTopic} />
          ) : (
            <ResourcesBurger topics={topics} setTopic={setTopic} />
          )}
        </div>
        <Grid container spacing={4}>
          {postsSorted
            .filter((post) => topic === "All" || post.topic.includes(topic))
            .map((post) => (
              <Grid item xs={12} sm={6} md={4} key={post.nickname}>
                <NewPostCard post={post} />
              </Grid>
            ))}
        </Grid>
      </div>
    </>
  );
};

export default Resources;

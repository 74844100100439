import { Link } from "react-router-dom";

import Post from "../../components/blog/Post";
import PostImage from "../../components/blog/PostImage";
// import AIGFCDownloads from "./AIGFCDownloads";
import ExtendedDescription from "../../components/ExtendedDescription";
import EndnoteSource from "../../components/blog/EndnoteSource";
import AISystemLifecycle from "./AISystemLifecycle";

import aiAdoption from "../../img/blog/aigfc/ai-adoption.webp";
import aiInvestment from "../../img/blog/aigfc/ai-investment.webp";

const POSTNAME: postNickname = "aigfc-adoption";

const AIGFCAdoption: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default AIGFCAdoption;

const Rest = () => {
  const contents = [
    { anchor: "adoption", text: "Adoption and Investment Trends" },
    { anchor: "growth", text: "Signs of Growth" },
    { anchor: "stagnation", text: "Signs of Stagnation and Struggle" },
    { anchor: "barriers", text: "Barriers to Effective Adoption" },
    { anchor: "barriers-overview", text: "Overview of Barriers" },
    { anchor: "barriers-planning", text: "Planning and Resource Barriers" },
    { anchor: "barriers-deployment", text: "Deployment and Operation Barriers" },
    { anchor: "barriers-discussion", text: "Discusison" },
  ];

  return (
    <div className="blog">
      <p></p>
      {/* <AIGFCDownloads /> */}
      <p></p>
      <PostImage postName={POSTNAME} />
      <h4>Contents</h4>
      <ul>
        <li>
          <a href={"#" + contents[0].anchor}>{contents[0].text}</a>
          {contents.slice(1, 3).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
        <li>
          <a href={"#" + contents[3].anchor}>{contents[3].text}</a>
          {contents.slice(4, 8).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
      </ul>
      <h2 id={contents[0].anchor} style={{ marginTop: "0.2em" }}>
        {contents[0].text}
      </h2>
      <h4 id={contents[1].anchor}>{contents[1].text}</h4>

      <p>
        McKinsey's "
        <a
          href="https://www.mckinsey.com/capabilities/quantumblack/our-insights/the-state-of-ai#/download/%2F~%2Fmedia%2Fmckinsey%2Fbusiness%20functions%2Fquantumblack%2Four%20insights%2Fthe%20state%20of%20ai%2F2024%2Fthe-state-of-ai-in-early-2024-final.pdf%3FshouldIndex%3Dfalse"
          target="_blank"
          rel="noopener noreferrer"
        >
          The State of AI in Early 2024
        </a>
        " indicates that most businesses have now adopted AI.As shown in Figure 1.1, while adoption stood at around 50%
        from 2019-2023, 72% of businesses surveyed in early 2024 reported using AI in at least one business function.
        <sup>1</sup>
      </p>
      <p>
        What’s more, McKinsey's results indicate that this increased adoption in 2024 has been truly global. At least
        66% of respondents from nearly every major global region report using AI.
      </p>
      <figure>
        <img
          src={aiAdoption}
          alt="time series showing AI adoption"
          onClick={() =>
            window.open(
              "https://www.mckinsey.com/capabilities/quantumblack/our-insights/the-state-of-ai#/download/%2F~%2Fmedia%2Fmckinsey%2Fbusiness%20functions%2Fquantumblack%2Four%20insights%2Fthe%20state%20of%20ai%2F2024%2Fthe-state-of-ai-in-early-2024-final.pdf%3FshouldIndex%3Dfalse",
              "_blank"
            )
          }
          style={{ cursor: "pointer" }}
        />
        <figcaption>
          Figure 1.1 – "Organizations that have adopted AI in at least 1 business function" from "
          <a
            href="https://www.mckinsey.com/capabilities/quantumblack/our-insights/the-state-of-ai#/download/%2F~%2Fmedia%2Fmckinsey%2Fbusiness%20functions%2Fquantumblack%2Four%20insights%2Fthe%20state%20of%20ai%2F2024%2Fthe-state-of-ai-in-early-2024-final.pdf%3FshouldIndex%3Dfalse"
            target="_blank"
            rel="noreferrer"
          >
            The State of AI in Early 2024
          </a>
          ," McKinsey
        </figcaption>
      </figure>

      <p>
        The growing prevalence of AI among businesses is also indicated elsewhere. According to Stanford's "
        <a
          href="https://aiindex.stanford.edu/wp-content/uploads/2024/05/HAI_AI-Index-Report-2024.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          AI Index Report 2024
        </a>
        ," which broadly exploring recent developments in AI, AI was mentioned in the earning calls of nearly 80% of
        Fortune 500 companies, up 48% over earning call mentions in 2022.<sup>2</sup> A{" "}
        <a
          href="https://expleo.com/global/en/wp-content/documents/EXPLEO%20-%20AI%20REPORT.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          2024 guide
        </a>{" "}
        to the wide-scale business implementation of AI found that 87% of the 803 Western European C-level executives
        interviewed are at least actively exploring investment in AI tools.<sup>3</sup>
      </p>
      <p>
        Various use cases are motivating businesses to adopt AI. Among companies currently exploring or deploying AI, a
        2023{" "}
        <a
          href="https://newsroom.ibm.com/2024-01-10-Data-Suggests-Growth-in-Enterprise-Adoption-of-AI-is-Due-to-Widespread-Deployment-by-Early-Adopters?sf185480910=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          survey from IBM
        </a>{" "}
        found that automation of IT processes was the most common use case motivating adoption (33%), followed by
        security and threat detection (26%) and AI monitoring or governance (25%). Research and development (44%) and
        reskilling/workforce development (39%) were the most common AI investments.<sup>4</sup>
      </p>
      <p>
        By region, the US far exceeded all other regions in total 2023 private investments in AI. The US's total
        investment of $67 billion is a 22% increase on its 2022 investments and around three times as much as the
        investments of all other countries combined. In fact, US's total investment is 8.7 times higher than that of the
        next largest investor, China.<sup>2</sup>
      </p>
      <p>
        McKinsey attributes much of the newfound adoption and investment in AI to the rapidly developing capabilities of
        generative AI. In their 2023 survey, 33% of respondents were using gen AI with adoption increasing to 65% in
        2024.<sup>1</sup> Stanford's report supports this conclusion, showing that 2023 gen AI investments were around
        nine times higher than in 2022. Changes to investment in AI infrastructure, research, and governance for 2023,
        mainly in the major labs producing gen AI such as OpenAI and Anthropic, were even more extreme at around 20
        times higher year-to-year from under $1 billion in 2022 to over $18 billion in 2023. One-third of 2023 US
        investments were into gen AI, making up around 90% of global gen AI investments.<sup>2</sup>
      </p>
      <p>
        Many businesses are expressing optimism about the future impact of gen AI. KPMG's 2023 "
        <a
          href="https://kpmg.com/kpmg-us/content/dam/kpmg/pdf/2023/kpmg-gen-ai-survey2023.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Generative AI Survey
        </a>
        " found that 80% of respondents believe gen AI will disrupt their industry, while 77% believe it will be the
        most impactful emerging technology for their business, well ahead of second-placed 5G at 40%.<sup>5</sup>
      </p>
      <p>
        However, gen AI investment remains a lower priority than analytical AI for most industries. McKinsey found that
        while some industries allocated, on average, budgets of around 5% to gen AI and analytical AI each, most
        industries are reporting at least 20% more spending on analytical AI over gen AI.<sup>1</sup> This result is an
        important reminder of the high application value of analytical AI despite its lower coverage.
      </p>
      <h4 id={contents[2].anchor}>{contents[2].text}</h4>

      <p>
        A recent rise in global AI adoption, fueled by rapid gen AI development and high US investment, strongly
        indicates growth in AI adoption and investment. However, over the same period, many regions have seen declines
        in private AI investment while many businesses have struggled with AI deployment.
      </p>
      <figure>
        <img
          src={aiInvestment}
          alt="bar chart of ai investment activity"
          onClick={() =>
            window.open(
              "https://aiindex.stanford.edu/wp-content/uploads/2024/05/HAI_AI-Index-Report-2024.pdf",
              "_blank"
            )
          }
          style={{ cursor: "pointer" }}
        />
        <figcaption>
          Figure 1.2 – "Global corporate AI investment activity" from "
          <a
            href="https://aiindex.stanford.edu/wp-content/uploads/2024/05/HAI_AI-Index-Report-2024.pdf"
            target="_blank"
            rel="noreferrer"
          >
            AI Index Report 2024
          </a>
          ," Stanford
        </figcaption>
      </figure>
      <p>
        Figure 1.2 shows global corporate investment activity for the last decade. Before 2021, global corporate AI
        investment activity had grown rapidly year-on-year for a decade. However, overall global AI investment has
        declined since then. Investment activity peaked at $337 billion in 2021, declined to $235 billion in 2022, and
        then declined to $189 billion in 2023.<sup>2</sup>
      </p>
      <p>
        Private AI investment in China, the EU, the UK, and even the US have seen declines and stagnation over the same
        period. Whereas private Chinese investors invested over $20 billion in 2021, it fell to just below $15 billion
        in 2022 and $8 billion in 2023. Meanwhile, EU and UK investments have remained fairly constant since 2021,
        holding steady at around $11 billion annually. And, despite its investment growth since 2022, US private
        investment in AI has thus far peaked in 2021 at $80 billion, 23% higher than 2023's investment of $67 billion.
        <sup>2</sup>
      </p>
      <p>
        We also see a widespread struggle among businesses to adopt AI. Several surveys from IBM, largely of US-based
        investors, as part of a{" "}
        <a href="https://www.ibm.com/downloads/cas/6JAM3VB7" target="_blank" rel="noopener noreferrer">
          guide for CEOs
        </a>{" "}
        on adopting AI, emphasize the impact of current barriers on adoption and investment rates. Fewer than 60% of
        US-based executives surveyed feel prepared for AI regulations, and 56% are delaying large investments until
        there's greater regulatory clarity. Furthermore, 72% of organizations will forgo gen AI benefits due to ethical
        concerns.<sup>7</sup>
      </p>
      <p>
        Meanwhile, IBM's "
        <a
          href="https://newsroom.ibm.com/2024-01-10-Data-Suggests-Growth-in-Enterprise-Adoption-of-AI-is-Due-to-Widespread-Deployment-by-Early-Adopters?sf185480910=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Global AI Adoption Index 2023
        </a>
        " found that Data Privacy (57%) and trust and transparency concerns (43%) were the most substantial barriers to
        adoption, according to organizations not exploring or implementing gen AI.<sup>4</sup> Technical difficulties
        (39%), specifically integration with existing tools, are among the highest barriers to businesses adopting and
        scaling AI tools.<sup>3</sup>
      </p>
      <p>
        Such barriers are plausibly preventing many businesses from thoroughly integrating AI. When asked how far along
        respondents' businesses were in using AI on a four-stage scale, software development company Retool's 2024 "
        <a
          href="https://www.mongodb.com/resources/solutions/use-cases/retool-2024-state-of-ai-in-production?tck=retool_report_blog"
          target="_blank"
          rel="noopener noreferrer"
        >
          State of AI in Production
        </a>
        " found that only 30% of respondents thought their business was in either of the top two stages. What's more,
        the proportion of respondents self-reporting as being in the highest stage of use in 2024 was down to 9.8% from
        13.4% in 2023.<sup>6</sup>
      </p>
      <p>
        Barriers to adopting AI in 2023 and 2024 seem part of a broader trend. A{" "}
        <a
          href="https://www.capgemini.com/wp-content/uploads/2021/02/State-of-AI_Report_Web-2.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          2020 survey
        </a>{" "}
        from IT consultant company Capgemini of 993 companies with over $1B in annual revenue found that only 13% of
        respondents' organizations had rolled out multiple AI applications across multiple teams. Meanwhile, 72% of
        organizations had started piloting AI applications in 2019 and still hadn't been able to deploy a single
        application in production. Lack of mid-to senior-level AI talent (70%), lack of change management processes
        (65%), and lack of strong governance models for achieving scale (63%) were the top barriers to deployment.
        <sup>8</sup>
      </p>
      <p>
        <a
          href="https://techcrunch.com/2024/02/05/china-ai-investment-decline/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Reduced US venture capital
        </a>{" "}
        investment in China and a{" "}
        <a
          href="https://www.eib.org/en/press/all/2021-181-new-eib-report-eur10-billion-investment-gap-in-artificial-intelligence-and-blockchain-technologies-is-holding-back-the-european-union"
          target="_blank"
          rel="noopener noreferrer"
        >
          weak innovation ecosystem
        </a>{" "}
        in the EU are among the many factors proposed to explain recent investment declines.<sup>9, 10</sup>{" "}
        Simultaneously, barriers to AI adoption seem persistent and widely impact businesses. Nonetheless, businesses
        can make progress on capturing the value potential of AI while mitigating related risks by understanding and
        addressing key barriers to adoption.
      </p>

      <h2 id={contents[3].anchor}>{contents[3].text}</h2>
      <h4 id={contents[4].anchor}>{contents[4].text}</h4>
      <p>
        To structure this section, we have organized barriers as relevant to planning and resources or the deployment
        and operation phases of the <Link to="/resources/aigfc/intro#ai-lifecycle">AI system lifecycle</Link>.
      </p>
      <p>
        <strong>Planning and Resource Barriers</strong>:
      </p>
      <ul>
        <li>
          <strong>Lack of Expertise</strong>:&nbsp; when key roles lack knowledge or skills related to AI
        </li>
        <li>
          <strong>Cost and Investment</strong>:&nbsp; resources necessary to deploy and operate AI
        </li>
        <li>
          <strong>Uncertainty about Business Case</strong>:&nbsp; strategic uncertainty about the business use cases for
          and purpose of AI
        </li>
        <li>
          <strong>Uncertainty about Regulations</strong>:&nbsp; strategic uncertainty about what future regulations for
          AI will involve
        </li>
        <li>
          <strong>Cultural Resistance</strong>:&nbsp; where a large proportion of a business's staff are opposed to AI
          adoption
        </li>
      </ul>

      <p>
        <strong>Deployment and Operation Barriers</strong>:
      </p>
      <ul>
        <li>
          <strong>Risk Mitigation</strong>:&nbsp; AI risks and risk mitigation practices in alignment with standard
          definitions of AI risk, excluding risks explicitly related to data
        </li>
        <li>
          <strong>Data Management</strong>:&nbsp; related expressly to data practices, including some overlap with risk
          mitigation
        </li>
        <li>
          <strong>Technical and Organizational Logistics</strong>:&nbsp; barriers related to practically deploying AI on
          technical and organizational levels
        </li>
      </ul>

      <h4 id={contents[5].anchor}>{contents[5].text}</h4>
      <h6>Lack of expertise</h6>
      <p>
        A shortage of specialized skills, strategic understanding, and effective leadership seem to be the most
        substantial planning and resource barriers to AI deployment and scaling of initiatives.
      </p>
      <p>
        One study of global IT professionals shows that 33% identify limited AI skills and expertise as a top obstacle
        to AI deployment.<sup>4</sup> Among US business leaders, 24% cite a lack of leadership understanding or
        strategy, 28% report a shortage of skilled talent to develop and implement AI solutions, and 16% find gen AI
        challenging to use and learn.<sup>5</sup> West European C-suite executives echo this mix of leadership and
        technical skill gaps, with 28% pointing to an internal skills gap.<sup>3</sup>
      </p>
      <p>
        Further complicating matters, a comprehensive survey of organizations already using AI indicates that 35% face
        challenges related to talent when trying to capture value from gen AI.<sup>1</sup> Best practice assessments
        reveal that a vast majority fall short in several areas: 81% lack curated learning journeys tailored to build
        essential gen AI skills; 84% have not clearly defined the talent needed to execute their AI strategy; 83% do not
        maintain a talent strategy that supports effective recruitment, onboarding, and integration of AI-related
        talent; 79% have not appointed a credible, empowered leader for AI initiatives; and 78% of nontechnical
        personnel do not fully grasp the potential value or risks of gen AI.
        {/* <sup>14</sup> */}
      </p>
      <p>
        Additional global perspectives reinforce these concerns. One survey identifies a lack of skilled staff as one of
        the three top barriers to AI adoption.<sup>5</sup> Another survey points out that 53.7% of organizations
        struggle with a shortage of skilled personnel – such as data scientists, data engineers, or AI modelers.
        <sup>12</sup>
      </p>
      <p>
        The data indicate an urgent need to invest in targeted skill development, refine leadership strategies, and
        educate non-technical staff about the opportunities and risks associated with gen AI.
      </p>

      <h6>Cost and investment</h6>
      <p>
        Many businesses struggle to afford the necessities of AI adoption. Survey data highlight that the investment
        cost is a key concern for US business leaders, with 23% identifying it as a primary barrier to implementing gen
        AI.<sup>5</sup> Similarly, West European C-suite executives point to high implementation costs as a large
        obstacle, with 35% emphasizing the financial strain associated with new AI initiatives.<sup>3</sup>
      </p>
      <p>
        Beyond the initial outlay, financial management practices play a crucial role. Among organizations that have
        deployed AI, 86% lack funding and budgeting processes agile enough to support the continuous delivery of gen AI
        solutions.<sup>1</sup> Responses from outside the US and Western Europe further underscore the cost challenge,
        as one survey reveals that 54.3% of organizations cite cost as a top impediment to AI adoption.<sup>12</sup>
      </p>
      <p>
        These insights illustrate that cost and investment challenges extend well beyond upfront expenses. They point to
        systemic financial planning and resource allocation issues that must be addressed if organizations are to
        navigate the evolving demands of AI adoption.
      </p>

      <h6>Uncertainty about business case</h6>
      <p>
        Many organizations struggle to define and articulate the value that AI initiatives are expected to generate,
        leaving decision-makers hesitant to invest in transformative projects.
      </p>
      <p>
        Global IT professionals report that the inability to identify the right use case and the lack of a clear
        business case affect 20% of respondents, indicating that a large portion of organizations face difficulty
        justifying AI projects from a strategic or financial perspective.<sup>4</sup> Similarly, senior executives from
        West Europe reveal that nearly one-quarter of their peers harbor uncertainty about the return on investment,
        reflecting cautiousness when committing resources to AI initiatives.<sup>3</sup>
      </p>
      <p>
        Organizations already deploying AI further illustrate these challenges. A considerable number report strategic
        hurdles – with 39% citing challenges in capturing value from gen AI. Even more concerning, a large share of best
        practice respondents indicate that senior leaders do not fully grasp how gen AI can create business value, and a
        majority have yet to develop an enterprise-wide roadmap that effectively prioritizes AI initiatives based on
        value, feasibility, and risk.<sup>1</sup>
      </p>
      <p>
        Global surveys also highlight that over half of organizations experience unclear decision criteria and ambiguous
        business cases or lack sufficient support from key business units. This persistent uncertainty permeates the
        entire AI adoption process, from initial planning to full-scale deployment.<sup>12</sup>
      </p>
      <p>
        These insights point to a broader challenge: without a well-defined and compelling business case, the promise of
        AI remains clouded by ambiguity. Organizations must invest in developing rigorous frameworks that delineate the
        strategic benefits of AI, align initiatives with measurable outcomes, and secure the support necessary to drive
        transformative change.
      </p>

      <h6>Uncertainty about regulations</h6>
      <p>
        Uncertainty about regulations leads to fear of noncompliance and legal exposure when adopting AI. Data indicates
        that a substantial number of US-based executives feel unprepared for upcoming AI regulations, with 60%
        expressing concerns about their readiness and 56% delaying major investments until greater regulatory clarity is
        achieved.<sup>7</sup>
      </p>
      <p>
        Among US business leaders, apprehensions about the evolving regulatory landscape also play a substantial role.
        About 30% rank these concerns among the top three barriers to implementing gen AI, while 21% worry about
        potential legal exposure that could complicate deployment and scaling efforts⁵. In West Europe, the picture is
        relatively consistent – 23% of C-suite executives cite regulatory uncertainties as a primary obstacle to
        adopting and scaling AI tools.<sup>3</sup>
      </p>
      <p>
        Businesses seem caught between the promise of innovation and the risks of navigating an unpredictable legal
        framework, which delays investment and slows overall progress.
      </p>

      <h6>Cultural resistance</h6>
      <p>Internal skepticism and resistance from employees is slowing the pace of adoption within businesses.</p>
      <p>
        In one survey, 28% of US business leaders identified internal cultural resistance as a top obstacle to
        implementing gen AI.<sup>4</sup> Similarly, West European C-suite executives report that 31% experience employee
        resistance as a major challenge when adopting and scaling AI tools.<sup>3</sup>
      </p>
      <p>
        The data suggest that even the most advanced AI solutions may struggle to thrive without cultivating a culture
        of innovation and trust. Resistance may stem from uncertainty about new technologies, concerns over job
        security, or discomfort with shifts in established work processes. Such barriers highlight the need for
        organizations to address Change Management proactively by engaging employees, providing targeted training, and
        fostering open dialogue about the benefits and impacts of AI.
      </p>

      <h4 id={contents[6].anchor}>{contents[6].text}</h4>
      <h6>Risk Mitigation</h6>
      <p>
        Recent surveys consistently underscore that risk mitigation remains a pervasive barrier to AI adoption,
        highlighting concerns that span ethical considerations, trust and transparency, data privacy, and the overall
        robustness of governance frameworks. These issues are not peripheral – they fundamentally shape how
        organizations deploy and scale AI systems.
      </p>
      <p>
        A survey of global IT professionals revealed that ethical concerns are among the top inhibitors to AI
        deployment, with 23% of respondents citing them as a critical barrier. In the realm of gen AI, the challenges
        intensify: 43% of professionals reported that trust and transparency issues significantly impede adoption, while
        57% flagged Data Privacy as a major concern.<sup>4</sup> Complementing these findings, a separate survey
        targeting executives found that 72% expect their organizations to forgo the potential benefits of gen AI due to
        ethical issues.<sup>7</sup>
      </p>
      <p>
        Further reinforcing these challenges, 21% of US business leaders in a targeted survey expressed a lack of
        confidence in the accuracy and reliability of gen AI tools.<sup>5</sup> In a broader study examining the overall
        AI lifecycle, 35% of respondents identified risk and responsible AI as key hurdles to capturing value from gen
        AI.
      </p>
      <p>
        Moreover, a considerable proportion of businesses appear unprepared to manage these risks: 64% do not consider
        risk awareness and mitigation as essential skills for technical talent; 76% lack clear processes to integrate
        risk mitigation into their AI solutions (e.g., by involving legal or compliance functions); 81% indicated that
        their gen AI models are not designed to facilitate audits, bias checks, or risk assessments; and 82% reported
        the absence of an enterprise-wide council or board dedicated to responsible AI Governance.<sup>1</sup>
      </p>
      <p>
        In addition, insights from surveys point to governance-related deficiencies as central obstacles. One survey
        identified the lack of AI Governance and risk management solutions as one of the top barriers preventing broader
        AI adoption.<sup>5</sup> In contrast, another survey highlighted that 53.8% of organizations experienced
        shortcomings in their risk management frameworks, with 50.8% lacking tools to ensure fairness, explainability,
        and transparency and 52.6% expressing concerns regarding the adversarial robustness of their algorithms.
        <sup>12</sup>
      </p>
      <p>
        Together, these findings illustrate that while investment and interest in AI continue to grow, risk mitigation
        remains a critical stumbling block. Addressing these challenges requires organizations to develop comprehensive
        governance frameworks, embed risk management practices throughout the AI lifecycle, and enhance technical
        capabilities that support continuous auditability and bias detection.
      </p>
      <AISystemLifecycle figureNumber="1.3" />

      <h6>Data Management</h6>
      <p>
        Data Management as a barrier to AI adoption is characterized by data complexity, inaccessibility, inadequate
        governance, and security concerns.
      </p>
      <p>
        A survey of IT professionals in managerial roles revealed that 25% consider excessive data complexity a primary
        obstacle to AI deployment.<sup>4</sup> In parallel, another study involving business leaders in the United
        States found that 16% identified the inability to access and leverage data as a critical impediment to
        implementing gen AI.<sup>5</sup> These findings suggest that organizations may struggle to realize the full
        benefits of AI innovation without streamlined data processes.
      </p>
      <p>
        Further investigation into organizational practices uncovers deeper systemic issues. Respondents from various
        industries reported that 27% of organizations lack systems that provide data accessibility and seamless
        integration. Even more striking, 80% acknowledged lacking a robust Data Governance program to define and enforce
        essential policies and processes. Additionally, over 60% admitted to a limited understanding of their data
        sources and insufficient policies for managing diverse data types, while 23% noted the persistence of ungoverned
        data silos.<sup>13</sup>
      </p>
      <p>
        Data Security and strategic data planning are also substantial Data Management barriers. In a survey among
        senior executives, 19% highlighted Data Security as a challenge in adopting and scaling AI tools.<sup>3</sup>{" "}
        Moreover, 38% of organizations experienced difficulties in capturing AI-derived value due to data-related
        issues, with 83% lacking a defined Data Strategy and 76% failing to leverage data consistently to drive
        performance.<sup>1</sup> Finally, over half of the respondents cited inadequate volumes and quality of training
        data as a notable hindrance.<sup>12</sup>
      </p>
      <p>
        These surveys strongly indicate that Data Management is not an isolated issue but a multifaceted barrier that
        intersects with technical complexity, organizational infrastructure, and strategic execution. For organizations
        to effectively adopt AI, they need to invest in integrated data systems, develop comprehensive governance
        frameworks, and implement robust data strategies.
      </p>

      <h6>Technical and organizational logistics</h6>
      <p>
        Logistical barriers involve a combination of technical constraints and organizational shortcomings. This barrier
        encompasses issues ranging from legacy systems' rigidity to infrastructure deficits and difficulties in
        integrating new technologies into existing operational frameworks.
      </p>
      <p>
        Survey data underscores the multifaceted nature of these logistical challenges. In one study of business
        leaders, 28% identified an inability to pivot legacy applications and systems as a primary impediment, while 22%
        noted that insufficient technology infrastructure further restricts progress. Additionally, 16% of respondents
        described these systems as difficult to use and learn, highlighting a usability challenge that compounds the
        technical limitations.<sup>5</sup>
      </p>
      <p>
        Further insights from a survey of senior executives reveal an even more pronounced reliance on external
        expertise, with 60% indicating that dependence on outside specialists creates a bottleneck in AI deployment.
        This external reliance is accompanied by hurdles in technical integration – with 39% of respondents pointing to
        difficulties in integrating AI tools with existing systems – and organizational issues, as 27% reported internal
        challenges that obstruct effective AI scaling.<sup>3</sup>
      </p>
      <p>
        Additional analysis deepens the understanding of logistical constraints. A comprehensive study found that nearly
        half of respondents (47%) face challenges with their operating models, 43% encounter technology-related
        barriers, and 33% report difficulties with adopting and scaling AI initiatives. Notably, the integration of best
        practices is lacking, as evidenced by 82% of organizations that do not embed testing and validation in their
        model release processes and a striking 93% that have not established live monitoring systems to address issues
        quickly.<sup>1</sup>
      </p>
      <p>
        Complementing these findings, another study reported that 52% of organizations lack the necessary tools to
        monitor model performance for data and concept drift, and 51.6% struggle with establishing effective machine
        learning operations, underscoring the operational gaps that can undermine AI initiatives.<sup>12</sup>
      </p>
      <p>
        The data illustrates that logistical barriers are not merely technical issues but also reflect broader
        organizational challenges. Addressing these barriers will require concerted efforts to modernize legacy systems,
        invest in robust technological infrastructures, and cultivate internal expertise.
      </p>

      <h4 id={contents[7].anchor}>{contents[7].text}</h4>
      <p>
        Recent investigations into AI adoption reveal a complex range of challenges businesses currently navigate.
        Evidence currently indicates that deployment and operational barriers are especially prevalent.
      </p>
      <p>
        One of the most prominent barriers is risk mitigation. Surveys consistently show that ethical concerns, trust
        deficits, transparency issues, and data privacy worries are not peripheral but central to AI's deployment. For
        example, 23% of IT professionals consider ethical dilemmas critical, 43% of those working with gen AI highlight
        trust and transparency issues, and 57% cite data privacy as a key stumbling block. This pervasive unease with
        deploying AI is compounded by many organizations lacking robust governance frameworks, with large portions
        admitting that risk awareness is neither prioritized nor systematically integrated into their AI strategies.
      </p>
      <p>
        Data Management challenges further exacerbate the issue. Excessive data complexity and inaccessibility are key
        impediments, with 25% of IT professionals and 16% of US business leaders underscoring these hurdles. Unresolved
        data silos, insufficient policies, and subpar training data quality further impede the effective deployment of
        AI.
      </p>
      <p>
        Planning and resource barriers add another layer of complexity. A notable shortage of specialized skills and
        leadership insight is evident, with around 33% of IT professionals and up to 28% of executives citing these
        deficiencies. High costs and ambiguous business cases, coupled with regulatory uncertainties and cultural
        resistance – reported by 28% to 31% of respondents – create a multifaceted obstacle. Many organizations hesitate
        to invest heavily in AI due to unclear strategic benefits and fears of noncompliance with evolving regulations.
      </p>
      <p>
        Technical and organizational logistics round out the challenges. Legacy systems, inadequate infrastructure, and
        difficulties in integrating new technologies with existing frameworks continue to hinder progress. Survey data
        indicates that 28% of business leaders struggle with outdated applications, while reliance on external
        specialists and fragmented integration processes further slow down AI initiatives. Nearly half of businesses
        report challenges with their operating models and technology infrastructures, revealing that logistical
        constraints are as much an organizational issue as a technical one.
      </p>
      <p>
        Overall, these investigations underscore that as interest in AI grows, its broad adoption is stymied by
        intertwined challenges across risk management, data handling, technical logistics, and strategic planning.
        Addressing these barriers will require a holistic approach – enhancing governance, modernizing infrastructure,
        cultivating specialized talent, and fostering a culture that embraces innovation.
      </p>
      <br />
      <ExtendedDescription title="Endnotes" description={Endnotes()} blog={true} />
    </div>
  );
};

const Endnotes = () => {
  const endnotes = [
    "aigfc-004",
    "aigfc-009",
    "aigfc-008",
    "aigfc-001",
    "aigfc-003",
    "aigfc-010",
    "aigfc-002",
    "aigfc-011",
    "aigfc-012",
    "aigfc-013",
    "aigfc-014",
    "aigfc-015",
    "aigfc-016",
  ];
  return (
    <ol style={{ marginLeft: 0, paddingLeft: "1em" }}>
      {endnotes.map((endnote) => (
        <EndnoteSource key={endnote} endnoteId={endnote} />
      ))}
    </ol>
  );
};

const Jobs: React.FC = () => {
  return (
    <div>
      <ul>
        <li>
          <a
            href="https://docs.google.com/document/u/1/d/1-PUHHTD7fR_b6axgPQAC5MVKqIU90gn7OzxaBBbg3hw/preview"
            target="_blank"
            rel="noreferrer"
          >
            CDMP Subject Matter Expert (SME)
          </a>{" "}
          – <em>posted Jan 29, 2024; remote</em>
        </li>
        <li>
          <a
            href="https://docs.google.com/document/d/17u27P7pJTfN53A396G8gUzvK4vBhWk5-yUNVGXrWQB0/preview"
            target="_blank"
            rel="noreferrer"
          >
            CAP Subject Matter Expert (SME)
          </a>{" "}
          – <em>posted Jan 28, 2024; remote</em>
        </li>
      </ul>
    </div>
  );
};

export default Jobs;

import { Link } from "react-router-dom";

import Post from "../../components/blog/Post";
import PostImage from "../../components/blog/PostImage";
import AIGFCDownloads from "./AIGFCDownloads";

import incidentsAndHazards from "../../img/blog/aigfc/incidents-and-hazards.webp";
import aiIncidents from "../../img/blog/aigfc/ai-incidents.webp";
import harmClassification from "../../img/blog/aigfc/harm-classification.webp";
import incidentsByIndustry from "../../img/blog/aigfc/incidents-by-industry.webp";
import riskRelevance from "../../img/blog/aigfc/risk-relevance.webp";
import riskMitigation from "../../img/blog/aigfc/risk-mitigation.webp";
import gap from "../../img/blog/aigfc/gap.webp";

const POSTNAME: postNickname = "aigfc-risks";

const AIGFCRisks: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default AIGFCRisks;

const Rest = () => {
  const contents = [
    { anchor: "importance", text: "The Importance of Understanding Risks" },
    { anchor: "overview", text: "Overview" },
    { anchor: "defining", text: "Defining and Measuring Risks" },
    { anchor: "evidence", text: "Evidence of Negative Impacts" },
    { anchor: "survey-responses", text: "Survey Responses" },
    { anchor: "ai-incidents", text: "AI Incident Tracking Databases" },
    { anchor: "findings", text: "Findings About AI Incidents" },
    { anchor: "business", text: "Business Prioritization and Mitigation" },
    { anchor: "perceptions", text: "Perceptions of Different Risks" },
    { anchor: "gap", text: "A Risk Management Perception-Action Gap" },
    { anchor: "trends", text: "Trends from 2019-2024" },
    { anchor: "other-surveys", text: "Other Survey Data from 2023-2024" },
    { anchor: "evaluation", text: "An Evaluation of Public AI Governance Disclosures" },
    { anchor: "barriers", text: "Addressing Deployment and Operation Barriers" },
  ];

  return (
    <div className="blog">
      <PostImage postName={POSTNAME} />
      <h4>Contents</h4>
      <ul>
        <li>
          <a href={"#" + contents[0].anchor}>{contents[0].text}</a>
          {contents.slice(1, 3).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
        <li>
          <a href={"#" + contents[3].anchor}>{contents[3].text}</a>
          {contents.slice(4, 7).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
        <li>
          <a href={"#" + contents[7].anchor}>{contents[7].text}</a>
          <li>
            <a href={"#" + contents[8].anchor}>{contents[8].text}</a>
          </li>
        </li>
        <li>
          <a href={"#" + contents[9].anchor}>{contents[9].text}</a>
          {contents.slice(10, 13).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
        <li>
          <a href={"#" + contents[13].anchor}>{contents[13].text}</a>
        </li>
      </ul>
      <h2 id={contents[0].anchor}>{contents[0].text}</h2>
      <h4 id={contents[1].anchor}>{contents[1].text}</h4>
      <p>
        While AI has significant potential to create and capture value, it also introduces novel and exacerbated
        business risks. Risk mitigation is among the most common barriers identified to AI adoption and is commonly
        prioritized as a component of AI Governance processes.
      </p>
      <p>
        Many large companies prioritize AI risk mitigation.{" "}
        <a href="https://www.ibm.com/topics/ai-governance" target="_blank" rel="noreferrer">
          IBM
        </a>{" "}
        highlights risk mitigation as among the three key components of their approach to governing AI, while{" "}
        <a
          href="https://docs.aws.amazon.com/pdfs/whitepapers/latest/aws-caf-for-ai/aws-caf-for-ai.pdf#governance-perspective-managing-an-aiml-driven-organization"
          target="_blank"
          rel="noreferrer"
        >
          AWS
        </a>{" "}
        lists it as one of four key factors in their governance approach. AstraZeneca{" "}
        <a
          href="https://www.frontiersin.org/journals/computer-science/articles/10.3389/fcomp.2022.1068361/full"
          target="_blank"
          rel="noreferrer"
        >
          reflected
        </a>{" "}
        on its recent experience implementing AI Governance, emphasizing the need to "prioritize defining and
        controlling the risk appetite of different projects." More broadly, Stanford's "
        <a
          href="https://aiindex.stanford.edu/wp-content/uploads/2024/05/HAI_AI-Index-Report-2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          AI Index Report 2024
        </a>
        " found that 86% of the over 1000 large companies they surveyed agree or strongly agree that "generative AI
        presents enough of a threat that globally agreed generative AI Governance is required."
      </p>
      <p>
        Governments, too, are introducing risk-focused policies. The "
        <a
          href="https://www.europarl.europa.eu/topics/en/article/20230601STO93804/eu-ai-act-first-regulation-on-artificial-intelligence"
          target="_blank"
          rel="noreferrer"
        >
          EU AI Act
        </a>
        " takes a risk-focused approach to AI regulation, categorizing regulation by risk level and{" "}
        <a
          href="https://futurium.ec.europa.eu/en/european-ai-alliance/best-practices/implementing-ai-governance-framework-practice"
          target="_blank"
          rel="noreferrer"
        >
          arguing
        </a>{" "}
        that businesses should "actively address and mitigate any potential risks and negative consequences that may
        arise." The Centre for Emerging Technology and Security goes further in a{" "}
        <a
          href="https://cetas.turing.ac.uk/sites/default/files/2023-08/cetas-cltr_ai_risk_briefing_paper.pdf"
          target="_blank"
          rel="noreferrer"
        >
          guide
        </a>{" "}
        for the UK government. They argue that AI risks are currently so underprioritized that delaying progress any
        further could lead to one of two outcomes: either AI risks will cause widespread harm to society, or fear of
        risks will result in the complete cessation of AI adoption.
      </p>
      <p>
        As explored later, many businesses perceive AI risks as relevant to them. In Stanford's "
        <a
          href="https://aiindex.stanford.edu/wp-content/uploads/2024/05/HAI_AI-Index-Report-2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          AI Index Report 2024
        </a>
        ," among the 10 risks asked about, privacy and data governance had the highest perceived relevance globally, at
        51%. But many others don't think different risks are relevant to them—fairness was the risk rated as least
        relevant, with an average across regions of 29%.
      </p>
      <p>
        Many data practices essential for AI deployment and operation are directly linked to AI risks. As discussed
        later, Data Governance is closely tied to AI Governance. This tie includes Data Quality practices, which help
        prevent bias and transparency risks, as well as Data Security practices to address data protection and privacy
        risks.
      </p>
      <p>
        In addition to risk mitigation proving to be a critical barrier to AI adoption, there's further evidence that
        many businesses aren't familiar with AI risks. In IAPP-EY's{" "}
        <a
          href="https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report_executive_summary.pdf"
          target="_blank"
          rel="noreferrer"
        >
          2023 survey
        </a>{" "}
        about organizational AI Governance, 56% of respondents believe their organizations don't understand AI's risks
        and benefits. Meanwhile, in a{" "}
        <a
          href="https://wp.ans.co.uk/l/909202/2024-03-14/c4wgj4/909202/1710419434pJajeBkT/ANS_Business_Blockers_Report_March_2024_SMB.pdf"
          target="_blank"
          rel="noreferrer"
        >
          study
        </a>{" "}
        by ANS of 254 SMBs in the UK, in regards to how businesses felt about AI, 16% went as far as to select that AI
        "scares the hell out of me and what it means for the future of my business."
      </p>
      <p>
        We find that there are two groups of businesses that need to improve their knowledge of and practices for AI
        risk mitigation. The first group is those who don't think AI risks are relevant to them despite evidence for the
        growing frequency and breadth of AI incidents and their negative impacts. This group needs to understand how
        ubiquitous many AI risks have become across industries and that the frequency of incidents is increasing.
      </p>
      <p>
        The second group describes those who claim to be prioritizing or at least see AI risks as relevant to them but
        who aren't following up with action to mitigate these risks. This group needs to understand the necessity of
        practically mitigating AI risks and get any support necessary for them to start doing so. Nonetheless, both
        groups of businesses need to start mitigating AI risks in practice; they're increasingly vulnerable to AI
        harming them and broader society.
      </p>
      <p>
        In response to the evidence for the importance of risk mitigation as part of AI adoption, issues with risk
        mitigation being key barriers to adoption, and a lack of understanding of AI risks among businesses, we explore
        AI's risks and businesses' efforts to mitigate them.
      </p>
      <h4 id={contents[2].anchor}>{contents[2].text}</h4>
      <p>
        The exceptional analytical and generative power of current AI, let alone that of{" "}
        <a href="https://epochai.org/trends" target="_blank" rel="noreferrer">
          increasingly powerful
        </a>{" "}
        future AI, presents a series of risks. Commonly highlighted risks include{" "}
        <a href="https://theaipi.org/ai-interactive-map/" target="_blank" rel="noreferrer">
          increasing unemployment
        </a>
        ,{" "}
        <a
          href="https://www3.weforum.org/docs/WEF_AI_Governance_Alliance_Briefing_Paper_Series_2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          disrupting data governance
        </a>
        , and individual privacy breaches.
      </p>
      <p>
        The US National Institute of Standards and Technology (NIST) is likely the strongest current authority on
        cataloging risks from AI. Their "AI Risk Management Framework (
        <a href="https://nvlpubs.nist.gov/nistpubs/ai/NIST.AI.100-1.pdf" target="_blank" rel="noreferrer">
          AI RMF
        </a>
        )" frames risks as threats to AI's trustworthiness, stating that "approaches that enhance AI trustworthiness can
        reduce negative AI risks."
      </p>
      <p>
        The properties of trustworthiness outlined in the NIST AI RMF include valid and reliable, safe, secure and
        resilient, accountable and transparent, explainable and interpretable, privacy-enhanced, and fair AI. Similar AI
        risk taxonomies exist, such as Singapore's "
        <a
          href="https://www.pdpc.gov.sg/-/media/Files/PDPC/PDF-Files/Resource-for-Organisation/AI/SGModelAIGovFramework2.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Model AI Governance Framework
        </a>
        " and Stanford's "
        <a
          href="https://aiindex.stanford.edu/wp-content/uploads/2024/05/HAI_AI-Index-Report-2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          AI Index Report 2024.
        </a>
        ".
      </p>
      <p>
        However, operationalizing such risk frameworks has been challenging. Our current ability to define, monitor for,
        and address risks, let alone understand how likely they are to occur, is highly variable.
      </p>
      <p>
        Risks range from well-documented, more concrete problems such as{" "}
        <a
          href="https://hai.stanford.edu/news/privacy-ai-era-how-do-we-protect-our-personal-information"
          target="_blank"
          rel="noreferrer"
        >
          Data Privacy
        </a>{" "}
        to more challenging to analyze and{" "}
        <a
          href="https://aiindex.stanford.edu/wp-content/uploads/2024/05/HAI_AI-Index-Report-2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          extreme risks
        </a>{" "}
        such as potential long-term{" "}
        <a
          href="https://assets.publishing.service.gov.uk/media/6655982fdc15efdddf1a842f/international_scientific_report_on_the_safety_of_advanced_ai_interim_report.pdf"
          target="_blank"
          rel="noreferrer"
        >
          existential threats
        </a>
        . Stanford's "
        <a
          href="https://aiindex.stanford.edu/wp-content/uploads/2024/05/HAI_AI-Index-Report-2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          AI Index Report 2024
        </a>
        " outlines a range of risk evaluation benchmarks that have been devised to date. These include DecodingTrust for
        broadly evaluating model trustworthiness, TruthfulQA for assessing the truthfulness of Large Language Model
        (LLM) outputs, and RealToxicityPrompts for assessing output toxicity. Academic papers citing these benchmarks
        have become increasingly numerous over time, especially for TruthfulQA in 2023.
      </p>
      <p>
        Understanding how risks may then be efficiently and reliably addressed presents additional challenges. In 2024,
        the Center for AI Safety released{" "}
        <a href="https://arxiv.org/abs/2402.04249" target="_blank" rel="noreferrer">
          HarmBench
        </a>
        , "a standardized evaluation framework for automated red teaming and robust refusal." In addition to helping
        uncover new desirable properties of methods for finding AI risks, HarmBench has been claimed to improve LLM
        robustness against a wide range of attacks.
      </p>
      <p>
        To best contextualize AI Governance's importance for businesses, we've highlighted empirical evidence for the
        incident rate and perceived priority of better-documented, more concrete risks.
      </p>
      <h2 id={contents[3].anchor}>{contents[3].text}</h2>
      <h4 id={contents[4].anchor}>{contents[4].text}</h4>
      <p>
        There is an increasing amount of data on AI's negative impacts. Below, we explore survey results about the
        negative impacts businesses report experiencing before looking more in-depth at the research to record and learn
        from individual AI incidents.
      </p>
      <p>
        Many businesses report experiencing negative impacts from AI. For example, Data Robot's 2022 "
        <a
          href="https://www.datarobot.com/newsroom/press/datarobots-state-of-ai-bias-report-reveals-81-of-technology-leaders-want-government-regulation-of-ai-bias/"
          target="_blank"
          rel="noreferrer"
        >
          State of AI Bias Report
        </a>
        " found that 36% of 350 organizations surveyed across the US and UK experienced challenges or direct negative
        impacts on their businesses. These impacts included losing revenue (62%), customers (61%), or employees (43%)
        due to an occurrence of AI bias in their algorithms.
      </p>
      <p>
        Looking at a broader range of risks, McKinsey's "
        <a
          href="https://www.mckinsey.com/~/media/mckinsey/business%20functions/quantumblack/our%20insights/the%20state%20of%20ai/2024/the-state-of-ai-in-early-2024-final.pdf?shouldIndex=false"
          target="_blank"
          rel="noreferrer"
        >
          The State of AI in Early 2024
        </a>
        " found that 61% of their respondents had experienced negative impacts from at least one risk listed. The two
        most commonly experienced impacts are inaccuracy (23%) and cybersecurity (16%). IDC's{" "}
        <a href="https://www.ibm.com/downloads/cas/KXVRM5QE" target="_blank" rel="noreferrer">
          2022 survey
        </a>{" "}
        found that among 2,017 respondents, customer privacy loss (32.1%), revenue loss (31.3%), and damage to brand
        reputation (31.1%) were the three most commonly experienced negative impacts.
      </p>
      <p>
        Given the prolific rise of individual LLM use since ChatGPT's Nov 2022 release, negative impacts from AI can
        also occur through employee accidents.{" "}
        <a
          href="https://www.pcmag.com/news/samsung-bans-chatgpt-after-engineers-use-it-to-fix-proprietary-code"
          target="_blank"
          rel="noreferrer"
        >
          Samsung
        </a>{" "}
        and{" "}
        <a
          href="https://www.the-sun.com/money/7550332/walmart-chatgpt-artificial-intelligence/"
          target="_blank"
          rel="noreferrer"
        >
          Walmart
        </a>{" "}
        are among many large businesses that banned the use of ChatGPT among their employees in early to mid-2023.
        ChatGPT has been used to help with problems related to proprietary and private customer information.
      </p>
      <p>
        Retool's 2024 "
        <a
          href="https://www.mongodb.com/resources/solutions/use-cases/retool-2024-state-of-ai-in-production?tck=retool_report_blog"
          target="_blank"
          rel="noreferrer"
        >
          The State of AI in Production
        </a>
        " found that over 27% of its primarily technical respondents secretly use AI at work. Ambiguities about business
        AI policies and perceived stigma against AI usage were among the main motivating factors for secrecy.
      </p>
      <h4 id={contents[5].anchor}>{contents[5].text}</h4>
      <p>
        A growing number of organizations are tracking AI incidents. This research gives us more granular insight into
        incident trends, mainly the frequency of AI incidents and their adverse impacts.
      </p>
      <p>
        The Responsible AI Collaborative's AI Incident Database (
        <a href="https://incidentdatabase.ai/" target="_blank" rel="noreferrer">
          AIID
        </a>
        ) and the OECD's AI Incident Monitoring (
        <a
          href="https://oecd.ai/en/incidents?search_terms=%5B%5D&and_condition=false&from_date=2013-12-31&to_date=2024-10-18&properties_config=%7B%22principles%22:%5B%5D,%22industries%22:%5B%5D,%22harm_types%22:%5B%5D,%22harm_levels%22:%5B%5D,%22harmed_entities%22:%5B%5D%7D&only_threats=false&order_by=date&num_results=20"
          target="_blank"
          rel="noreferrer"
        >
          AIIM
        </a>
        ) database are each live databases on AI incidents. AIID's stated aim for tracking AI incidents is to build a
        "repository of problems experienced in the real world so that future researchers and developers may mitigate or
        avoid repeated bad outcomes." Research into understanding AI risks can thus be based on updated, comprehensive,
        and high-quality incident data.
      </p>
      <p>
        AIID collects data on incidents through manually written reports from a community of users, from system
        architects to public policy researchers. Reports focus on how recently deployed intelligent systems have
        produced unexpected outcomes in the real world. Strongly influenced by the commercial air travel industry, these
        "accidents" are defined as cases "where substantial damage or loss of life occurs," and "incidents" are defined
        as cases "where the risk of an accident substantially increases."
      </p>
      <p>
        Adaptive criteria for classifying individual incidents are used amid uncertainty about how best to differentiate
        algorithms, intelligence, and the harms they may directly or indirectly cause. An autonomous vehicle killing a
        pedestrian and a trading algorithm causing a market "flash crash" are both incidents.
      </p>
      <p>
        Various taxonomies are used to classify incidents. For example, the Goals, Methods, and Failures (GMF) taxonomy
        tries to identify the causes of AI failures. The GMF currently indicates that AI incidents have a wide range of
        failures and causes, with autonomous vehicle incidents being the most numerous at 7.8% of incidents. Another
        taxonomy is the CSET AI Harm Taxonomy, which characterizes AI incidents and classifies harms relevant to the
        public policy community.
      </p>
      <p>
        The OECD's AIIM database automates incident reporting using AIs to identify and classify incidents and hazards
        reported in international media. An incident is an event, circumstance, or series of events where the
        development, use, or malfunction of one or more AI systems directly or indirectly leads to one of four different
        harms. An AI hazard is the risk of an AI incident, and an accident occurs when harm occurs.
      </p>
      <p>
        The incident-finding model uses the news intelligence platform Event Registry to detect specific event types
        reported in news articles using information from the title, abstract, and first few paragraphs. Similar models
        then classify incidents and hazards into different categories from the OECD Framework for the Classification of
        AI Systems. The severity level of the incident, the type of harm caused, and the general group to which the
        harmed parties belong are determined for each incident.
      </p>
      <h4 id={contents[6].anchor}>{contents[6].text}</h4>
      <p>
        Graphing the number of incidents over time, we can see that the AIIM and AIID database indicates slightly
        different trends. However, both databases indicate that AI incidents have increased over time.
      </p>
      <figure>
        <img src={incidentsAndHazards} alt="line graph of AI incidents since 2014" />
        <figcaption>
          Figure 2.1 – Evolution of incidents and hazards from the OECD's AI Incident Monitoring (
          <a href="https://oecd.ai/en/incidents?search_terms=%5B%5D&and_condition=false&from_date=2013-12-31&to_date=2024-10-18&properties_config=%7B%22principles%22:%5B%5D,%22industries%22:%5B%5D,%22harm_types%22:%5B%5D,%22harm_levels%22:%5B%5D,%22harmed_entities%22:%5B%5D%7D&only_threats=false&order_by=date&num_results=20">
            AIIM
          </a>
          ) Database
        </figcaption>
      </figure>
      <figure>
        <img src={aiIncidents} alt="line graph of AI incidents sine 1990" />
        <figcaption>
          Figure 2.2 – AI Incidents Reported Since 1990 from Responsible AI Collaborative's AI Incident Database (
          <a href="https://incidentdatabase.ai/research/snapshots/">AIID</a>)
        </figcaption>
      </figure>
      <p></p>
      <p>
        Comparisons between the types of harm each database records are less straightforward due to differences in the
        taxonomies and graph types used. Robustness and digital security, respect for human rights, and privacy and data
        governance risks are among the "AI principles" AIIM's database indicates have been especially prevalent.
      </p>
      <p>
        Meanwhile, the Harm Distribution Basis from AIID – published as part of a project by the Center for Security and
        Emerging Technology in July 2023 – identifies the proportion of intangible harms that affect different group
        types. Race is by far the most widely impacted group type, followed by sex then religion.
      </p>
      <p>
        Looking at Figure 2.3 using data from the CSETv1 dataset, we can see that many incidents present tangible harm.
        For an incident to have tangible harm, there must be an entity that experienced a harm event or harm issue that
        can be directly linked to a consequence of the behavior of an AI system. Intangible harms currently refer
        exclusively to harms from bias and fairness risks.
      </p>
      <figure>
        <img src={harmClassification} alt="harm classification bar chart" />
        <figcaption>Figure 2.3 – Harm Classification from the CSETv1 Dataset</figcaption>
      </figure>
      <p>
        What most clearly demonstrates the broad applicability of AI incidents to different businesses is the data each
        database offers about industries affected by AI incidents. On Figure 2.4, taken from AIIM's database, the number
        of AI incidents has increased significantly across industries from early 2023 onwards, although there's high
        variance in the degree of increase between industries, and incident numbers seem to have since plateaued for
        some risks.
      </p>
      <p>
        Incident rates have been especially high for digital security, IT infrastructure and hosting, and government,
        security, and defense on average. However, in February 2024 alone, media, social platforms, and marketing
        recorded 138 incidents, while business processes and support services experienced 78 incidents in the same
        month. AIID finds that most incidents relate to information and communication, transportation and storage, and
        wholesale and retail trade. Both databases find incidents related to other industries as well.
      </p>
      <figure>
        <img src={incidentsByIndustry} alt="line chart of AI incidents by industry" />
        <figcaption>
          Figure 2.4 – Evolution of incidents and hazards by industry from the OECD's AI Incident Monitoring database
        </figcaption>
      </figure>
      <p>
        Each database provides various insights into past AI incidents and their trends. The evidence for the frequency
        of incidents generally increasing over time is moderately strong, especially given this broad trend is found
        using two different AI incident tracking methodologies. Different groups are being harmed, and industries across
        the board are affected. Businesses from all industries adopting AI must recognize that many risks from AI are
        likely relevant to them, which could be severe. See{" "}
        <a href="https://arxiv.org/pdf/2406.13843" target="_blank" rel="noreferrer">
          Google's research
        </a>{" "}
        on trends in misuse of gen AI for more information.
      </p>
      <h2 id={contents[7].anchor}>{contents[7].text}</h2>
      <h4 id={contents[8].anchor}>{contents[8].text}</h4>
      <p>
        While evidence for the negative impacts of AI has grown, the perceived priority level or relevance of AI risks,
        especially those from gen AI, varies considerably between different risks and surveys. Although the following
        surveys aren't directly comparable – their sample groups are very different, they're not asking about the same
        risks, and they were done at different times as gen AI adoption rates increased – we can still identify
        high-level patterns across the surveys about the risks businesses have recently been concerned about.
      </p>
      <p>
        KPMG's{" "}
        <a
          href="https://kpmg.com/kpmg-us/content/dam/kpmg/pdf/2023/kpmg-gen-ai-survey2023.pdf"
          target="_blank"
          rel="noreferrer"
        >
          two near-identical surveys
        </a>{" "}
        in March and June 2023 indicate that US businesses with over $1B in annual revenue came to prioritize risks from
        gen AI significantly more between early to mid-2023. In June's survey, most respondents found each risk to be a
        high priority (6 or 7 out of 7 for priority level). An average of 60% of respondents classified these risks as a
        high priority, with each risk being prioritized roughly equally, from privacy concerns with personal data as the
        most common highly prioritized risk (63%) to weaponization as the least common highly prioritized risk (56%).
      </p>
      <p>
        The proportion of respondents who highly prioritized each risk was substantially higher in June than in March's
        survey. For the six risks featured across both surveys, an average of 49% of respondents rated them as a high
        priority in June's survey. However, only 42% of the same respondents felt this way three months earlier. This
        shift represents a 40% increase in respondents rating these risks as a high priority in each survey. It's
        possible that the capabilities of OpenAI's GPT-4, released in March 2023, explain the sudden jump in the
        perceived priority of different AI risks.
      </p>
      <p>
        In collaboration with Accenture, the "AI Index Report 2024" from Stanford ran the "Global State of Responsible
        AI" survey from February to March 2024. Their survey covers risks from both gen AI and analytical AI. Over 1,000
        businesses participated, each with at least $500 million in annual revenue. Findings for responses to five of
        the total 14 risks surveyed are shared and divided by global region. In-depth survey data, such as responses to
        the remaining nine risks and how many organizations come from each region, has yet to be shared.
      </p>
      <p>
        Across these five risks – privacy and data governance, reliability, security, transparency, and fairness – the
        average number of organizations that think these risks, as a whole, are relevant is close for each global
        region, from 40% for North America to 45% for Europe, with Asia, Latin America, and the rest of the world being
        in between. Per risk, privacy and data governance had the highest perceived relevance globally at 51%. Most
        notably, fairness was rated as least relevant, with an average of 29%, whereas transparency had the second
        lowest average at 43%.
      </p>
      <p>
        Also conducted from February to March 2024 with over 1,000 global participants, McKinsey's "
        <a
          href="https://www.mckinsey.com/~/media/mckinsey/business%20functions/quantumblack/our%20insights/the%20state%20of%20ai/2024/the-state-of-ai-in-early-2024-final.pdf?shouldIndex=false"
          target="_blank"
          rel="noreferrer"
        >
          The State of AI in Early 2024
        </a>
        " found inaccuracy (63%), intellectual property management (52%), and cybersecurity (51%) to be the risks from
        gen AI most perceived as relevant. The range in perceived relevance for the risks McKinsey focuses on is also
        broad, from 63% for inaccuracy to 8% for physical safety (not including 1% for "none of the above").
      </p>
      <p>
        The perceived relevance of each risk in McKinsey's surveys has been highly stable since 2019. Stanford's "
        <a href="https://aiindex.stanford.edu/wp-content/uploads/2023/04/HAI_AI-Index-Report_2023.pdf">
          AI Index Report 2023
        </a>
        " offers a chart depicted in Figure 2.5 showing the proportion of McKinsey respondents who think each risk is
        relevant, not including inaccuracy and intellectual property management – the two risks considered relevant by
        most businesses – which were only included in the surveys from 2023 onwards.
      </p>
      <figure>
        <img src="" alt="" />
        <figcaption>
          Figure 2.5 – "Risks From Adopting AI that Organizations Consider Relevant, 2019-22" from Stanford's "
          <a
            href="https://aiindex.stanford.edu/wp-content/uploads/2023/04/HAI_AI-Index-Report_2023.pdf"
            target="_blank"
            rel="noreferrer"
          >
            AI Index Report 2023
          </a>
          "
        </figcaption>
      </figure>
      <p>
        Data-related issues are pervasive across the AI risks businesses are experiencing and prioritizing. Not only is
        "data" currently the{" "}
        <a href="https://incidentdatabase.ai/summaries/wordcounts/" target="_blank" rel="noreferrer">
          8th most common keyword
        </a>{" "}
        used throughout AIID reports, but it's also connected to each of the top negative impacts and risks
        organizations have named as experiencing and considering respectively from the surveys explored. Data privacy,
        data security, and data quality seem especially relevant to AI Governance. Mitigating AI risks is, therefore,
        intimately connected with effective Data Governance.
      </p>
      <p>
        Across surveys, only around half of businesses thought that AI risks were relevant to them or high priority.
        Furthermore, McKinsey's 2019-2024 State of AI surveys show that the perceived relevance of each risk among
        businesses has been highly stable over time.
      </p>
      <p>
        When considering the range of organizations that have experienced negative impacts from AI incidents, the
        relationship between AI risks and data practices, and the limited degree to which gen AI's powerful new
        capabilities seem to have updated business perceptions of risk relevance, we argue that many businesses do not
        understand how vulnerable they and the broader society are to various AI risks in 2024.
      </p>
      <p>
        <h2 id={contents[9].anchor}>{contents[9].text}</h2>
        Even among businesses that have reported being concerned about AI risks, only a minority seem to have taken
        wide-reaching actions to mitigate the risks. Evidence from McKinsey's annual State of AI surveys indicates that
        such a risk management perception-action gap, where the proportion of businesses that think each AI risk is
        relevant to them is significantly higher than that of businesses mitigating each risk, has existed since at
        least 2019.
      </p>
      <p>
        We later argue that challenges with practically implementing AI Governance frameworks could be the primary cause
        behind this gap and share concern that increased AI adoption in the short term could widen it.
      </p>
      <p>
        Alongside emphasizing the need for AI risk mitigation and highlighting the best practices of high-profile AI
        Governance frameworks, we aim to highlight this potential gap to emphasize the need for more businesses to take
        risk mitigation seriously and for more support to be available with implementation.
      </p>
      <h4 id={contents[10].anchor}>{contents[10].text}</h4>
      <p>
        The risk management perception-action gap seemingly exists globally and across differently-sized businesses.
        Looking at charts from Stanford's "
        <a
          href="https://aiindex.stanford.edu/wp-content/uploads/2023/04/HAI_AI-Index-Report_2023.pdf"
          target="_blank"
          rel="noreferrer"
        >
          AI Index Report 2023
        </a>
        " visualizing McKinsey's "
        <a
          href="https://www.mckinsey.com/~/media/mckinsey/business%20functions/quantumblack/our%20insights/the%20state%20of%20ai%20in%202022%20and%20a%20half%20decade%20in%20review/the-state-of-ai-in-2022-and-a-half-decade-in-review.pdf?shouldIndex=false"
          target="_blank"
          rel="noreferrer"
        >
          The State of AI 2022
        </a>
        " survey data, we find strong evidence for a global risk management perception-action gap over time. However,
        these graphs only featured data up until 2022, so we've created our own graphs combining data from "The State of
        AI 2022" with data from McKinsey's "
        <a
          href="https://www.mckinsey.com/~/media/mckinsey/business%20functions/quantumblack/our%20insights/the%20state%20of%20ai/2024/the-state-of-ai-in-early-2024-final.pdf?shouldIndex=false"
          target="_blank"
          rel="noreferrer"
        >
          The State of AI in Early 2024
        </a>
        " to include responses over 2023-2024 as well.
      </p>
      <p>
        Data from 2019-2022 relates to analytical AI, while data from 2023-2024 is exclusively for gen AI. We thus treat
        responses to McKinsey's surveys as being two separate though related trends and not one continuous trend.
      </p>
      <figure>
        <img src={riskRelevance} alt="line chart of risk relevance" />
        <figcaption>Figure 2.6 – "Rates of Perceived Risk Relevance"</figcaption>
      </figure>
      <figure>
        <img src={riskMitigation} alt="line chart of risk mitigation activities" />
        <figcaption>Figure 2.7 – "Rates of Risk Mitigation"</figcaption>
      </figure>
      <p>
        Looking at business perceptions of the relevance of AI risks in Figure 2.6, trends have held fairly constant
        across the whole period, across analytical AI and gen AI. However, as shown in Figure 2.7, not only are rates of
        risk mitigation notably lower across the whole period on average but there's also a large decline in rates of
        risk mitigation between analytical AI and gen AI. Between 2019-2022, rates of risk mitigation for analytical AI
        were fairly constant. Meanwhile, rates of risk mitigation for gen AI from 2023 are roughly half of what they
        were for analytical AI.
      </p>
      <p>
        These trends indicate a large perception-action gap. One of the starkest examples of this trend is for the two
        risks most widely considered relevant in 2024. Inaccuracy (63%) and intellectual property management (52%) were
        risks newly added in 2023's survey, indicating their high particular relevance to gen AI. Yet only 38% of
        organizations were actively mitigating inaccuracy, while 25% were mitigating intellectual property management
        risks.
      </p>
      <p>
        Stanford has noted the difference between business perceptions of AI risks and their actions to mitigate them.
        "These differences suggest a gap exists between the awareness organizations have of various risks and their
        steps taken to mitigate such risks."
      </p>
      <p>
        Using the data from Figure 2.6 and Figure 2.7, Figure 2.8 to visualizes this perception-action gap. The y-axis
        indicates how many times more businesses think risks are relevant than the number mitigating each risk. The
        higher the value on the y-axis, the larger the perception-action gap.
      </p>
      <figure>
        <img src={gap} alt="" />
        <figcaption>Figure 2.8 – "Risk Perception-Action Gap"</figcaption>
      </figure>
      <p>
        To reemphasize, McKinsey's surveys focused on analytical AI from 2019-2022 – before gen AI was widely adopted by
        businesses – and gen AI from 2023-2024. No data on the perception or mitigation of analytical AI from 2023
        onwards is included. We've highlighted the discontinuity of results between the surveys on each type of AI using
        a gap between results for 2022-2023 to avoid misrepresenting these results as continuous trends. This
        distinction is important because each type of AI has different capabilities and use cases that influence the
        risks they pose. Results also indicate that businesses currently approach analytical and gen AI very
        differently.
      </p>
      <p>
        The analytical AI risk management perception-action gap generally declined slightly on average from 2019-2020
        before remaining reasonably constant in width between 2020-2022. The risks with the smallest gap and lowest
        fluctuations are generally those that are more commonly prioritized, such as cybersecurity and regulatory
        compliance. The plateau in gap size between 2020-2022 may indicate that a similar gap size exists for analytical
        AI now.
      </p>
      <p>
        Meanwhile, the perception-action gap for gen AI for 2023-2024 has been substantially wider than that for
        analytical AI from 2019-2022 for every risk and has even widened between 2023 and 2024 for most risks. Possible
        explanations for these results include that businesses actually don't prioritize mitigating gen AI risks as
        highly as analytical AI risks or that businesses are especially struggling to mitigate gen AI risks.
      </p>
      <p>
        In summary, the risk management perception-action gap is substantial for many gen AI risks and likely remains so
        for many analytical AI risks, with no clear evidence of trends toward the gap closing further for either. When
        considering the increasing rates of AI adoption and AI incidents alongside limited changes to rates of AI risk
        prioritization and mitigation since 2019, the risk of adverse impacts from AI is rapidly increasing over time.
        Whether it's a lack of understanding of AI's risks or struggles to implement risk mitigations, businesses need
        support now more than ever to prepare for AI risks.
      </p>
      <h4 id={contents[11].anchor}>{contents[11].text}</h4>
      <p>
        While McKinsey's survey results offer a helpful time series for understanding the risk management
        perception-action gap, further evidence over 2023-2024 supports the general argument that the gap is large and
        challenging to close for many businesses.
      </p>
      <p>
        In KPMG's{" "}
        <a
          href="https://kpmg.com/kpmg-us/content/dam/kpmg/pdf/2023/kpmg-gen-ai-survey2023.pdf"
          target="_blank"
          rel="noreferrer"
        >
          June 2023 survey
        </a>{" "}
        of large US businesses, 77% of respondents were extremely confident of their ability to mitigate the nine risks
        from gen AI that were listed (6 or 7 out of 7 for their confidence level). Self-confidence seems slightly
        positively related to risk prioritization. The average number of more confident respondents who highly
        prioritize risks was 62% compared with 52% among less confident organizations. Only 5% of respondents expressed
        having moderate or less confidence in their risk mitigation abilities.
      </p>
      <p>
        The priority level of many AI risks rose substantially over three months. An average of 60% of respondents
        identified each risk as a high priority in June, up 40% from March's survey. Furthermore, although not measured
        in June's survey,{" "}
        <a
          href="https://kpmg.com/kpmg-us/content/dam/kpmg/pdf/2023/generative-ai-survey.pdf"
          target="_blank"
          rel="noreferrer"
        >
          March's survey
        </a>{" "}
        found that 69% of respondents hadn't finished evaluating AI risk and risk mitigation strategies, while 6% had a
        dedicated team for risk evaluation and implementation.
      </p>
      <p>
        It seems implausible that most businesses could operationalize thorough risk mitigation procedures within three
        months and thus have strong reason to be highly confident of their risk mitigation capabilities.{" "}
        <a
          href="https://www.bcg.com/publications/2023/ceo-agenda-must-include-responsible-use-of-ai"
          target="_blank"
          rel="noreferrer"
        >
          Research
        </a>{" "}
        from BCG indicates that establishing responsible AI – a big part of which is risk mitigation – takes
        organizations two to three years on average. At least by June 2023, large US businesses seemed generally
        overconfident about their risk mitigation capabilities.
      </p>
      <p>
        Two separate surveys from IBM also indicate a significant perception-action gap. In a 2022-2023 survey used for
        their "
        <a href="https://www.ibm.com/downloads/cas/6JAM3VB7" target="_blank" rel="noreferrer">
          CEO's guide to gen AI
        </a>
        ," IBM found that while 79% of executives say AI ethics is important, fewer than 25% of organizations had
        operationalized AI ethics. Their "
        <a
          href="https://newsroom.ibm.com/2024-01-10-Data-Suggests-Growth-in-Enterprise-Adoption-of-AI-is-Due-to-Widespread-Deployment-by-Early-Adopters?sf185480910=1"
          target="_blank"
          rel="noreferrer"
        >
          Global AI Adoption Index 2023
        </a>
        " found that 83% of respondents think being able to explain how their AI reached a decision is important to
        them. However, under half of respondents are taking actions toward mitigating related AI risks, such as reducing
        bias (27%) or developing ethical AI policies (44%).
      </p>
      <p>
        The "Global State of Responsible AI" survey, discussed in Stanford's "
        <a
          href="https://aiindex.stanford.edu/wp-content/uploads/2024/05/HAI_AI-Index-Report-2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          AI Index Report 2024
        </a>
        ," offers a more ambiguous view of the relationship between AI risk perceptions and actions. It particularly
        gives insight into a business's degree of AI Governance operationalization.
      </p>
      <p>
        Between 82% (Latin America) and 96% (North America) of all businesses across global regions self-reported having
        operationalized 1-50% of the Responsible AI measures the survey highlighted. Meanwhile, 17% (North America) to
        26% (Asia, Latin America, and the rest of the world) have operationalized at least 51% of these measures.
      </p>
      <p>
        Given that across all risks, no region had more than 56% of respondents think a risk was relevant to them, it
        seems plausible that businesses that don't think risks are relevant to them are nonetheless implementing risk
        mitigation policies amid regulatory uncertainty. However, there remains a substantial gap between the number of
        businesses that think risks are relevant and those that have implemented most risk mitigation practices.
      </p>
      <p>
        Additional data shared from the survey, such as the rates at which different measures are being introduced and
        how these actions correspond to business perceptions of each risk, would be necessary for understanding what
        this survey indicates about the existence of a risk management perception-action gap.
      </p>
      <h4 id={contents[12].anchor}>{contents[12].text}</h4>
      <p>
        Taking a different approach, one group of AI ethics researchers{" "}
        <a
          href="https://www.techbetter.ai/_files/ugd/f83391_539908448a6e428da80177b14717074c.pdf"
          target="_blank"
          rel="noreferrer"
        >
          evaluated
        </a>{" "}
        the relative frequencies of "signal activities," such as AI principles, and "implementation activities," such as
        measures to mitigate bias as outlined in a business's public disclosure on AI Governance. The AI Governance
        public disclosures of 254 businesses in 2022 were investigated using NIST's Risk Management Framework to
        categorize between signal and implementation activities.
      </p>
      <p>
        We've interpreted the two groups being evaluated as roughly reflecting businesses' perceptions of and actions to
        mitigate AI risks as measured in the preceding surveys. Signal activities are a proxy measure for the number of
        businesses considering AI risks to be at least relevant, if not high-priority. For example, public disclosures
        include principles declaring the importance of minimizing AI bias as a risk. While they're not explicit evidence
        of practices being implemented, references to implementation activities seem as reliable as, if not more so, an
        indicator of implementation as business self-reporting on surveys.
      </p>
      <p>
        In addition to these methodological differences, other limitations to the evaluation also reduce the weight of
        its findings in our estimation of a risk management perception-action gap's existence. These include the fact
        that the evaluation's data is from 2022 – before gen AI significantly increased AI adoption – and its analyses
        lack important details, e.g., numerical values for activity correlations. We've tried to factor these
        limitations into our inferences from its findings.
      </p>
      <p>
        Among its findings is the gap between the number of businesses with at least one signal activity – Principles
        being most common at 49% of businesses – compared with implementation activities – Design and pre-review being
        most common at 20% – is especially relevant. The proportion of signal activities to implementation activities is
        roughly similar to that found between perceptions and actions in McKinsey's 2024{" "}
        <a
          href="https://www.mckinsey.com/~/media/mckinsey/business%20functions/quantumblack/our%20insights/the%20state%20of%20ai/2024/the-state-of-ai-in-early-2024-final.pdf?shouldIndex=false"
          target="_blank"
          rel="noreferrer"
        >
          survey
        </a>
        .
      </p>
      <p>
        Of those businesses showing at least one governance signal (76%), 26.4% exhibited no implementation activities,
        while 74.4% had 2 or fewer implementation activities. The more governance signals a business showed, the more
        likely it was to include implementation activities. The number of implementation activities also roughly doubled
        for each additional signal activity, from 0.6 for 1 signal activity to 6.7 for 5.
      </p>
      <p>
        Comparing the Q1 and Q4 results for each business, the researchers found that while over 70% maintained the same
        number of signal and implementation activities, more businesses saw declines versus increases in governance
        activity numbers. This pattern was especially evident for implementation activities, where 17.7% of companies
        saw declines while 9.1% saw increases.
      </p>
      <p>
        These results support the existence of a perception-action gap. The number of businesses whose public AI
        Governance disclosures include at least one signal activity relative to at least one implementation activity is
        large. Similarly, businesses are far more willing to remove implementation activities from their public AI
        Governance disclosures than signal activities. Nonetheless, more signal activities strongly predict more
        implementation activities, suggesting that the total number of signal activities a business publicizes might be
        a rough indicator of how seriously businesses prioritize AI risks in practice.
      </p>
      <p>
        As previously mentioned, various barriers to implementing effective AI Governance frameworks, including risk
        mitigation policies, have created this gap.
      </p>
      <h2 id={contents[13].anchor}>{contents[13].text}</h2>
      <p>
        Recent growth in AI adoption among businesses has come with many challenges. Barriers during the AI deployment
        and operation phases of adoption seem especially prevalent.
      </p>
      <p>
        Among these barriers, AI risks stand out for how often they appear across surveys and how commonly they're
        reported as barriers within surveys. A growing track record of AI incidents and negative impacts on businesses
        indicates their high impact potential on all businesses adopting AI. Despite this data, surveys also indicate
        that, at most, only half of organizations think various AI risks are relevant to them, and considerably fewer
        are taking action to mitigate them.
      </p>
      <p>
        Implementing organizational AI Governance is crucial for overcoming and avoiding barriers to AI adoption. AI
        Governance frameworks determine policies that will help businesses maximize the value they capture from AI and
        policies necessary to mitigate AI risks.
      </p>
    </div>
  );
};

import { Link } from "react-router-dom";

import Post from "../../components/blog/Post";
import PostImage from "../../components/blog/PostImage";
import AIGFCDownloads from "./AIGFCDownloads";

import aiGovernance from "../../img/blog/aigfc/ai-governance.webp";
import aiFrameworkTypes from "../../img/blog/aigfc/ai-framework-types.webp";

const POSTNAME: postNickname = "aigfc-importance";

const AIGFCGovernance: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default AIGFCGovernance;

const Rest = () => {
  const contents = [
    { anchor: "what-ai-governance-involves", text: "What AI Governance Involves" },
    { anchor: "broad-scope", text: "Broad Scope" },
    { anchor: "a-priority-for-large-businesses", text: "A Priority for Large Businesses" },
    { anchor: "types-of-ai-governance", text: "Types of AI Governance" },
    { anchor: "roles-and-responsibilities", text: "Roles and Responsibilities" },
    { anchor: "tasks-in-practice", text: "Tasks in Practice" },
    { anchor: "aiga-model-case-study", text: "AIGA Model Case Study" },
    { anchor: "ceos", text: "CEOs" },
    { anchor: "data-management-and-data-governance", text: "Data Management and Data Governance" },
    { anchor: "data-quality-and-data-security", text: "Data Quality and Data Security" },
    { anchor: "oversight-groups", text: "Oversight Groups" },
    { anchor: "business-benefits", text: "Business Benefits" },
    {
      anchor: "business-incentives",
      text: "Business Incentives and Responsibility Are Aligned",
    },
    { anchor: "increased-roi", text: "Increased ROI" },
    { anchor: "building-trust", text: "Building Trust" },
    { anchor: "regulatory-compliance", text: "Regulatory Compliance" },
    { anchor: "adoption-rates-and-barriers", text: "Adoption Rates and Barriers" },
    { anchor: "rates-of-business-adoption", text: "Rates of Business Adoption" },
    { anchor: "deployment-and-operation-barriers", text: "Deployment and Operation Barriers" },
    { anchor: "planning-and-resource-barriers", text: "Planning and Resource Barriers" },
    { anchor: "discussion", text: "Discussion" },
  ];

  return (
    <div className="blog">
      <PostImage postName={POSTNAME} />
      <h4>Contents</h4>
      <ul>
        <li>
          <a href={"#" + contents[0].anchor}>{contents[0].text}</a>
          {contents.slice(1, 4).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
        <li>
          <a href={"#" + contents[4].anchor}>{contents[4].text}</a>
          {contents.slice(5, 11).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
        <li>
          <a href={"#" + contents[11].anchor}>{contents[11].text}</a>
          {contents.slice(12, 16).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
        <li>
          <a href={"#" + contents[16].anchor}>{contents[16].text}</a>
          {contents.slice(17, 21).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
      </ul>
      <h2 id={contents[0].anchor}>{contents[0].text}</h2>
      <h4 id={contents[1].anchor}>{contents[1].text}</h4>
      <p>
        Drawing from the definitions of various organizations (
        <a href="https://www.ibm.com/topics/ai-governance" target="_blank" rel="noreferrer">
          1
        </a>
        ,{" "}
        <a href="https://www.responsible.ai/navigating-organizational-ai-governance/" target="_blank" rel="noreferrer">
          2
        </a>
        ,{" "}
        <a href="https://iapp.org/news/a/ai-governance-what-is-being-governed" target="_blank" rel="noreferrer">
          3
        </a>
        ,{" "}
        <a href="https://www.ibm.com/downloads/cas/KXVRM5QE" target="_blank" rel="noreferrer">
          4
        </a>
        ,{" "}
        <a
          href="https://futurium.ec.europa.eu/en/european-ai-alliance/best-practices/implementing-ai-governance-framework-practice"
          target="_blank"
          rel="noreferrer"
        >
          5
        </a>
        ), AI Governance refers to the set of policies and mechanisms that ensure AI systems remain ethical and safe
        throughout the AI lifecycle.
      </p>
      <p>
        Responsible AI (RAI) is one of the{" "}
        <a href="https://iapp.org/resources/article/key-terms-for-ai-governance/" target="_blank" rel="noreferrer">
          many terms
        </a>{" "}
        related to AI Governance that many businesses have likely encountered. Although often used synonymously with AI
        Governance, RAI typically describes AI principles focused on risk mitigation, i.e., using AI responsibly. In
        contrast, AI Governance is currently used as a broader term that encompasses AI principles and the practical
        implementation of such principles. Where responsible AI is discussed by sources in a way that closely resembles
        AI Governance, such as implementing a responsible AI program to mitigate risks, we've treated the advice and
        evidence related to it as essentially synonymous with that for AI Governance.
      </p>
      <p>
        Gartner{" "}
        <a href="https://www.gartner.com/doc/reprints?id=1-2F2UJPZR&ct=230922&st=sb" target="_blank" rel="noreferrer">
          highlights
        </a>{" "}
        that the AI Governance market is "currently characterized by an absence of agreed-upon standards or
        regulations." The{" "}
        <a href="https://www.responsible.ai/" target="_blank" rel="noreferrer">
          Responsible AI Institute
        </a>{" "}
        meanwhile,{" "}
        <a href="https://www.responsible.ai/navigating-organizational-ai-governance/" target="_blank" rel="noreferrer">
          describes
        </a>{" "}
        AI Governance as "an emergent but rapidly evolving field." The field's rapid development comes in response to
        significant technical AI developments and the proliferation of AI applications over the last 10 years,
        especially in the last two years with gen AI.
      </p>
      <p>
        Many different stakeholders contribute to the field's development. Prominent types of AI Governance include
        government regulation, such as the{" "}
        <a
          href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex:52021PC0206"
          target="_blank"
          rel="noreferrer"
        >
          EU AI Act
        </a>
        ; AI lab policies, such as Anthropic's{" "}
        <a
          href="https://assets.anthropic.com/m/24a47b00f10301cd/original/Anthropic-Responsible-Scaling-Policy-2024-10-15.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Responsible Scaling Policy
        </a>
        ; and standard-setting organizations, such as NIST's AI Risk Management Framework.
      </p>
      <p>
        These each address different governance needs. For example, government regulation aims to improve the
        reliability of an organization's legal compliance with best practices, while international standard-setting
        organizations try to identify and propagate best practices globally.
      </p>
      <h4 id={contents[2].anchor}>{contents[2].text}</h4>
      <p>
        Many businesses will understandably hesitate to implement AI Governance. Keeping track of best practices can be
        challenging; it impacts on various parts of a business; and implementing and operating requires new expertise.
        It could be tempting to start with what IBM{" "}
        <a href="https://www.ibm.com/topics/ai-governance" target="_blank" rel="noreferrer">
          calls
        </a>{" "}
        informal governance – using existing governance systems – with a plan to implement ad hoc systems as specific
        risks arise.
      </p>
      <p>
        However, in addition to encouragement{" "}
        <a
          href="https://futurium.ec.europa.eu/en/european-ai-alliance/best-practices/implementing-ai-governance-framework-practice"
          target="_blank"
          rel="noreferrer"
        >
          from governments
        </a>
        , implementing comprehensive, purpose-built organizational AI Governance frameworks as part of the AI adoption
        process has wide support among large businesses.{" "}
        <a
          href="https://www.spglobal.com/en/research-insights/special-reports/the-ai-governance-challenge"
          target="_blank"
          rel="noreferrer"
        >
          S&P Global
        </a>{" "}
        argues for "robust, flexible, and adaptable governance frameworks." At the same time,{" "}
        <a href="https://www.bcg.com/publications/2023/a-guide-to-mitigating-ai-risks" target="_blank" rel="noreferrer">
          BCG
        </a>{" "}
        claims that robust AI Governance is how "organizations [can] ensure that they can deliver the benefits of AI
        while mitigating the risks."
      </p>
      <p>
        Each of the four large tech companies whose AI Governance frameworks this report reviews similarly emphasizes
        the importance of AI Governance:
      </p>
      <ul>
        <li>
          <a
            href="https://cdn-dynmedia-1.microsoft.com/is/content/microsoftcorp/microsoft/final/en-us/microsoft-brand/documents/Microsoft-Responsible-AI-Standard-General-Requirements.pdf?culture=en-us&country=us"
            target="_blank"
            rel="noreferrer"
          >
            Microsoft
          </a>
          :&nbsp; recognizing that AI Governance lags behind technological risks, Microsoft developed its Responsible AI
          Standard to guide product teams. They advocate for "principled and actionable AI Governance norms" through
          cross-sector collaboration.
        </li>

        <li>
          <a
            href="https://docs.aws.amazon.com/pdfs/whitepapers/latest/aws-caf-for-ai/aws-caf-for-ai.pdf#governance-perspective-managing-an-aiml-driven-organization"
            target="_blank"
            rel="noreferrer"
          >
            AWS
          </a>
          :&nbsp; "AI Governance... is instrumental in building trust, enabling the deployment of AI technologies at
          scale, and overcoming challenges to drive business transformation and growth." AWS emphasizes that AI
          Governance frameworks create consistent practices for managing risks, ethics, data quality, and regulatory
          compliance.
        </li>

        <li>
          <a href="https://safety.google/intl/en_us/cybersecurity-advancements/saif/" target="_blank" rel="noreferrer">
            Google
          </a>
          :&nbsp; Google has established AI Governance policies covering responsible principles, safety, and security.
          Their Secure AI Framework guides organizations in implementing "security standards for building and deploying
          AI responsibly."
        </li>

        <li>
          <a href="https://www.ibm.com/topics/ai-governance" target="_blank" rel="noreferrer">
            IBM
          </a>
          :&nbsp; AI Governance is crucial for compliance, trust, risk mitigation, and efficiency in AI development and
          application. It provides frameworks and guidelines to balance innovation with safety, ensuring AI systems
          respect human rights and dignity.
        </li>
      </ul>
      <p>
        We strongly encourage all businesses who have, are currently employing, or are considering adopting AI to learn
        more about AI Governance and how your business can effectively implement it.
      </p>
      <h2 id={contents[3].anchor}>{contents[3].text}</h2>
      <p>
        Multiple classification systems currently exist to categorize different types of AI Governance. We've found the
        taxonomies proposed in the separate collaborations of{" "}
        <a href="https://www.responsible.ai/navigating-organizational-ai-governance/" target="_blank" rel="noreferrer">
          BCG and RAII
        </a>{" "}
        (Figure 3.1) and{" "}
        <a
          href="https://iapp.org/media/pdf/resource_center/ai_governance_in_practice_report_2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          IAPP and FTI Consulting
        </a>{" "}
        (Figure 3.2) to be the most useful descriptions of the current AI Governance field, which roughly converge on
        five primary types of AI Governance, complete with specific examples.
      </p>
      <figure>
        <img
          src={aiGovernance}
          alt="chart showing various types of AI Governance, including AI principles, AI frameworks, laws & policies, voluntary guidelines, and standards and certifications"
        />
        <figcaption>
          Figure 3.1 – From BCG and RAII's{" "}
          <a
            href="https://www.responsible.ai/navigating-organizational-ai-governance/"
            target="_blank"
            rel="noreferrer"
          >
            Navigating Organizational AI Governance
          </a>
        </figcaption>
      </figure>

      <figure>
        <img src={aiFrameworkTypes} alt="types of ai frameworks" />
        <figcaption>
          Figure 3.2 – From IAPP and FTI Consulting's{" "}
          <a
            href="https://iapp.org/media/pdf/resource_center/ai_governance_in_practice_report_2024.pdf"
            target="_blank"
            rel="noreferrer"
          >
            AI Governance in Practice Report 2024
          </a>
        </figcaption>
      </figure>

      <p>
        AI principles, laws and regulations, and frameworks are likely the types of AI Governance most relevant to
        businesses. AI principles are necessary for businesses to define their ethics and safety-focused objectives for
        AI. See what some large businesses{" "}
        <a
          href="https://www.industry.gov.au/publications/australias-artificial-intelligence-ethics-framework/testing-ai-ethics-principles"
          target="_blank"
          rel="noreferrer"
        >
          experienced in a pilot test
        </a>{" "}
        of Australia's "AI Ethics Principles."
      </p>
      <p>
        Understanding AI laws and regulations is necessary for businesses to maintain legal compliance. Convergence
        Analysis's "
        <a
          href="https://drive.google.com/file/d/13gyYbBixU75QwFQDTku0AMIovbeTp9_g/view"
          target="_blank"
          rel="noreferrer"
        >
          2024 State of the AI Regulatory Landscape
        </a>
        " offers a valuable, up-to-date overview.
      </p>
      <p>
        This report, however, is focused on supporting businesses in improving their organizational AI Governance by
        learning from or integrating existing AI Governance frameworks. Like other key terms for AI Governance, "AI
        Governance framework" is commonly used in different ways, including to describe guidance that could be better
        categorized under AI principles or AI laws and regulations.
      </p>
      <p>
        We've used the list of AI framework examples outlined for AI governance frameworks in Figure 3.2 as a starting
        place for defining and identifying frameworks. We define AI Governance frameworks as documents outlining formal
        policies and procedures for practically implementing AI principles and regulatory compliance. See the framework
        investigations "Overview" section for more information about our approach.
      </p>
      <h2 id={contents[4].anchor}>{contents[4].text}</h2>
      <h4 id={contents[5].anchor}>{contents[5].text}</h4>

      <p>
        An AI Governance framework requires that a business implements objective-aligned policies and procedures for
        governing AI across the AI lifecycle. Policies and procedures can ideally be developed explicitly for AI
        Governance, but Singapore AI{" "}
        <a
          href="https://www.pdpc.gov.sg/-/media/Files/PDPC/PDF-Files/Resource-for-Organisation/AI/SGModelAIGovFramework2.pdf"
          target="_blank"
          rel="noreferrer"
        >
          proposes
        </a>{" "}
        that adapting existing governance structures to accommodate AI can also work. For example, AI risk mitigation
        processes can be developed within a business's existing risk management structure.
      </p>
      <p>
        While an AI's specific features and intended use cases will influence what governance mechanisms a business
        employs to govern it, there are many broad mechanisms that businesses are likely to practice along the AI
        lifecycle. Singapore AI outlines risk control, maintenance, monitoring, documentation, review measures, and
        reviews of how stakeholders communicate as common types of operational tasks.
      </p>
      <p>
        Although before the proliferation of gen AI,{" "}
        <a href="https://www.ibm.com/downloads/cas/KXVRM5QE" target="_blank" rel="noreferrer">
          survey results
        </a>{" "}
        from IDC in 2022 offer more granular insight into what AI Governance capabilities businesses think are critical.
        Dashboards to assess, monitor, and drive timely actions/risk management (61.7%), multi-persona collaboration
        (60%), and support governance for third-party models and applications (60%) were the three most commonly cited
        critical capabilities. However, of the nine capabilities surveyed, reports for compliance (43.1%) were the
        lowest, indicating that many businesses consider a wide range of capabilities critical.
      </p>
      <p>
        2023{" "}
        <a
          href="https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report.pdf?referrer=content_seehereview"
          target="_blank"
          rel="noreferrer"
        >
          research
        </a>{" "}
        from IAPP-EY highlights which business functions are currently being or expected to be tasked with AI Governance
        responsibilities. Among those functions with primary responsibility, information technology (19%), privacy
        (16%), legal and compliance (14%), and data governance (11%) were the most commonly involved. Meanwhile, the
        proportion of business functions with secondary responsibility for AI Governance is far higher. Privacy (57%),
        legal and compliance (55%), security (50%), and information technology (46%) were the functions that at least
        35% of respondents indicated involvement with AI Governance.
      </p>
      <p>
        Taking a bigger picture view, the OECD's "
        <a
          href="https://www.oecd-ilibrary.org/docserver/008232ec-en.pdf?expires=1730238317&id=id&accname=guest&checksum=BEB1A6DA71952C260493D2FE17BB1F81"
          target="_blank"
          rel="noreferrer"
        >
          Tools for Trustworthy AI
        </a>
        " report provides a taxonomy of the types of tools involved in implementing AI principles. Here, tools are
        defined as "instruments and structured methods that can be leveraged by others to facilitate their
        implementation of the AI Principles." These give a broader context for what deployment requires in addition to
        the operational system.
      </p>
      <h2 id={contents[6].anchor}>{contents[6].text}</h2>
      <p>
        In addition to understanding the content of AI Governance frameworks as documents, it's important to understand
        how this framework operates as a comprehensive system. Shown in Figure 3.3, the AI Governance and Auditing
        (AIGA) consortium, coordinated by the University of Turku, provides a{" "}
        <a
          href="https://ai-governance.eu/ai-governance-framework/the-ai-governance-lifecycle/"
          target="_blank"
          rel="noreferrer"
        >
          model
        </a>{" "}
        for how an AI Governance system that{" "}
        <a
          href="https://futurium.ec.europa.eu/en/european-ai-alliance/best-practices/implementing-ai-governance-framework-practice"
          target="_blank"
          rel="noreferrer"
        >
          complies
        </a>{" "}
        with the EU AI Act could operate. Although this model primarily caters to AI developers, it could help
        businesses understand what their AI Governance could involve during deployment and operation.
      </p>
      <p>
        Figure 3.3 – From the University of Turku's{" "}
        <a
          href="https://ai-governance.eu/ai-governance-framework/the-ai-governance-lifecycle/"
          target="_blank"
          rel="noreferrer"
        >
          The AI Governance Lifecycle
        </a>
      </p>
      <p>
        The AIGA model outlines individual governance tasks at the intersection of different task categories, e.g., AI
        system, and phases along the AI lifecycle, e.g., planning and design. We are particularly interested in the
        tasks during the deployment and operation phases, especially those related to data operations and risk and
        impacts. Although this model doesn't outline any tasks for the deployment phase, many tasks occur during the
        operation and monitoring phase.
      </p>
      <p>
        For data operations, data quality monitoring (task 38) and data health checks (task 39) occur during operation
        and monitoring. Data quality monitoring involves the AI system owner ensuring the implementation of planned data
        quality processes, such as disclosing standard breaches and data drift, before implementing measures to address
        problems. Meanwhile, the algorithm owner performs data health checks, assessing how the AI system data resources
        and data-related processes align with the organization's values and risk tolerance and taking appropriate
        measures to address any issues identified.
      </p>
      <p>
        The diversity of tasks that AI Governance frameworks involve means that whole-organization coordination between
        roles is necessary for the framework to function reliably. We focus on evidence for the responsibilities and
        impact of key roles. These roles include CEOs who set targets and communicate cultural norms, data practitioners
        from policy-setting to practical implementation, and the intersecting groups that can provide practical
        oversight for AI programs.
      </p>
      <h2 id={contents[7].anchor}>{contents[7].text}</h2>
      <p>
        Reliable AI adoption requires that AI Governance be aligned with a business's objective and implemented
        throughout different business functions along the AI lifecycle. BCG and RAII{" "}
        <a href="https://www.responsible.ai/navigating-organizational-ai-governance/" target="_blank" rel="noreferrer">
          determine
        </a>{" "}
        "that responsibility for AI Governance should ultimately sit with CEOs since AI Governance includes 'issues
        related to customers' trust in the company's use of the technology, experimentation with AI within the
        organization, stakeholder interest, and regulatory risks.'"{" "}
        <a href="https://nvlpubs.nist.gov/nistpubs/ai/NIST.AI.100-1.pdf" target="_blank" rel="noreferrer">
          NIST
        </a>
        ,{" "}
        <a href="https://www.ibm.com/topics/ai-governance" target="_blank" rel="noreferrer">
          IBM
        </a>
        ,{" "}
        <a href="https://www.ibm.com/downloads/cas/KXVRM5QE" target="_blank" rel="noreferrer">
          IDC
        </a>
        , and{" "}
        <a
          href="https://tdwi.org/Research/2024/07/ADV-ALL-2024-State-of-AI-Readiness/Asset.aspx?tc=assetpg"
          target="_blank"
          rel="noreferrer"
        >
          TDWI
        </a>{" "}
        echo this basic sentiment.
      </p>
      <p>
        Executives, more than other roles, look to the CEO for guidance. In their{" "}
        <a href="https://www.ibm.com/downloads/cas/6JAM3VB7" target="_blank" rel="noreferrer">
          guide
        </a>{" "}
        to gen AI for CEOs, survey results from IBM show that roughly 3 times as many executives look to CEOs for
        guidance on AI ethics than various other roles, from Board of Directors to Risk and Compliance Officers.
        Furthermore, 80% think business leaders should be primarily accountable for AI ethics, not technology leaders.
        IDC{" "}
        <a href="https://www.ibm.com/downloads/cas/KXVRM5QE" target="_blank" rel="noreferrer">
          notes
        </a>{" "}
        that regulators, too, are looking to C-level executives to ensure regulatory compliance.
      </p>
      <p>
        CEOs are deeply involved in implementing AI Governance. Referring to the OECD's "
        <a
          href="https://www.oecd-ilibrary.org/docserver/008232ec-en.pdf?expires=1730238317&id=id&accname=guest&checksum=BEB1A6DA71952C260493D2FE17BB1F81"
          target="_blank"
          rel="noreferrer"
        >
          Tools for Trustworthy AI
        </a>
        ," we find a range of procedural and educational tasks involving the CEO. These include setting the tone for the
        risk management culture, developing open communication channels, dictating lines of authority, rolling out
        training, and investing resources.
      </p>
      <p>
        Furthermore, this support might need to be sustained for years. BCG{" "}
        <a href="https://www.bcg.com/publications/2023/a-guide-to-mitigating-ai-risks" target="_blank" rel="noreferrer">
          estimates
        </a>{" "}
        that businesses take an average of three years to reach high levels of responsible AI maturity. The EU strongly{" "}
        <a
          href="https://futurium.ec.europa.eu/en/european-ai-alliance/best-practices/implementing-ai-governance-framework-practice"
          target="_blank"
          rel="noreferrer"
        >
          recommends
        </a>{" "}
        appointing an AI Governance lead to oversee a framework's implementation and maintenance to help maintain
        progress over time.
      </p>
      <p>
        The impact that more hands-on CEOs have on AI Governance implementation seems substantial. A BCG and MIT Sloan
        Management Review{" "}
        <a
          href="https://www.bcg.com/publications/2023/ai-responsibility-at-crossroads"
          target="_blank"
          rel="noreferrer"
        >
          2023 survey
        </a>{" "}
        found that of the 1,240 respondents, only 28% of CEOs were involved in responsible AI programs. Those businesses
        with an involved CEO saw 58% more business benefits on average, such as accelerated innovation and long-term
        profitability from their program, than those without a hands-on CEO.
      </p>
      <h4 id={contents[8].anchor}>{contents[8].text}</h4>
      <p>
        IAPP and FTI{" "}
        <a
          href="https://iapp.org/media/pdf/resource_center/ai_governance_in_practice_report_2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          call
        </a>{" "}
        Data Management "a foundational element to managing all AI systems." At the same time, AWS{" "}
        <a
          href="https://docs.aws.amazon.com/pdfs/whitepapers/latest/aws-caf-for-ai/aws-caf-for-ai.pdf#governance-perspective-managing-an-aiml-driven-organization"
          target="_blank"
          rel="noreferrer"
        >
          asserts
        </a>{" "}
        that "the value of [AI] systems is driven largely by the data that makes it more effective." Indeed, Data
        Management issues are among the most common barriers to AI adoption, such as needing a Data Strategy to handle
        high volumes of data. Although this report focuses on AI Governance during the deployment and operation
        lifecycle phases, data is critical during an AI's training, making up one part of the "
        <a
          href="https://cset.georgetown.edu/publication/the-ai-triad-and-what-it-means-for-national-security-strategy/"
          target="_blank"
          rel="noreferrer"
        >
          AI triad
        </a>
        ."
      </p>
      <p>
        IAPP and FTI propose that Data Management considerations "include understanding what data is being used in which
        system; how it is collected, retained, and disposed of; if there is lawful consent to use the data; and who is
        responsible for ensuring the appropriate oversight." Classifying the Data Management practices using DAMA's{" "}
        <em>
          <a href="http://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            DMBOK
          </a>
        </em>{" "}
        depicted in Figure 3.4, our research indicates that the strongest relationships between AI Governance and Data
        Management are in data governance, data quality, and data security (especially Data Privacy).
      </p>
      <p>
        Figure 3.4 – From DAMA's{" "}
        <em>
          <a
            href="https://www.amazon.com/gp/product/1634622340/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=1634622340&linkId=5e47fe53a18a705df29f9b91763a22b8"
            target="_blank"
            rel="noreferrer"
          >
            DMBOK
          </a>
        </em>
      </p>
      <p>
        The lifecycles of AI and data are deeply intertwined, and thus, so is the governance of each. Stanford's "
        <a
          href="https://aiindex.stanford.edu/wp-content/uploads/2024/05/HAI_AI-Index-Report-2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          AI Index Report 2024
        </a>
        " emphasizes that "data governance is crucial for ensuring that the data used for training and operating AI
        systems is accurate, fair, and used responsibly and with consent."
      </p>
      <p>
        Data Governance expert{" "}
        <a
          href="https://www.linkedin.com/pulse/ai-governance-vs-data-robert-s-seiner-duiqc/"
          target="_blank"
          rel="noreferrer"
        >
          Bob Seiner
        </a>{" "}
        refers to the synthesis of Data Governance and Data Governance as AI Data Governance, where Data Governance is a
        subset of AI Governance. Key aspects include managing data access and securing data against breaches. These are
        enacted by policies and procedures for data annotation, collection, and utilization to responsibly meet the
        operational needs of AI systems. Without robust AI Data Governance, Seiner highlights that AI risks operating
        <strong> </strong>with "flawed, biased, or insecure data, leading to poor and potentially harmful decisions."
      </p>
      <p>
        TDWI's "
        <a
          href="https://tdwi.org/Research/2024/07/ADV-ALL-2024-State-of-AI-Readiness/Asset.aspx?tc=assetpg"
          target="_blank"
          rel="noreferrer"
        >
          The State of AI Readiness 2024
        </a>
        " survey results offer insight into the demand for data practitioner roles in connection with AI Governance and
        some of the challenges businesses are experiencing related to AI Data Governance. Many organizations seem
        especially interested in hiring data engineers and upskilling business analysts to work increasingly as data
        scientists who also understand business.
      </p>
      <p>
        Lacking strong data practices has already made adopting AI challenging. TDWI found that only 20% of respondents
        self-report having a solid Data Governance program. In comparison, only 40% understand their data sources and
        have policies to govern different kinds of data. These results are consistent with McKinsey's{" "}
        <a
          href="https://www.mckinsey.com/capabilities/quantumblack/our-insights/the-state-of-ai#/download/%2F~%2Fmedia%2Fmckinsey%2Fbusiness%20functions%2Fquantumblack%2Four%20insights%2Fthe%20state%20of%20ai%2F2024%2Fthe-state-of-ai-in-early-2024-final.pdf%3FshouldIndex%3Dfalse"
          target="_blank"
          rel="noreferrer"
        >
          finding
        </a>{" "}
        that 70% of respondents struggled to define processes for Data Governance and integrating data into AI models.
      </p>
      <p>
        Meanwhile, less than 30% agreed they have "a company-wide Data Architecture in place for AI that can handle user
        growth." Businesses also need to learn how to store and govern new, often unstructured data types that AI often
        involves, such as PDFs and audio files.
      </p>
      <h4 id={contents[9].anchor}>{contents[9].text}</h4>
      <p>
        IAPP-FTI's "
        <a
          href="https://iapp.org/media/pdf/resource_center/ai_governance_in_practice_report_2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          AI Governance in Practice Report 2024
        </a>
        " details AI Governance's connection to data quality and Data Security. Even during the deployment and operation
        phases of the AI lifecycle, data quality "impacts the quality of the outputs and performance," making it
        essential to creating a safe and responsible AI system. Policies and procedures should systematically measure
        data's accuracy, completeness, validity, and consistency across the AI lifecycle. These processes mitigate risks
        such as transparency and support AI in performing the specific business functions they're tasked with.
      </p>
      <p>
        Data labels are one tool that businesses can use in data quality assessment and review processes. Documenting
        and creating inventories for data sources can support understanding where data is acquired and help carry out
        legal due diligence. If, for example, a business uses third-party data, it must comply with the terms of service
        in any data-sharing agreement and attribute the origin of the data its AI uses. Data operations tasks{" "}
        <a href="#aiga-model-case-study-6" target="_blank" rel="noreferrer">
          previously highlighted
        </a>{" "}
        from the AIGA model also demonstrate the role of data quality in AI Governance.
      </p>
      <p>
        Data Security's relationship to AI Governance spans data protection and Data Privacy. With AI models vulnerable
        to hacking, data poisoning, and adversarial attacks, guarding the AI system's components is vital to maintaining
        Data Security. Failure to do so risks outcomes such as the "manipulation of outputs, stolen sensitive
        information, or interference with system operations." These, in turn, could lead to substantial financial
        losses, reputational damage to a business, widespread harm to others such as stolen customer funds, and physical
        safety concerns.
      </p>
      <p>
        Data quality is intimately connected to Data Security in AI Governance. For example, data poisoning occurs when
        malicious actors change the labels for data being used by an AI or inject their data into the dataset. Backdoors
        could also be created for future system access. NIST's "AI Risk Management Framework (
        <a href="https://nvlpubs.nist.gov/nistpubs/ai/NIST.AI.100-1.pdf" target="_blank" rel="noreferrer">
          AI RMF
        </a>
        )" defines AI systems as being secure when they can "maintain confidentiality, integrity, and availability
        through protection mechanisms that prevent unauthorized access."
      </p>
      <p>
        Identifying Data Security risks is especially important when using third-party AI systems. IAPP and FTI outline
        several different contract terms that could be important to implement. These include maximizing the
        compatibility of a vendor's security practices with a business's practices, performing regular security
        assessments or audits to identify risks and ensure vendor compliance, and limiting vendor access to only the
        systems they need.
      </p>
      <p>
        The historical prevalence of personal data misuse by businesses and during data breaches makes Data Privacy both
        heavily regulated and a vulnerability to many businesses. In addition to addressing other risks from poor data
        quality, data inventories can be designed into an AI Governance system that handles personal data to help keep
        businesses accountable for Data Privacy. Privacy metadata could be used to develop a single source of truth to
        support regulatory compliance and, if permitted, be used as high-quality data for an AI to make inferences about
        a population. AI-specific inventories might also be developed.
      </p>
      <p>
        Privacy leaders often already have a direct line to CEOs and the Board of Directors. Existing Data Privacy
        governance systems can be adapted to accommodate policies and procedures related to AI systems interacting with
        personal data. In previous research, IAPP found that 73% of their members implemented privacy-focused AI
        Governance policies and procedures using their existing privacy systems.
      </p>
      <h4 id={contents[10].anchor}>{contents[10].text}</h4>
      <p>
        While CEOs provide primary leadership and data practitioners make up a large proportion of workers focused on
        practical implementation, various groups might be involved in practically overseeing AI Governance processes.
        Gartner{" "}
        <a href="https://www.gartner.com/doc/reprints?id=1-2F2UJPZR&ct=230922&st=sb" target="_blank" rel="noreferrer">
          proposes
        </a>{" "}
        that many existing review boards, privacy teams, or legal departments across businesses are overwhelmed with
        AI-related ethical concerns and then take ad hoc actions to address them.
      </p>
      <p>
        Establishing dedicated oversight groups can more reliably accommodate rising AI incidents. BCG{" "}
        <a href="https://www.bcg.com/publications/2023/a-guide-to-mitigating-ai-risks" target="_blank" rel="noreferrer">
          argues
        </a>{" "}
        that a committee of senior leaders should oversee responsible AI program development and implementation,
        including establishing linkages to existing corporate governance structures, such as a risk committee. For
        example,{" "}
        <a
          href="https://www.pdpc.gov.sg/-/media/Files/PDPC/PDF-Files/Resource-for-Organisation/AI/SGModelAIGovFramework2.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Mastercard's
        </a>{" "}
        Governance Council reviews AI applications deemed high risk with various data practitioners involved, from Data
        Science teams to a Chief Information Security Officer, Chief Data Officer, and Chief Privacy Officer.
      </p>
      <p>
        In practice, Gartner's{" "}
        <a href="https://www.gartner.com/doc/reprints?id=1-2F2UJPZR&ct=230922&st=sb" target="_blank" rel="noreferrer">
          case study
        </a>{" "}
        on IBM offers examples of what groups might exist and how their responsibilities could intersect. IBM's overall
        governance strategy follows a technology ethics framework. This case study explicitly outlines IBM's
        operationalized use-case review process for AI ethics and identifies each group involved.
      </p>
      <p>
        The first level of this review process is a distributed Advocacy Network. It consists of employees across
        business units who generally promote and scale a responsible AI culture. Next, each business unit includes roles
        called Focal Points, which proactively identify concerns, support framework implementation and regulatory
        compliance, and communicate between the AI Ethics Board and each business unit.
      </p>
      <p>
        At the third level is the AI Ethics Board, co-chaired by the global AI ethics leader from IBM Research and the
        chief privacy and trust officer. It consists of a cross-functional and centralized body of representatives and
        is responsible for defining and maintaining policies, advising on AI ethics questions, and providing guidance
        through guardrails and education courses.
      </p>
      <p>
        The Policy Advisory Committee, composed of senior leaders, exists at the top. It oversees the AI Ethics Board,
        reviews the impacts of various issues throughout the business, and acts as the final escalation point for
        concerns as needed. The Chief Privacy Officer (CPO) Ethics Project Office supports each group. It acts as the
        formal liaison between each level, manages workstreams defined by the AI Ethics Board, and supports the use-case
        review process.
      </p>
      <p>
        The scale of IBM's governance structure reflects its scale as a business. For many businesses, the
        responsibilities performed at each level could likely be condensed into fewer levels and roles as needed, while
        some may not be necessary at all. Ultimately, leadership should establish the purpose and role of their AI
        Governance framework to determine which roles and responsibilities are essential to their business.
      </p>
      <h2 id={contents[11].anchor}>{contents[11].text}</h2>
      <h4 id={contents[12].anchor}>{contents[12].text}</h4>
      <p>
        The World Economic Forum's (WEF) 2024 "
        <a
          href="https://www3.weforum.org/docs/WEF_Responsible_AI_Playbook_for_Investors_2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Responsible AI Playbook for Investors
        </a>
        " highlights natural tensions between business incentives and the aims of responsible AI. For example, whereas a
        business may want to bring a product to market rapidly, implementing AI risk mitigation policies and procedures
        may slow product development using AI. However, business incentives may align more with responsible AI than many
        businesses realize.
      </p>
      <p>
        As a starting point, AI Governance addresses the most prevalent barriers to AI adoption. Policies and procedures
        help businesses mitigate AI risks better, manage data along the AI lifecycle, and integrate new technical and
        logistical systems during AI adoption. As outlined above, many CEO and data practitioner AI Governance
        responsibilities directly address such problems.
      </p>
      <p>
        Implementing AI Governance further benefits businesses during the operation phase of the AI lifecycle. A{" "}
        <a href="https://arxiv.org/pdf/2309.13057" target="_blank" rel="noreferrer">
          paper
        </a>{" "}
        from IBM and the University of Notre Dame proposes a Holistic Return on Ethics (HROE) framework for
        understanding the breadth of ROI that AI ethics affords a business. These include direct economic to intangible
        (reputational) and real option (capability) improvements. We use these types of benefits to roughly categorize
        the benefits we've identified across sources.
      </p>
      <p>
        Direct economic benefits largely come from AI Governance optimizing how efficiently a business uses AI systems.
        For example,{" "}
        <a href="https://www.ibm.com/downloads/cas/KXVRM5QE" target="_blank" rel="noreferrer">
          AI Governance platforms
        </a>{" "}
        are designed to succinctly bring stakeholders together along the AI lifecycle to employ best practices that
        maximize AI's benefits and prevent risks.
      </p>
      <p>
        Meanwhile, AI Governance's risk mitigation capabilities strongly contribute to producing intangible benefits for
        a business. We previously explored the impact and prevalence of negative impacts from AI on society, such as
        biased facial recognition systems, and businesses, such as data breaches. But these are only the first-order
        impacts. In addition to avoiding the potential direct harm done by AI, mitigating AI risks can also build trust
        between a business and various target groups and ensure regulatory compliance.
      </p>
      <p>
        Former Eli Lilly CDAO Vipin Gopal{" "}
        <a
          href="https://web-assets.bcg.com/37/87/33f2ee9d4e2281e792472f4ec1bf/to-be-a-responsible-ai-leader-focus-on-being-responsible.pdf"
          target="_blank"
          rel="noreferrer"
        >
          frames
        </a>{" "}
        the alternative to adopting AI Governance as choosing to power innovation using an AI system that is more prone
        to producing biased and unfair outcomes, having its data breached, or becoming unexplainable.
      </p>
      <h4 id={contents[13].anchor}>{contents[13].text}</h4>
      <p>
        Despite many businesses likely expecting that AI Governance policies and procedures would be net-negative for
        them by raising costs and reducing efficiency, many businesses are attributing significant value to their
        governance frameworks. As researchers from IBM and the University of Notre Dame{" "}
        <a href="https://arxiv.org/pdf/2309.13057" target="_blank" rel="noreferrer">
          argue
        </a>
        , this ROI comes in various forms, specifically economic, intangible (reputational), and real options
        (capabilities).
      </p>
      <p>
        Direct economic ROI is among the key factors businesses will consider AI Governance's effect on. Gartner's{" "}
        <a
          href="https://www.gartner.com/peer-community/oneminuteinsights/ai-governance-frameworks-responsible-ai-33q"
          target="_blank"
          rel="noreferrer"
        >
          March 2023 survey
        </a>{" "}
        – conducted at the start of gen AI's proliferation – found various positive financial-related impacts attributed
        to a business's AI Governance framework. Forty-eight percent experienced more successful AI initiatives, 46%
        improved customer experience, 46% increased revenue, 30% decreased costs, and more.
      </p>
      <p>
        Gartner further asked respondents whose organizations lacked AI Governance about the negative impacts of AI that
        they attribute to lacking AI Governance. Forty-five percent of respondents could specify at least one negative
        effect, with the top three impacts being increased costs (47%), failed AI initiatives (36%), and decreased
        revenue (34%).
      </p>
      <p>
        MIT Sloan and BCG's{" "}
        <a
          href="https://web-assets.bcg.com/37/87/33f2ee9d4e2281e792472f4ec1bf/to-be-a-responsible-ai-leader-focus-on-being-responsible.pdf"
          target="_blank"
          rel="noreferrer"
        >
          2022 research
        </a>{" "}
        findings indicate that two key factors significantly explain how much of an impact an AI Governance framework
        has on economic performance. The first is a business's commitment to implementing AI Governance, such as by
        investing resources into its implementation, seems to strongly predict its economic outcomes from using AI. More
        committed businesses experienced better products and services (50% vs. 19%), enhanced brand differentiation (48%
        vs. 14%), and accelerated innovation (43% vs. 17%) compared with less committed businesses. The second key
        factor is the framework's maturity. Two to three times more businesses with mature versus immature AI Governance
        programs experienced each benefit.
      </p>
      <p>
        What's more, this survey precedes the proliferation of gen AI. Given the various new risks that gen AI
        introduced and the challenges with mitigating them, it's plausible that the economic benefits of AI Governance
        could be considerably higher now.
      </p>
      <p>
        As shown in Table 3.1, commitment to investing in AI Governance seems to predict higher economic benefits than
        program maturity does as a contributing factor. However, the combination of commitment and an already
        well-functioning program has by far the highest combined impact.
      </p>
      <p>
        Of the 1000 managers surveyed, 52% report having a framework. Of these 52%, approximately 31% were classified as
        leaders (69% non-leaders), while approximately 19% have mature frameworks (81% immature). All respondents
        without a framework were also classified as non-leaders. As such, the size of each group is fairly large and
        could plausibly mean that these results are significant. These statistics have been derived from those directly
        stated in the report.
      </p>
      <p>
        Intangible ROI could also be high from AI Governance.{" "}
        <a href="https://arxiv.org/pdf/2309.13057" target="_blank" rel="noreferrer">
          IBM and University of Notre Dame researchers
        </a>{" "}
        define intangible impacts as the indirect paths to return associated with organizational reputation. From the
        corporate social responsibility (CSR) perspective, AI Governance produces considerable value to society,
        primarily by mitigating risks. The authors argue that strong CSR and environmental, social, and governance (ESG)
        ratings for a business "improve sales and reputation and can decrease the likelihood of customer churn." Such
        benefits can be estimated using the Social Return on Investment (SROI) technique.
      </p>
      <p>
        The researchers also emphasize the potential value of real options for businesses from AI Governance. The
        authors define real options as "small investments made by organizations that generate future flexibility – they
        position managers to make choices that capitalize on future opportunities." Resources can be invested into
        actions in phases throughout a project as a business learns more about which actions might be especially
        promising for it to take. This approach is especially beneficial when investment opportunities are highly
        uncertain.
      </p>
      <p>
        For example, real options reasoning helps businesses assess and justify investments in a novel technology that
        builds business knowledge, skills, and technical infrastructure. In the case of AI ethics, the "capabilities an
        organization gains from an investment in ethical product development will further result in opportunities for
        possible product improvements, the mitigation of identified product issues, and possibly a greater awareness and
        thus improvement in the organization's employee and customer culture."
      </p>
      <p>Both intangible ROI and real options are strongly connected to building trust and regulatory compliance.</p>
      <h4 id={contents[14].anchor}>{contents[14].text}</h4>
      <p>
        KPMG's 2023 "
        <a
          href="https://assets.kpmg.com/content/dam/kpmg/au/pdf/2023/trust-in-ai-global-insights-2023.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Trust in AI
        </a>
        " study argues that there are four distinct pathways to trust. These are an institutional pathway reflecting
        beliefs such as the adequacy of current safeguards, a motivational pathway reflecting perceived benefits of AI,
        an uncertainty reduction pathway regarding the mitigation of AI risks, and a knowledge pathway reflecting
        people's ability to understand and use AI. The first and third each highlight just how important a business's
        risk mitigation capabilities are for building trust in others.
      </p>
      <p>
        This perception of a business's commitment and ability to mitigate AI risks strongly dictates how consumers,
        employees, and other groups engage that business. Strong signals of a business's commitment and abilities are
        necessary to maintain favorable perceptions reliably. A 2024{" "}
        <a href="https://www.ibm.com/downloads/cas/QJ2BYLZG" target="_blank" rel="noreferrer">
          survey
        </a>{" "}
        from IBM indicates that three-quarters of CEOs believe that trusted AI is impossible without AI Governance.
        However, as{" "}
        <a
          href="https://www.techbetter.ai/_files/ugd/f83391_539908448a6e428da80177b14717074c.pdf"
          target="_blank"
          rel="noreferrer"
        >
          research
        </a>{" "}
        from TechBetter indicates, failing to follow through with commitments amounts to "ethics washing," which
        misleads and potentially disillusions others about a business's motivations and reliability.
      </p>
      <p>
        Businesses risk a lot when their risk mitigation practices are perceived as inadequate. Sentiments towards Data
        Privacy, for example, are strong, with IBM{" "}
        <a href="https://www.ibm.com/downloads/cas/6JAM3VB7" target="_blank" rel="noreferrer">
          finding
        </a>{" "}
        that 57% of consumers are uncomfortable with how their data is being handled. In comparison, 37% have switched
        to more privacy-protective brands. Consumers would also likely act based on their trust in how businesses use
        AI. In a separate IBM{" "}
        <a
          href="https://newsroom.ibm.com/2024-01-10-Data-Suggests-Growth-in-Enterprise-Adoption-of-AI-is-Due-to-Widespread-Deployment-by-Early-Adopters?sf185480910=1"
          target="_blank"
          rel="noreferrer"
        >
          survey
        </a>{" "}
        of IT professionals, 85% of respondents strongly or somewhat agreed that consumers are more likely to choose
        services from companies with transparent and ethical AI practices.
      </p>
      <p>
        And the trust gap between customers and businesses adopting AI seems large and possibly growing. In a{" "}
        <a
          href="https://www.salesforce.com/news/stories/customer-engagement-research-2023/"
          target="_blank"
          rel="noreferrer"
        >
          2023 survey
        </a>{" "}
        of over 14,000 international customers, research from Salesforce indicates that roughly three-quarters of
        customers were concerned about unethically using AI. Sixty-eight percent think that AI advances make it more
        important for businesses to be trustworthy. These concerns, likely amplified by the introduction of gen AI,
        might explain why consumer and business buyer openness to businesses using AI dropped between 2022 and 2023 from
        82% to 73% and 65% to 51%, respectively.
      </p>
      <p>
        Although not specific to businesses, results from KPMG's "
        <a
          href="https://assets.kpmg.com/content/dam/kpmg/au/pdf/2023/trust-in-ai-global-insights-2023.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Trust in AI
        </a>
        " survey of over 17,000 people internationally also indicate a large trust gap around AI in general. They found
        that 61% of respondents felt unsure or disagreed that current AI safeguards are sufficient to protect from AI
        risks. These confidence levels across the Western countries surveyed have remained relatively stagnant between
        2020-2022, which is proposed to indicate that AI Governance adoption is lagging behind people's expectations.
        However, levels of trust varied considerably by geography. Most people in India (80%) and China (74%) believe
        current safeguards to be adequate.
      </p>
      <p>
        Consumer trust erodes further when AI incidents occur. BCG{" "}
        <a
          href="https://www.bcg.com/publications/2023/ceo-agenda-must-include-responsible-use-of-ai"
          target="_blank"
          rel="noreferrer"
        >
          points out
        </a>{" "}
        that it's not only significant AI incidents, such as a data breach, that will lose a business consumer's trust
        but also individualized incidents. They give examples of someone being denied a bank loan due to a biased
        assessment of their request or a recommender algorithm suggesting Father's Day gift ideas to someone whose
        father recently died. "Today's consumers will hesitate to buy from a company that doesn't seem in control of its
        technology, or that doesn't protect values like fairness and decency."
      </p>
      <p>
        Trust in businesses by their employees is also improved by implementing AI Governance. IAPP and EY{" "}
        <a
          href="https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report.pdf?referrer=content_seehereview"
          target="_blank"
          rel="noreferrer"
        >
          found
        </a>{" "}
        that among Data Privacy practitioners, 65% of respondents at businesses without AI Governance don't feel
        confident about their privacy compliance. Only 12% of respondents at businesses with AI Governance implemented
        don't feel confident. Low confidence in their business's risk mitigation capabilities could drive away
        employees. Sixty-nine percent of workers in an IBM{" "}
        <a href="https://www.ibm.com/downloads/cas/6JAM3VB7" target="_blank" rel="noreferrer">
          survey
        </a>{" "}
        would be more willing to accept job offers from socially responsible organizations, while 45% are willing to
        take a pay cut to work at one.
      </p>
      <p>
        Boards and investors also need to trust AI risk mitigation practices. BCG{" "}
        <a
          href="https://www.bcg.com/publications/2023/ceo-agenda-must-include-responsible-use-of-ai"
          target="_blank"
          rel="noreferrer"
        >
          suggests
        </a>{" "}
        that AI incidents can harm a CEO's credibility with their board. At the same time, investors need to be
        confident that a business's use of AI is aligned with its corporate social responsibility statements. At the
        most significant scale, KPMG{" "}
        <a
          href="https://assets.kpmg.com/content/dam/kpmg/au/pdf/2023/trust-in-ai-global-insights-2023.pdf"
          target="_blank"
          rel="noreferrer"
        >
          thinks
        </a>{" "}
        that the strength of trust's impact on people's decisions could be critical to the sustained adoption of AI by
        businesses as a whole.
      </p>
      <h4 id={contents[15].anchor}>{contents[15].text}</h4>
      <p>
        Compliance with existing and preparation for future AI regulations increasingly make AI Governance systems{" "}
        <a
          href="https://futurium.ec.europa.eu/en/european-ai-alliance/best-practices/implementing-ai-governance-framework-practice"
          target="_blank"
          rel="noreferrer"
        >
          obligatory
        </a>
        . Businesses across all industries, sizes, and countries are increasingly asked to explain{" "}
        <a
          href="https://www.spglobal.com/en/research-insights/special-reports/the-ai-governance-challenge"
          target="_blank"
          rel="noreferrer"
        >
          how they use AI
        </a>
        . Gartner{" "}
        <a
          href="https://www.gartner.com/en/articles/what-it-takes-to-make-ai-safe-and-effective"
          target="_blank"
          rel="noreferrer"
        >
          advises
        </a>{" "}
        that businesses "be prepared to comply beyond what's already required for regulations such as those pertaining
        to privacy protection." Meanwhile, the{" "}
        <a
          href="https://www3.weforum.org/docs/WEF_Responsible_AI_Playbook_for_Investors_2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          WEF{" "}
        </a>
        argues that doing so "helps proactively mitigate regulatory and business risks, saves costs, and ultimately
        influences the regulatory landscape."
      </p>
      <p>
        Noncompliance risks substantial financial costs and legal action. For example, the most severe{" "}
        <a href="https://www.holisticai.com/blog/penalties-of-the-eu-ai-act" target="_blank" rel="noreferrer">
          penalties of the EU AI Act
        </a>{" "}
        can amount to €35 million or 7% of total worldwide annual turnover if the offender is a company. Costly
        compliance overhead, such as from audits, is also a risk to businesses unprepared for emerging regulations.
      </p>
      <p>
        While the need for regulatory compliance will be clear for many businesses, what regulations will involve is
        not. Among the key barriers to AI adoption
        <a href="qn9u4lgk87#heading=h.fwelqj4g3scw" target="_blank" rel="noreferrer">
          previously identified
        </a>{" "}
        was uncertainty about regulations. Across the surveys explored, between approximately 20% and 60% of businesses
        are struggling to adopt or scale AI due to uncertainty about what regulations will involve.
      </p>
      <p>
        Looking at current regulations is a start for understanding what future regulations may involve. Among the best
        sources of data on the current global regulatory landscape is the OECD Policy Observatory's database on{" "}
        <a href="https://oecd.ai/en/dashboards/overview/policy" target="_blank" rel="noreferrer">
          national AI strategies and policies
        </a>
        . It's a live repository of over 1,000 AI-related policies across 69 countries, territories, and the EU. In the
        emerging AI-related regulation{" "}
        <a
          href="https://oecd.ai/en/dashboards/policy-instruments/Emerging_technology_regulation"
          target="_blank"
          rel="noreferrer"
        >
          section
        </a>
        , we can see how many related policies different countries currently have, what groups they target, and the
        challenges they address.
      </p>
      <p>
        Of the 240 relevant policies cataloged, Saudi Arabia (21) has the most policies currently in place, followed by
        the UK (20) and then the US (18). Firms of any size are the third most commonly targeted group, with a total of
        112 related policies. Risks to human safety (81), risks to fairness (66), and data protection and right to
        privacy (61) are the most commonly targeted challenges. These statistics indicate that many emerging AI-related
        regulations strongly prioritize risk mitigation among businesses, supported by{" "}
        <a href="https://www.ibm.com/downloads/cas/KXVRM5QE" target="_blank" rel="noreferrer">
          observations from the IDC
        </a>{" "}
        that concerns about AI risks are driving an increasing amount of AI regulation.
      </p>
      <p>
        Businesses with higher levels of risk management and responsible AI seem to be more prepared for emerging
        regulations. First, IAPP and EY's{" "}
        <a
          href="https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report.pdf?referrer=content_seehereview"
          target="_blank"
          rel="noreferrer"
        >
          Autumn 2023 survey
        </a>{" "}
        found that respondents whose organizations lack AI Governance were far less confident about privacy compliance
        capabilities than those with AI Governance (65% versus 12%). This result aligns with BCG's{" "}
        <a
          href="https://web-assets.bcg.com/37/87/33f2ee9d4e2281e792472f4ec1bf/to-be-a-responsible-ai-leader-focus-on-being-responsible.pdf"
          target="_blank"
          rel="noreferrer"
        >
          2022 survey
        </a>
        , in which businesses with more mature responsible AI programs report feeling ready to meet emerging AI
        regulation requirements (51%) relative to businesses with new responsible AI programs (30%).
      </p>
      <p>
        Results from IBM's 2023{" "}
        <a href="https://www.ibm.com/downloads/documents/us-en/10a99803f9afdac2" target="_blank" rel="noreferrer">
          survey
        </a>{" "}
        tell a similar story. Fewer than 60% of executives feel prepared for gen AI regulation, while 69% expect a
        regulatory fine. This feeling of being unprepared is significantly holding businesses back. Fifty-six percent
        are delaying major investments pending clarity on AI standards and regulations, and 72% will forgo gen AI
        benefits due to ethical concerns. A lack of AI ethics is what they propose might explain these feelings. IBM
        estimates the proportion of AI budgets going to AI ethics will triple from 2018 levels of 3% to 9% in 2025.
      </p>
      <h2 id={contents[16].anchor}>{contents[16].text}</h2>
      <h4 id={contents[17].anchor}>{contents[17].text}</h4>
      {/* tables  */}
      <h4 id={contents[18].anchor}>{contents[18].text}</h4>
      {/* tables  */}

      <h4 id={contents[19].anchor}>{contents[19].text}</h4>
      {/* tables  */}

      <h4 id={contents[20].anchor}>{contents[20].text}</h4>
      <p>
        Looking first at Table 3.2, evidence of AI Governance adoption between 2022 and 2024 is limited, making
        understanding different adoption trends challenging. Furthermore, each survey's questions are slightly
        different, such as whether businesses are asked about having an AI Governance framework or program and whether
        AI Governance maturity is asked about or not.
      </p>
      <p>
        However, the relative stagnation of adoption seems like a pattern over time. Regarding whether businesses have
        an AI Governance framework or program, responses indicate that 40-50% of businesses did at least between 2022
        and 2023. Still, by the end of 2023, the two most recent surveys indicate that adoption rates may have dropped
        closer to 30%.
      </p>
      <p>
        This downward trend in adoption is supported by the reduced rates of risk mitigation for gen AI on average in
        2024 relative to 2023, shown in McKinsey's "
        <a
          href="https://www.mckinsey.com/capabilities/quantumblack/our-insights/the-state-of-ai#/download/%2F~%2Fmedia%2Fmckinsey%2Fbusiness%20functions%2Fquantumblack%2Four%20insights%2Fthe%20state%20of%20ai%2F2024%2Fthe-state-of-ai-in-early-2024-final.pdf%3FshouldIndex%3Dfalse"
          target="_blank"
          rel="noreferrer"
        >
          The State of AI in Early 2024
        </a>
        ." Approximately 40% of businesses seem to be in the process of implementing a framework. In comparison, the
        number of businesses not planning to implement AI Governance seems to be as high as 15% to 35%.
      </p>
      <p>
        The maturity of AI Governance frameworks is more ambiguous due to a lack of data points and high variance in
        responses. Among those with frameworks, 46% of respondents in IAPP-EY's survey indicated having high maturity,
        while only 21% in MIT Sloan and BCG's survey reported having substantial implementation of their framework. Only
        12% of respondents in Gartner's survey who have a "dedicated" AI Governance framework may be considered an
        indicator of maturity. Regardless, it seems many businesses likely don't have mature frameworks in their
        estimation.
      </p>
      <p>
        Stanford's "
        <a
          href="https://aiindex.stanford.edu/wp-content/uploads/2024/05/HAI_AI-Index-Report-2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          AI Index Report 2024
        </a>
        " alone offers substantial insight into the degree of risk mitigation adoption levels globally in 2024. The
        average number of fully implemented risk mitigations (35-40%) and businesses with no risk mitigations (5-12%)
        are similar. However, the proportion of businesses implementing 1-50% of measures versus 50-99% of measures
        varies between risks, from 8% versus 80% for transparency and explainability risks to 28% versus 63% for
        cybersecurity risks.
      </p>
      <p>
        Meanwhile, the key barriers to AI Governance adoption fall under remarkably similar categories to those for
        adopting AI. However, unlike AI adoption, businesses seem to struggle more with the planning and resources phase
        before deployment, especially with a lack of expertise (Table 3.6). This lack of expertise could be behind
        downstream deployment issues. For example, regarding technical and organizational logistics as another common
        barrier category, 57% of IAPP and EY's respondents feel a "lack of control over AI deployment."
      </p>
      <p>
        Indeed, among the businesses already implementing AI Governance, there's a lack of confidence in risk mitigation
        capabilities. IBM{" "}
        <a href="https://www.ibm.com/downloads/cas/QJ2BYLZG" target="_blank" rel="noreferrer">
          surveyed
        </a>{" "}
        over 2,500 CEOs globally at the beginning of 2024 and found that only 39% of CEOs whose businesses have a
        framework believed their organization's AI Governance was effective. It's important that businesses anticipate
        and deliberately design their framework to address regulatory concerns.
      </p>
      <p>
        Many businesses recognize and are acting to address this need. According to KPMG's{" "}
        <a
          href="https://kpmg.com/kpmg-us/content/dam/kpmg/pdf/2023/kpmg-gen-ai-survey2023.pdf"
          target="_blank"
          rel="noreferrer"
        >
          June 2023 survey
        </a>
        , hiring specialized talent such as Data Privacy officers (50%), creating new roles relative to gen AI
        regulations (45%), and partnering with third-party external contractors/consultants (41%) are the three most
        common actions businesses plan on taking in anticipation of regulations. Leveraging specialized skills and tools
        will be necessary to implement an effective AI Governance framework.
      </p>
    </div>
  );
};

import { Link } from "react-router-dom";

import Post from "../../components/blog/Post";
import PostImage from "../../components/blog/PostImage";
import AIGFCDownloads from "./AIGFCDownloads";

import aiGovernance from "../../img/blog/aigfc/ai-governance.webp";
import aiFrameworkTypes from "../../img/blog/aigfc/ai-framework-types.webp";

const POSTNAME: postNickname = "aigfc-methodology";

const AIGFCMethodology: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default AIGFCMethodology;

const Rest = () => {
  const contents = [
    { anchor: "limitations", text: "Limitations" },
    { anchor: "wider-research", text: "Wider Research" },
    { anchor: "framework-assessments", text: "Framework Assessments" },
    { anchor: "framework-profiles", text: "Framework Profiles" },
    { anchor: "introduction-and-content-summary", text: "Introduction and Content Summary" },
    { anchor: "best-practice-highlights", text: "Best Practice Highlights" },
    { anchor: "assessments-of-value-potential", text: "Assessments of Value Potential" },
    { anchor: "assessment-methodology", text: "Assessment Methodology" },
    { anchor: "best-practice-categories", text: "Best Practice Categories" },
    { anchor: "assessment-criteria", text: "Assessment Criteria" },
    { anchor: "overall-assessments", text: "Overall Assessments" },
    { anchor: "references", text: "References" },
  ];

  const subcontent = [
    { anchor: "risk-mitigation", text: "Risk Mitigation" },
    { anchor: "data-management", text: "Data Management" },
    { anchor: "breadth", text: "Breadth" },
    { anchor: "depth", text: "Depth" },
    { anchor: "practicality", text: "Practicality" },
    { anchor: "best-practice-assessment-ratings", text: "Best Practice Assessment Ratings" },
    { anchor: "best-for", text: "Best For" },
    { anchor: "value-of-supplementary-resources", text: "Value of Supplementary Resources" },
  ];

  return (
    <div className="blog">
      <PostImage postName={POSTNAME} />
      <h4>Contents</h4>
      <ul>
        <li>
          <a href={"#" + contents[0].anchor}>{contents[0].text}</a>
          {contents.slice(1, 3).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
        <li>
          <a href={"#" + contents[3].anchor}>{contents[3].text}</a>
          {contents.slice(4, 7).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
          <li>
            <a href={"#" + contents[8].anchor}>{contents[8].text}</a>
          </li>
        </li>
        {contents.slice(9, 11).map((content) => (
          <li>
            <a href={"#" + content.anchor}>{content.text}</a>
          </li>
        ))}
        <li>
          <a href={"#" + contents[11].anchor}>{contents[11].text}</a>
        </li>
      </ul>

      <h2 id={contents[0].anchor}>{contents[0].text}</h2>
      <p>
        We aim to be as <a href="https://www.openphilanthropy.org/research/reasoning-transparency/">transparent</a> as
        possible with our reasoning and processes. For a related discussion, see the{" "}
        <Link to="/resources/aigfc/intro#scope" target="_blank">
          Scope
        </Link>{" "}
        section of the Introduction.
      </p>
      <h4 id={contents[1].anchor}>{contents[1].text}</h4>
      <ul>
        <li>
          <strong>Finding sources</strong>:&nbsp; most sources were found through either a keyword search or other
          sources. While many high-quality sources were found, the AI Governance field remains disparate, so many
          valuable resources, including surveys and models, are likely not accounted for in this report.
        </li>
        <li>
          <strong>Reliance on survey data</strong>:&nbsp; most evidence for business AI and AI Governance adoption
          trends comes from survey data. This evidence is useful for understanding how businesses view AI and AI
          Governance. However, it may fail to capture reliable information about what businesses are doing with AI and
          AI Governance due to mistaken or dishonest responses. In contrast, evidence that more directly gauges what
          businesses are doing such as publicly released AI Governance Frameworks and independent audits currently has
          limited availability.
        </li>
        <li>
          <strong>Accounting for source dates</strong>:&nbsp; some effort was made to discuss survey results within the
          context of their release date, but we did so inconsistently across analyses. For example, the key barriers to
          AI adoption section doesn't explicitly consider survey dates when making inferences about the most relevant
          barriers. Furthermore, there's a lack of historical data for many subjects, which limits what can be said
          about how they've changed or where they're trending towards.
        </li>
        <li>
          <strong>Accounting for variance in source methodology</strong>:&nbsp; when multiple surveys would be discussed
          as supporting data points for some argument, e.g., what the key barriers to AI adoption are, the degree of
          variance in sample size, audience, question-wording, and other survey features were often not thoroughly
          accounted for in inferences made from each source. This issue risks misinterpreting results and making claims
          not supported by the evidence.
        </li>
      </ul>
      <h4 id={contents[2].anchor}>{contents[2].text}</h4>
      <ul>
        <li>
          <strong>Framework comparability</strong>:&nbsp; there is high variance in the subject matter, format, and
          intended use among the frameworks investigated. A standardized assessment method was applied, which attempted
          to account for different broad characteristics of each framework, but comparisons between frameworks can only
          be reasonably limited to what they offer on risk mitigation and Data Management
        </li>
        <li>
          <strong>Best practice categories and benchmarks</strong>:&nbsp; the two categories identified don't represent
          the full extent of AI Governance best practice categories. For example, operational tasks and guidance on
          implementing a framework are essential to AI Governance but couldn't easily be assessed. However, they are
          each important to AI Governance and our audience and could be assessed using reputable best practice
          taxonomies as benchmarks for classifying guidance. These taxonomies are not currently used as standard
          benchmarks for classifying guidance (see more on assessment validity below).
        </li>
        <li>
          <strong>Assessments focus on describing framework content</strong>:&nbsp; we were able to assess the type and
          amount of guidance offered in frameworks but not its quality. Our assessments do not evaluate the accuracy or
          effectiveness of a framework's guidance and cannot anticipate what guidance most aligns with a business's
          objectives.
        </li>
        <li>
          <strong>Depth of investigations</strong>:&nbsp; frameworks' breadth, depth, and practicality could only be
          assessed at a moderate depth of investigation for each framework. Large portions of content were skimmed or
          skipped entirely. As such, there will likely be substantial assessment-relevant guidance that our assessments
          do not account for.
        </li>
        <li>
          <strong>Validity of assessment methodology</strong>:&nbsp; the assessment methodology used in this report,
          from the best practice categories it assessed to the criteria it used to assess guidance, was novel. No
          testing or expert consultation on its development occurred before it was used.{" "}
        </li>
        <li>
          <strong>Reliability of assessments</strong>:&nbsp; assessments were often made in ad-hoc steps, such as how
          deeply each framework was investigated, how many times a framework was revisited, when and what type of notes
          were taken, etc., which did not control for a consistent treatment of each framework. Furthermore, each
          criterion was subjectively assessed by a non-expert, meaning that assessments may not effectively represent a
          framework's information value or diverge significantly from how others assess each framework.
        </li>
        <li>
          <strong>Public release of practice-based frameworks</strong>:&nbsp; very few large businesses, including those
          whose currently released framework guidance we assessed, have publicly released information on their AI
          governance frameworks. This situation means there's a limited pool of resources to draw insights.
        </li>
      </ul>
      <h4 id={contents[4].anchor}>{contents[4].text}</h4>
      <h6 id={contents[5].anchor}>{contents[5].text}</h6>
      <p>
        While the Introduction provides basic background details on a framework, the Content Summary explains its key
        contents. The key details in the introduction include who created the framework and when, what its aims are, who
        its target audience is, and what plans for its future development are. Content Summaries focused on outlining
        the key content categories and the type of information available on each, e.g., actions, definitions, etc.
      </p>
      <p>
        Anthropic's AI model Claude Sonnet 3.5 (hereafter Claude) was used to help find information and write some
        introductions and content summaries. Initial note-taking for key details was done manually before Claude was
        used to review the document for gaps in the type of information being sought. Claude provided keywords and page
        numbers as part of its suggestions for the researcher to verify their accuracy.
      </p>
      <h6 id={contents[6].anchor}>{contents[6].text}</h6>
      <p>
        Highlights aim to make useful guidance from each framework highly accessible to businesses and represent the
        quality and type of guidance businesses might gain from investigating a framework further. Risk mitigation and
        Data Management highlights were exclusively identified in alignment with our framework assessment methodology.
        The final examples of guidance highlighted are directly taken from each source with minimal editing and were
        iteratively selected during several shallow-to-moderately deep passes over each framework.
      </p>
      <h6 id={contents[7].anchor}>{contents[7].text}</h6>
      <p>
        In addition to describing specific details for each framework, we assessed several general qualities of its
        content to indicate how potentially valuable it could be for businesses to investigate it further. Subjective
        ratings for the breadth, depth, and practicality of guidance on risk mitigation and Data Management, much of
        which is directly relevant to the deployment and operation AI lifecycle phases, were made based on moderately
        deep investigations of each framework.
      </p>
      <p>
        Claude was used to help assess the breadth criterion. Specifically, Claude was used to parse each framework and
        identify any substantive guidance for each best practice subcategory. It used the descriptions of and
        explanations for each subcategory as outlined in NIST's AI RMF and DAMA's{" "}
        <em>
          <a href="https://www.amazon.com/gp/product/1634622340/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=1634622340&linkId=5e47fe53a18a705df29f9b91763a22b8">
            DMBOK
          </a>
        </em>{" "}
        for risk mitigation and Data Management guidance, respectively. For each suggestion, Claude provided page
        numbers for the referenced content and a brief explanation of what makes its suggested guidance qualify for a
        given subcategory. The researcher ultimately determined if any guidance did or did not qualify for a particular
        subcategory.
      </p>
      <p>
        In addition to these quantitative assessments, high-level guidance was offered on the value potential of
        supplementary resources and what each framework was best for. Many supplementary resources meaningfully increase
        a core framework's overall value potential therefore, highlighting their potential on top of the core framework
        assessed in detail seemed important. By briefly highlighting what use case a framework may be best for, we can
        describe a framework's value potential in a way aligned with its particular aims and may not be captured in our
        quantitative assessments.
      </p>
      <h2 id={contents[7].anchor}>{contents[7].text}</h2>
      <h4 id={contents[8].anchor}>{contents[8].text}</h4>
      <p>
        Only Risk Mitigation and Data Management, among the best practices we explored assessing frameworks, could be
        effectively operationalized using reputable taxonomies as benchmarks for what constitutes relevant and specific
        guidance.
      </p>
      <p className="h7" id={subcontent[0].anchor}>
        {subcontent[0].text}
      </p>
      <p>
        This includes measures explicitly to mitigate risks that include but aren't exclusive to those discussed
        throughout "<a href="?tab=t.b161c61fpcjv">AI Risks and Trustworthiness</a>." The seven types of AI
        trustworthiness and risk identified in NIST's AI RMF were used as a benchmark of subcategories for identifying
        risk-specific guidance.
      </p>
      <p className="h8">Subcategories</p>
      <ul>
        <li>Valid and reliable</li>
        <li>Safe</li>
        <li>Secure and resilient</li>
        <li>Accountable and transparent</li>
        <li>Explainable and interpretable</li>
        <li>Privacy-enhanced</li>
        <li>Fair – with harmful bias managed</li>
      </ul>
      <h6 id={subcontent[1].anchor}>{subcontent[1].text}</h6>
      <p>
        This includes data practices that include but aren't exclusive to those outlined under "{/* Link */}
        Data Management and Data Governance{/* Link */}" and "{/* Link */}
        Data Quality and Data Security
        {/* Link */}
        ." The eleven areas of Data Management classified in DAMA's{" "}
        <em>
          <a href="http://amzn.to/32oK8hH">DMBOK</a>
        </em>{" "}
        were used as a benchmark of subcategories for identifying Data Management-specific guidance.
      </p>
      <p className="h8">Subcategories</p>
      <ul>
        <li>Data Governance</li>
        <li>Data Warehousing & Business Intelligence</li>
        <li>Reference & Master Data</li>
        <li>Document & Content Management</li>
        <li>Data Integration & Interoperability</li>
        <li>Data Security</li>
        <li>Data Storage & Operations</li>
        <li>Data Modeling & Design</li>
        <li>Data Architecture</li>
        <li>Data Quality</li>
        <li>Metadata Management</li>
      </ul>
      <h4 id={contents[9].anchor}>{contents[9].text}</h4>
      <h6 id={subcontent[2].anchor}>{subcontent[2].text}</h6>
      <ul>
        <li>
          <strong>Definition</strong>:&nbsp; a rough indication of the range of risk mitigation and Data Management best
          practices that a framework offers guidance on
        </li>
        <li>
          <strong>Operationalization</strong>:&nbsp; the total number of best practice subcategories for which a
          framework includes at least one piece of substantive guidance, i.e., more than the mentioned risk. As the
          number of subcategories per best practice category is not 10, to be consistent with how thoroughness and
          practicality are also rated, ratings are normalized to be a score out of 10. However, should a framework offer
          no relevant guidance, a score of 0 will be assigned, which would then be reflected in in-depth and
          practicality ratings.
        </li>
      </ul>

      <h6 id={subcontent[3].anchor}>{subcontent[3].text}</h6>
      <ul>
        <li>
          <strong>Definition</strong>:&nbsp; the specificity and volume of detail that content involves, e.g., specific
          terminology and thorough explanations
        </li>
        <li>
          <strong>Operationalization</strong>:&nbsp; how detailed guidance on risk mitigation and Data Management is
          ranges from <em>very low (1-2)</em>
          <strong> </strong>to <em>very high (9-10)</em>. A 1 would offer no useful information, and 10 would provide
          all the details a business would need to understand best practices. A rating of 0 is possible if and only if
          breadth is rated as a 0. The researcher subjectively applies this rating and briefly shares the reasoning
          behind their conclusion.
        </li>
      </ul>

      <h6 id={subcontent[4].anchor}>{subcontent[4].text}</h6>
      <ul>
        <li>
          <strong>Definition</strong>:&nbsp; the degree to which content is explicit on how to conduct governance in
          practice, e.g., specific step-by-step procedures
        </li>
        <li>
          <strong>Operationalization</strong>:&nbsp; the overall practicality of risk mitigation and Data Management
          guidance ranges <em>from very low (1-2)</em> to <em>very high (9-10)</em>. A 1 offers no guidance that
          businesses could put into practice as is, while a 10 thoroughly and lays out the governance actions businesses
          need to take and how to take them. A rating of 0 is possible if and only if breadth is rated as a 0. The
          researcher subjectively applies this rating and briefly shares the reasoning behind their conclusion.
        </li>
      </ul>

      <h4 id={contents[10].anchor}>{contents[10].text}</h4>
      <h6 id={subcontent[5].anchor}>{subcontent[5].text}</h6>
      <p>
        Most importantly, because the number of subcategories of risk mitigation and Data Management used to measure
        breadth aren’t 10 each, whereas depth and practicality are subjectively rated out of 10, the raw rating for
        breadth is normalized to fit into a score of 10.
      </p>
      <p>
        This process first involves dividing 10 by the number of subcategories each best practice category involves – 7
        and 11, respectively. The output of this calculation is used to rescale the number of relevant subcategories
        identified to be a score out of 10. For example, if 6 / 7 subcategories are explicitly relevant to at least one
        practice identified on a framework, we calculate it in the following way: 10 / 7 (= approx. 1.4) * 6 = 8.6 (out
        of 10).
      </p>
      <p>
        Once the breadth rating has been normalized, the mean of all three ratings is taken as the overall assessment
        rating for each best practice category.
      </p>

      <h6 id={subcontent[6].anchor}>{subcontent[6].text}</h6>
      <p>
        Acknowledges the specific use case(s) the framework best suits. This indicator is included to specify the more
        nuanced value proposition of each framework outside of our standardized assessments. It’s possible for the
        specific aims and strengths of a given framework to align with a given business's AI Governance needs despite it
        being rated poorly in our assessments.
      </p>
      <h6 id={subcontent[7].anchor}>{subcontent[7].text}</h6>
      <p>
        This rating of low, moderate, or high roughly indicates how much value supplementary resources are estimated to
        add to the core framework assessed in full. However, the rating may be weighted heavily on a single, especially
        high-value resource, such as for NIST and AWS frameworks.
      </p>
      {/* <p>
        Only shallow research was done into supplementary resources, including a review of their table of contents, when
        available, and skim-reading a selection of relevant sections.
      </p> */}

      <h2 id={contents[11].anchor}>{contents[11].text}</h2>
      <ol>
        <li>
          <strong>AI Policy Institute.</strong> (2023).{" "}
          <em>New Analysis Shows Over 20% of US Jobs Significantly Exposed to AI Automation In the Near Future</em>.
          Retrieved from<a href="https://theaipi.org/ai-interactive-map/"> https://theaipi.org/ai-interactive-map/</a>
        </li>
        <li>
          <strong>AI Verify Foundation.</strong> (2023).{" "}
          <em>Summary Report: Binary Classification Model for Credit Risk</em> [PDF]. Retrieved from{" "}
          <a href="https://aiverifyfoundation.sg/downloads/AI_Verify_Sample_Report.pdf">
            https://aiverifyfoundation.sg/downloads/AI_Verify_Sample_Report.pdf
          </a>
        </li>
        <li>
          <strong>AI Verify Foundation.</strong> (2024). <em>Model AI Governance Framework for Generative AI</em> [PDF].
          Retrieved from{" "}
          <a href="https://aiverifyfoundation.sg/wp-content/uploads/2024/06/Model-AI-Governance-Framework-for-Generative-AI-19-June-2024.pdf">
            https://aiverifyfoundation.sg/wp-content/uploads/2024/06/Model-AI-Governance-Framework-for-Generative-AI-19-June-2024.pdf
          </a>
        </li>
        <li>
          <strong>Amazon Web Services (AWS).</strong> (2023). <em>AI Safety Summit - Enhancing Frontier AI Safety</em>.
          Retrieved from{" "}
          <a href="https://aws.amazon.com/uki/cloud-services/uk-gov-ai-safety-summit/">
            https://aws.amazon.com/uki/cloud-services/uk-gov-ai-safety-summit/
          </a>
        </li>
        <li>
          <strong>Amazon Web Services (AWS).</strong> (n.d.). <em>What is Generative AI?</em> Retrieved from{" "}
          <a href="https://aws.amazon.com/what-is/generative-ai/"> https://aws.amazon.com/what-is/generative-ai/</a>
        </li>
        <li>
          <strong>Amazon Web Services (AWS)</strong>. (2023). <em>Responsible Use of Machine Learning</em> [PDF].
          Retrieved from{" "}
          <a href="https://d1.awsstatic.com/responsible-machine-learning/AWS_Responsible_Use_of_ML_Whitepaper_1.2.pdf">
            https://d1.awsstatic.com/responsible-machine-learning/AWS_Responsible_Use_of_ML_Whitepaper_1.2.pdf
          </a>
        </li>
        <li>
          <strong>Amazon Web Services (AWS).</strong> (2023).{" "}
          <em>Machine Learning Lens: AWS Well-Architected Framework</em> [PDF]. Retrieved from{" "}
          <a href="https://docs.aws.amazon.com/pdfs/wellarchitected/latest/machine-learning-lens/wellarchitected-machine-learning-lens.pdf">
            https://docs.aws.amazon.com/pdfs/wellarchitected/latest/machine-learning-lens/wellarchitected-machine-learning-lens.pdf
          </a>
        </li>
        <li>
          <strong>Amazon Web Services (AWS).</strong> (2024).{" "}
          <em>AWS Cloud Adoption Framework for Artificial Intelligence, Machine Learning, and Generative AI</em> [PDF].
          Retrieved from{" "}
          <a href="https://docs.aws.amazon.com/pdfs/whitepapers/latest/aws-caf-for-ai/aws-caf-for-ai.pdf">
            https://docs.aws.amazon.com/pdfs/whitepapers/latest/aws-caf-for-ai/aws-caf-for-ai.pdf
          </a>
        </li>
        <li>
          <strong>ANS.</strong> (2024). <em>Business Blockers Report 2024 </em>[PDF]. Retrieved from{" "}
          <a href="https://wp.ans.co.uk/l/909202/2024-03-14/c4wgj4/909202/1710419434pJajeBkT/ANS_Business_Blockers_Report_March_2024_SMB.pdf">
            https://wp.ans.co.uk/l/909202/2024-03-14/c4wgj4/909202/1710419434pJajeBkT/ANS_Business_Blockers_Report_March_2024_SMB.pdf
          </a>
        </li>
        <li>
          <strong>Anthropic</strong>. (2023) <em>Responsible Scaling Policy</em> [PDF]. Retrieved from{" "}
          <a href="https://assets.anthropic.com/m/24a47b00f10301cd/original/Anthropic-Responsible-Scaling-Policy-2024-10-15.pdf">
            https://assets.anthropic.com/m/24a47b00f10301cd/original/Anthropic-Responsible-Scaling-Policy-2024-10-15.pdf
          </a>
        </li>
        <li>
          <strong>Australian Government, Department of Industry, Science and Resources.</strong> (2021).{" "}
          <em>Testing the AI Ethics Principles</em>. Retrieved from{" "}
          <a href="https://www.industry.gov.au/publications/australias-artificial-intelligence-ethics-framework/testing-ai-ethics-principles">
            https://www.industry.gov.au/publications/australias-artificial-intelligence-ethics-framework/testing-ai-ethics-principles
          </a>
        </li>
        <li>
          <strong>Bevilacqua, M., et al.</strong> (2023).{" "}
          <em>The Return on Investment in AI Ethics: A Holistic Framework</em> [PDF]. <em>arXiv</em>, arXiv:2309.13057.
          Retrieved from <a href="https://arxiv.org/pdf/2309.13057">https://arxiv.org/pdf/2309.13057</a>
        </li>
        <li>
          <strong>Boston Consulting Group (BCG).</strong> (2023). <em>A Guide to AI Governance for Business Leaders</em>
          . Retrieved from{" "}
          <a href="https://www.bcg.com/publications/2023/a-guide-to-mitigating-ai-risks">
            https://www.bcg.com/publications/2023/a-guide-to-mitigating-ai-risks
          </a>
        </li>
        <li>
          <strong>Boston Consulting Group (BCG).</strong> (2023). <em>Responsible AI at a Crossroads</em>. Retrieved
          from{" "}
          <a href="https://www.bcg.com/publications/2023/ai-responsibility-at-crossroads">
            https://www.bcg.com/publications/2023/ai-responsibility-at-crossroads
          </a>
        </li>
        <li>
          <strong>Boston Consulting Group (BCG).</strong> (2023). <em>Responsible AI Belongs on the CEO Agenda</em>.
          Retrieved from{" "}
          <a href="https://www.bcg.com/publications/2023/ceo-agenda-must-include-responsible-use-of-ai">
            https://www.bcg.com/publications/2023/ceo-agenda-must-include-responsible-use-of-ai
          </a>
        </li>
        <li>
          <strong>Buchanan, B.</strong> (2020). <em>The AI Triad and What It Means for National Security Strategy</em>.
          Center for Security and Emerging Technology. Retrieved from{" "}
          <a href="https://cset.georgetown.edu/publication/the-ai-triad-and-what-it-means-for-national-security-strategy/">
            https://cset.georgetown.edu/publication/the-ai-triad-and-what-it-means-for-national-security-strategy/
          </a>
        </li>
        <li>
          <strong>Capgemini Research Institute.</strong> (2020).{" "}
          <em>The AI-powered enterprise: Unlocking the potential of AI at scale </em>[PDF]. Retrieved from{" "}
          <a href="https://www.capgemini.com/wp-content/uploads/2021/02/State-of-AI_Report_Web-2.pdf">
            https://www.capgemini.com/wp-content/uploads/2021/02/State-of-AI_Report_Web-2.pdf
          </a>
        </li>
        <li>
          <strong>Council of Europe.</strong> (2023).{" "}
          <em>Human rights by design future-proofing human rights protection in the era of AI</em> [PDF]. Retrieved from{" "}
          <a href="https://rm.coe.int/0900001680ab2279">https://rm.coe.int/0900001680ab2279</a>
        </li>
        <li>
          <strong>Council of Europe.</strong> (202). <em>Ad hoc Committee on Artificial Intelligence (CAHAI)</em>.
          Retrieved from <a href="https://rm.coe.int/0900001680a1293a">https://rm.coe.int/0900001680a1293a</a>
        </li>
        <li>
          <strong>DAMA International.</strong> (2017). <em>DAMA-DMBOK: Data Management Body of Knowledge</em>. Retrieved
          from{" "}
          <a href="https://www.amazon.com/DAMA-DMBOK-Data-Management-Body-Knowledge/dp/1634622340">
            https://www.amazon.com/DAMA-DMBOK-Data-Management-Body-Knowledge/dp/1634622340
          </a>
        </li>
        <li>
          <strong>DataRobot.</strong> (2022).{" "}
          <em>
            DataRobot's State of AI Bias Report Reveals 81% of Technology Leaders Want Government Regulation of AI Bias
          </em>
          . Retrieved from{" "}
          <a href="https://www.datarobot.com/newsroom/press/datarobots-state-of-ai-bias-report-reveals-81-of-technology-leaders-want-government-regulation-of-ai-bias/">
            https://www.datarobot.com/newsroom/press/datarobots-state-of-ai-bias-report-reveals-81-of-technology-leaders-want-government-regulation-of-ai-bias/
          </a>
        </li>
        <li>
          <strong>Dotan, R., et al.</strong> (2023). <em>Evaluating AI Governance: Insights from Public Disclosures</em>{" "}
          [PDF]. Retrieved from{" "}
          <a href="https://www.techbetter.ai/_files/ugd/f83391_539908448a6e428da80177b14717074c.pdf">
            https://www.techbetter.ai/_files/ugd/f83391_539908448a6e428da80177b14717074c.pdf
          </a>
        </li>
        <li>
          <strong>Epoch AI.</strong> (2024). <em>Machine Learning Trends</em>. Retrieved from
          <a href="https://epochai.org/trends"> https://epochai.org/trends</a>
        </li>
        <li>
          <strong>European AI Alliance.</strong> (2023). <em>Implementing AI Governance: from Framework to Practice</em>
          . Retrieved from{" "}
          <a href="https://futurium.ec.europa.eu/en/european-ai-alliance/best-practices/implementing-ai-governance-framework-practice">
            https://futurium.ec.europa.eu/en/european-ai-alliance/best-practices/implementing-ai-governance-framework-practice
          </a>
        </li>
        <li>
          <strong>European Commission.</strong> (2021). <em>Artificial Intelligence Act</em>. Retrieved from{" "}
          <a href="https://artificialintelligenceact.eu/">
            https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex:52021PC0206
          </a>
        </li>
        <li>
          <strong>European Investment Bank (EIB).</strong> (2021).{" "}
          <em>
            €10 Billion Investment Gap in Artificial Intelligence and Blockchain Technologies Is Holding Back the
            European Union
          </em>
          . Retrieved from{" "}
          <a href="https://www.eib.org/en/press/all/2021-181-new-eib-report-eur10-billion-investment-gap-in-artificial-intelligence-and-blockchain-technologies-is-holding-back-the-european-union">
            https://www.eib.org/en/press/all/2021-181-new-eib-report-eur10-billion-investment-gap-in-artificial-intelligence-and-blockchain-technologies-is-holding-back-the-european-union
          </a>
        </li>
        <li>
          <strong>European Parliament.</strong> (2023). <em>EU AI Act: First Regulation on Artificial Intelligence</em>.
          Retrieved from{" "}
          <a href="https://www.europarl.europa.eu/topics/en/article/20230601STO93804/eu-ai-act-first-regulation-on-artificial-intelligence">
            https://www.europarl.europa.eu/topics/en/article/20230601STO93804/eu-ai-act-first-regulation-on-artificial-intelligence
          </a>
        </li>
        <li>
          <strong>Expleo.</strong> (2024). <em>Integrating AI: Navigating the next wave of business transformation</em>{" "}
          [PDF]. Retrieved from{" "}
          <a href="https://expleo.com/global/en/wp-content/documents/EXPLEO%20-%20AI%20REPORT.pdf">
            https://expleo.com/global/en/wp-content/documents/EXPLEO%20-%20AI%20REPORT.pdf
          </a>
        </li>
        <li>
          <strong>Gartner.</strong> (2023). <em>Tackling Trust, Risk and Security in AI Models</em>. Retrieved from{" "}
          <a href="https://www.gartner.com/en/articles/what-it-takes-to-make-ai-safe-and-effective">
            https://www.gartner.com/en/articles/what-it-takes-to-make-ai-safe-and-effective
          </a>
        </li>
      </ol>
    </div>
  );
};

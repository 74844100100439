const aigfcSources: aigfcSource[] = [
  {
    id: "aigfc-001",
    institution: "IBM",
    report: "Global AI Adoption Index 2023",
    reportLink:
      "https://newsroom.ibm.com/2024-01-10-Data-Suggests-Growth-in-Enterprise-Adoption-of-AI-is-Due-to-Widespread-Deployment-by-Early-Adopters?sf185480910=1",
    surveyed: "8,584 global IT Professionals in manager or higher roles",
    date: "Jan 2024",
  },
  {
    id: "aigfc-002",
    institution: "IBM",
    report: "The CEO’s Guide to Generative AI",
    reportLink: "https://www.ibm.com/downloads/cas/6JAM3VB7",
    surveyed: "data from three different surveys",
    date: "Oct 2023",
  },
  {
    id: "aigfc-003",
    institution: "KPMG",
    report: "Generative AI Survey",
    reportLink: "https://kpmg.com/kpmg-us/content/dam/kpmg/pdf/2023/kpmg-gen-ai-survey2023.pdf",
    surveyed: "225 US business leaders",
    date: "June 2023",
  },
  {
    id: "aigfc-004",
    institution: "McKinsey",
    report: "The State of AI in Early 2024",
    reportLink:
      "https://www.mckinsey.com/~/media/mckinsey/business%20functions/quantumblack/our%20insights/the%20state%20of%20ai/2024/the-state-of-ai-in-early-2024-final.pdf?shouldIndex=false",
    surveyed: "1,363 participants from across roles and regions",
    date: "May 2024",
  },
  {
    id: "aigfc-005",
    institution: "IDC",
    report: "Global AI Buyer Sentiment, Adoption, and Business Value Survey",
    reportLink: "https://idcdocserv.com/US50056923e_IBM",
    surveyed: "607 global respondents",
    date: "October 2023",
  },
  {
    id: "aigfc-006",
    institution: "IDC",
    report: "AI Strategies View Survey",
    reportLink: "https://www.ibm.com/downloads/cas/KXVRM5QE",
    surveyed: "2,053 organizations",
  },
  {
    id: "aigfc-007",
    institution: "TDWI",
    report: "The State of AI Readiness 2024",
    reportLink: "https://tdwi.org/Research/2024/07/ADV-ALL-2024-State-of-AI-Readiness.aspx",
    surveyed: "113 respondents from various industries and company sizes",
  },
  {
    id: "aigfc-008",
    institution: "Expleo",
    report: "Integrating AI report",
    reportLink: "https://expleo.com/global/en/wp-content/documents/EXPLEO%20-%20AI%20REPORT.pdf",
    surveyed: "803 West European C-suite level executives",
  },
  {
    id: "aigfc-009",
    institution: "Stanford University",
    report: "AI Index Report 2024",
    reportLink: "https://aiindex.stanford.edu/wp-content/uploads/2024/05/HAI_AI-Index-Report-2024.pdf",
    date: "Apr 2024",
  },
  {
    id: "aigfc-010",
    institution: "Retool",
    report: "2024 State of AI in Production",
    reportLink: "https://retool.com/blog/state-of-ai-h1-2024",
    date: "June 2024",
  },
  {
    id: "aigfc-011",
    institution: "Capgemini",
    report: "2020 State of AI Report",
    reportLink: "https://www.capgemini.com/wp-content/uploads/2021/02/State-of-AI_Report_Web-2.pdf",
  },
  {
    id: "aigfc-012",
    institution: "TechCrunch",
    report: "China's declining investment in AI",
    reportLink: "https://techcrunch.com/2024/02/05/china-ai-investment-decline/",
    date: "Feb 2024",
  },
  {
    id: "aigfc-013",
    institution: "European Investment Bank",
    report: "Europe's technology investment gap",
    reportLink:
      "https://www.eib.org/en/press/all/2021-181-new-eib-report-eur10-billion-investment-gap-in-artificial-intelligence-and-blockchain-technologies-is-holding-back-the-european-union",
    date: "June 2021",
  },
  {
    id: "aigfc-014",
    institution: "OECD",
    report: "The Technical Landscape",
    reportLink: "https://read.oecd-ilibrary.org/science-and-technology/artificial-intelligence-in-society_8b303b6f-en",
    date: "Aug 2019",
  },
  {
    id: "aigfc-015",
    institution: "IDC",
    report: "2022 AI Strategies View Survey",
    reportLink: "https://www.ibm.com/downloads/cas/KXVRM5QE",
    date: "Sep 2023",
  },
  {
    id: "aigfc-016",
    institution: "TDWI",
    report: "State of AI Readiness 2024",
    reportLink: "https://tdwi.org/Research/2024/07/ADV-ALL-2024-State-of-AI-Readiness.aspx",
    date: "Sep 2024",
  },
];

export default aigfcSources;
